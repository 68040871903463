<template>
  <div class="orderForm">
    <el-breadcrumb separator-class="el-icon-coordinate">
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
      <div style="padding-right: 30px;float: right">
        <el-button size="small" v-if="formType" type="primary" @click="Refresh">刷 新</el-button>
        <el-button size="small"
                   v-if="formType&&(orderForm.status==0||orderForm.status==10)&&!driverDetail&&orderForm.isSpeclifiedDriver==0"
                   type="primary"
                   @click="editOrder">修改
        </el-button>
        <el-button size="small"
                   v-if="!formType&&(orderForm.status==0||orderForm.status==10)&&!driverDetail&&orderForm.isSpeclifiedDriver==0"
                   type="primary"
                   @click="saveForm">保存
        </el-button>
        <el-button size="small" v-if="id" type="primary" @click="back">返 回</el-button>
      </div>
    </el-breadcrumb>
    <div class="container">
      <el-row class="title-group" :gutter="10">
        <el-col :span="15">
          <p style="text-align: left">订单编号：{{ orderForm.orderNo }}</p>
        </el-col>
        <el-col :span="9">
          <el-form ref="form" :model="orderForm" :rules="orderRules" label-width="79" size="small">
          </el-form>
        </el-col>
      </el-row>
      <el-row class="title-group" :gutter="10" v-if="orderForm.carrierStatus === 1||orderForm.carrierStatus === 3">
        <el-col :span="17">
          <p class="title">运输进程</p>
        </el-col>
      </el-row>
      <!--<div v-if="orderForm.carrierStatus === 1||orderForm.carrierStatus === 3">
                    <el-row>
                      <el-col :span="16">
                        <el-row class="times-tab">
                          <el-col :span="8"><p>订单生成时间</p></el-col>
                          <el-col :span="8"><p>运单生成时间</p></el-col>
                          <el-col :span="8"><p>转单所用时长</p></el-col>
                          <el-col :span="8">{{ orderForm.createTime | formatDate }}</el-col>
                          <el-col :span="8">{{
                              orderForm.orderItemLogVos.length > 0 ? orderForm.orderItemLogVos[0].createTime : '无'
                            }}-->
      <div class="process-line el-row"
           v-if="orderForm.carrierStatus === 1||orderForm.carrierStatus === 3&&log.orderItemLogVos.length>0">
        <el-col :span="3" v-for="item in 8" :key="item" :class="log.orderItemLogVos[item-1] ?'active':''">
          <el-row>
            <el-col :span="16">
              <p class="title" style="font-weight: 600">{{ statusList[item - 1] }}</p>
            </el-col>
            <el-col :span="8" v-if="item<8">
              <i class="el-icon-right el-m-t"/>
            </el-col>
            <el-col :span="16">
              <p>{{ log.orderItemLogVos[item - 1] ? log.orderItemLogVos[item - 1].createTime.split(' ')[0] : '' }}</p>
              <p>{{ log.orderItemLogVos[item - 1] ? log.orderItemLogVos[item - 1].createTime.split(' ')[1] : '' }}</p>
            </el-col>
            <el-col :span="8" v-if="item<8">
              {{ log.statusIntervalTime.length > 0 ? log.statusIntervalTime[item - 1] : '' }}
            </el-col>
          </el-row>
        </el-col>
      </div>
      <el-row class="title-group" :gutter="10" v-if="driverDetail">
        <el-col :span="17">
          <p class="title">承运信息</p>
        </el-col>
      </el-row>
      <div style="background: #FFFFFF" v-if="driverDetail">
        <el-row>
          <el-col :span="12">
            <el-row class="times-tab">
              <!--              <el-col :span="4"><p>抢单人姓名</p></el-col>-->
              <!--              <el-col :span="5"><p>抢单人手机号</p></el-col>-->
              <el-col :span="12"><p>承运司机姓名</p></el-col>
              <el-col :span="12"><p>承运司机手机号</p></el-col>
              <!--              <el-col :span="8"><p>承运司机身份证</p></el-col>-->
              <!--              <el-col :span="4">{{ driverDetail.grabName ? driverDetail.grabName : '无' }}</el-col>-->
              <!--              <el-col :span="5">{{ driverDetail.grabPhone ? driverDetail.grabPhone : '无' }}</el-col>-->
              <el-col :span="12">{{ driverDetail.userName ? driverDetail.userName : '无' }}</el-col>
              <el-col :span="12">{{ driverDetail.phone ? driverDetail.phone : '无' }}</el-col>
              <!--              <el-col :span="8">{{ driverDetail.idCardNumber ? driverDetail.idCardNumber : '无' }}</el-col>-->
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row class="times-tab">
              <el-col :span="12"><p>车头车牌</p></el-col>
              <el-col :span="12"><p>车挂车牌</p></el-col>
              <el-col :span="12">{{ driverDetail.plateNumber ? driverDetail.plateNumber : '无' }}</el-col>
              <el-col :span="12">{{ driverDetail.hangPlateNumber ? driverDetail.hangPlateNumber : '无' }}</el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <div style="padding-top: 45px">
              <el-button type="primary" size="mini" @click="certificatesClick" v-if="driverDetail">查看承运证件</el-button>
              <el-button size="mini" type="primary" @click="showMap">位置信息</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-row class="title-group" :gutter="10">
        <el-col :span="19">
          <p class="title">订单人员信息</p>
        </el-col>
      </el-row>
      <el-form ref="form5" label-position="top" :model="orderForm" :rules="orderRules" label-width="40%" class="form2"
               style="padding-top: 0;text-align: center;" :disabled="formType">
        <el-row style="padding-top:20px">
          <el-col :span="3">
            <el-form-item label="订单联系人姓名">
              <el-input placeholder="请输入" v-model="orderForm.orderContactName"/>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="订单联系人电话" prop="orderContactPhone">
              <el-input placeholder="请输入" v-input-limit='0' v-model="orderForm.orderContactPhone"/>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="发货人姓名">
              <el-input placeholder="请输入" v-model="orderForm.senderAddress.username"/>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="发货人手机号" prop="senderAddress.phone">
              <el-input placeholder="请输入" v-input-limit='0' v-model="orderForm.senderAddress.phone"/>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="收货人姓名">
              <el-input placeholder="请输入" v-model="orderForm.receiverAddress.username"/>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="border-right: 1px solid #eee;">
            <el-form-item label="收货人手机号" prop="receiverAddress.phone">
              <el-input placeholder="请输入" v-input-limit='0' v-model="orderForm.receiverAddress.phone"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="title-group" :gutter="10">
        <el-col :span="19">
          <p class="title">订单信息</p>
        </el-col>
      </el-row>
      <el-form ref="form1" :model="orderForm" :rules="orderRules" label-width="40%" class="form" :disabled="formType">
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货（装车）地址" prop="senderAddress.areaId" label-width="140px">
              <el-row style="background: #FFFFFF">
                <el-col :span="14">
                  <el-select v-model="orderForm.senderAddress.province" placeholder="省" style="width:30%"
                             value-key="provinceId" @change="addressChange1($event,1,'province')">
                    <el-option v-for="item in provinceOption1" :key="item.provinceId" :label="item.province"
                               :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="orderForm.senderAddress.city" placeholder="市" value-key="cityId"
                             style="width:35%"
                             @change="addressChange1($event,1,'city')">
                    <el-option v-for="item in cityOption1" :key="item.cityId" :label="item.city" :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="orderForm.senderAddress.area" placeholder="区/县" style="width:35%"
                             value-key="areaId"
                             @change="addressChange1($event,1,'area')">
                    <el-option v-for="item in areaOption1" :key="item.areaId" :label="item.area" :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="10">
                  <el-tooltip placement="top" effect="light" :content="orderForm.senderAddress.addressDetail"
                              :disabled="orderForm.senderAddress.addressDetail.length <= 11">
                    <el-input v-model="orderForm.senderAddress.addressDetail" placeholder="请输入内容"/>
                  </el-tooltip>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发货（装车）时间">
              <el-col :span="12">
                <el-date-picker v-model="planLoadDate" type="date" valueFormat="yyyy-MM-dd"
                                :picker-options="pickerOptions" :placeholder="orderForm.planLoadDate"
                                @change="planTimeChange"/>
              </el-col>
              <el-col :span="12">
                <el-select v-model='orderForm.planLoadTime' :disabled="planLoadStatus">
                  <el-option v-for="(item,index) in hourOption" :key="index" :value="item.value" :label="item.label"/>
                </el-select>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="收货（卸车）地址" prop="receiverAddress.areaId" label-width="140px">
              <el-row style="background: #FFFFFF">
                <el-col :span="14">
                  <el-select v-model="orderForm.receiverAddress.province" placeholder="省" style="width:30%"
                             value-key="provinceId" @change="addressChange($event,0,'province')">
                    <el-option v-for="item in provinceOption" :key="item.provinceId" :label="item.province"
                               :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="orderForm.receiverAddress.city" placeholder="市" value-key="cityId"
                             style="width:35%" @change="addressChange($event,0,'city')">
                    <el-option v-for="item in cityOption" :key="item.cityId" :label="item.city" :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="orderForm.receiverAddress.area" placeholder="区/县" style="width:35%"
                             value-key="areaId" @change="addressChange($event,0,'area')">
                    <el-option v-for="item in areaOption" :key="item.areaId" :label="item.area" :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="10">
                  <el-tooltip placement="bottom" effect="light" :content="orderForm.receiverAddress.addressDetail"
                              :disabled="orderForm.receiverAddress.addressDetail.length <= 11">
                    <el-input v-model="orderForm.receiverAddress.addressDetail" placeholder="请输入内容"/>
                  </el-tooltip>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货（卸车）时间">
              <el-date-picker v-model="orderForm.planArriveTime" valueFormat="timestamp" type="datetime"
                              :picker-options="pickerOptions1"
                              placeholder="选择日期时间"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="货物名称">
              <el-autocomplete v-model="orderForm.goodsInfo.goodsName" valueFormat=""
                               :fetch-suggestions="querySearchAsync"
                               placeholder="请输入内容" @select="goodNameSel"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="货物重量（吨）">
              <el-input v-input-limit='2' v-model="orderForm.goodsInfo.goodsWeight" placeholder="请输入"
                        @input="weightInput"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="货物尺寸-长(米)">
              <el-input v-model="orderForm.goodsInfo.overrunInfo.length" v-input-limit='2' placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="货物尺寸-宽（米）">
              <el-input v-model="orderForm.goodsInfo.overrunInfo.width" v-input-limit='2' placeholder="请输入"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="货物尺寸-高（米）">
              <el-input v-model="orderForm.goodsInfo.overrunInfo.high" v-input-limit='2' placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配送方式">
              <el-select v-model='orderForm.deliveryType' placeholder="未选择">
                <el-option v-for="(item,index) in deliveryTypeOption" :key="index" :value="item.value"
                           :label="item.label"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车板造型要求">
              <el-select v-model="orderForm.vehicleRequirementsInfo.tcarHangTypeList" value-key="id" placeholder="请选择"
                         multiple>
                <el-option v-for="item in tcarHangTypeOptions" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车挂长度要求">
              <el-select v-model="orderForm.vehicleRequirementsInfo.tcarLengthList" value-key="id" placeholder="请选择"
                         multiple>
                <el-option v-for="item in tcarLengthOptions" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="鹅颈要求">
              <el-select v-model="orderForm.vehicleRequirementsInfo.tcarGooseneckTypeList" value-key="id"
                         placeholder="请选择" multiple>
                <el-option v-for="item in tcarGooseneckTypeOptions" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="特殊要求">
              <el-select v-model="orderForm.carAccessories" value-key="id" placeholder="请选择"
                         multiple>
                <el-option v-for="item in tcarAccessoryOptions" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="装卸次数">
              <el-select v-model="orderForm.loadTimes" placeholder="未选择">
                <el-option v-for="item in loadTimeOption" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路况选择">
              <el-select v-model="orderForm.vehicleRequirementsInfo.roadConditionList" value-key="id"
                         placeholder="请选择"
                         multiple>
                <el-option v-for="item in roadConditionOptions" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label=加急>
              <el-select v-model="orderForm.expedited" placeholder="未选择">
                <el-option label="未选择" :value="0"/>
                <el-option label="不加急" :value="1"/>
                <el-option label="加急" :value="2"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label=装卸特殊要求>
              <el-select v-model="orderForm.serviceRequirements.loadUnloadRequireList" multiple collapse-tags
                         value-key="id" placeholder="请选择">
                <el-option v-for="item in loadUnloadRequireOption" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding-bottom: 1px;">
          <el-col :span="12">
            <el-form-item label='要装货照片'>
              <el-select v-model="orderForm.isZhZp" placeholder="未选择">
                <el-option label="否" :value="0"/>
                <el-option label="是" :value="1"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label='要卸货照片'>
              <el-select v-model="orderForm.isXhZp" placeholder="未选择">
                <el-option label="否" :value="0"/>
                <el-option label="是" :value="1"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!--        <el-row style="border-bottom: 1px solid #eee;">
                  <el-col :span="12">
                    <el-form-item label=防疫特殊要求>
                      <el-select v-model="orderForm.serviceRequirements.preventionRequireList" value-key="id" collapse-tags
                                 placeholder="请选择">
                        <el-option v-for="item in preventionRequireOption" :key="item.id" :label="item.name" :value="item"/>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>-->
      </el-form>
      <el-row class="title-group" :gutter="10">
        <el-col :span="5">
          <p class="title">订单费用相关</p>
        </el-col>
        <el-col :span="19">
          <!--          <el-button size="small" type="primary" @click="enclosureClick">查看附件
                    </el-button>-->
          <el-button size="small" type="primary" @click="enclosureClick">查看回单</el-button>
          <el-button size="small" type="primary" @click="openPhoto">查看照片
          </el-button>

          <!--          <el-button size="small" v-else type="primary" @click="enclosureClick">查看回单</el-button>-->
          <!--          <el-button size="small" v-if="id" type="primary" @click="quoteLogClick">报价修改历史</el-button>-->
        </el-col>
      </el-row>
      <el-form ref="form5" label-position="top" :model="orderForm" :rules="orderRules" label-width="40%" class="form2"
               style="padding-top: 0;text-align: center" :disabled="formType">
        <el-row style="padding-top:20px">
          <el-col :span="3">
            <el-form-item label="出价方式">
              <el-select v-model="orderForm.quoteCode" placeholder="请选择" @change="quoteChange">
                <el-option v-for="item in quoteCodeOptions" :key="item.id" :label="item.name" :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="数量">
              <el-input placeholder="请输入" v-input-limit='2' v-model="orderForm.num" @input="payTypeInput"/>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="单价">
              <el-input placeholder="请输入" v-input-limit='2' v-model="orderForm.price" @input="priceInput"/>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="3">
                      <el-form-item label="是否要发票">
                        <el-select v-model="orderForm.haveInvoice" placeholder="请选择" @change="needInvoiceChange"
                                   :disabled="orderForm.payerType ==='RECEIVER'">
                          <el-option v-for="item in hasOptions" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="3">
                      <el-form-item label="发票开具方">
                        <el-select v-model="orderForm.invoiceSource" placeholder="请选择">
                          <el-option label="未选择" :value="0"/>
                          <el-option label="平台开具" :value="1"/>
                          <el-option label="车方开具" :value="2"/>
                        </el-select>
                      </el-form-item>
                    </el-col>-->
          <el-col :span="3">
            <el-form-item label="付款方">
              <el-select v-model="orderForm.payerType" placeholder="请选择" @change="payTypeChange"
                         :disabled="orderForm.haveInvoice == 1">
                <el-option label="发货方付款" :value="1"/>
                <el-option label="收货方付款" :value="2"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="付款方式" prop="payerWay">
              <el-select v-model="orderForm.payerWay" placeholder="请选择">
                <el-option label="线上结算" :value="1"/>
                <el-option label="线下结算" :value="2"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="border-right: 1px solid #eee;">
            <el-form-item label="车方定金">
              <el-input placeholder="请输入" v-input-limit='2' v-model="orderForm.deposit"/>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="定金是否退还">
              <el-select v-model="orderForm.isRefundDeposit" placeholder="请选择">
                <el-option label="退还" :value="0"/>
                <el-option label="不退还" :value="1"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="是否含油卡">
              <el-select v-model="orderForm.isOil" placeholder="请选择" @change="OilCardChange">
                <el-option v-for="item in boolOption" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="油卡金额">
              <el-input placeholder="请输入" v-input-limit='2' v-model="orderForm.oilCardAmount"
                        @input="oilCardAmountInput"/>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="是否需要回单">
              <el-select v-model="orderForm.isReceipt" placeholder="请选择">
                <el-option label="否" :value="0"/>
                <el-option label="是" :value="1"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="回单押金">
              <el-input placeholder="请输入" v-input-limit='2' v-model="orderForm.receiptAmount"
                        :disabled="orderForm.payerType ==='RECEIVER'"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="title-group" :gutter="10">
        <el-col :span="24">
          <p class="title">发货照片 ({{ orderForm.goodsInfo.photosUrls && orderForm.goodsInfo.photosUrls.length }}/3)</p>
          <div style="text-align: left;margin-top: 12px">
            <el-radio-group v-model="orderForm.goodsInfo.photoShowTime" :disabled="formType">
              <el-radio :label="0">司机抢单前显示</el-radio>
              <el-radio :label="1">司机抢单后显示</el-radio>
            </el-radio-group>
          </div>
          <el-form class="form" :disabled="formType">
            <uploadList ref="ID" :imgList="imgList" v-on:photoChange="photoChange($event)"
                        v-on:removeImg="removeImg($event)"/>
          </el-form>
        </el-col>
        <el-col :span="12" style="text-align: left">
          <p class="title">装货照片 <span
              style="font-size: 13px;font-weight: 500">{{ orderForm.isZhZp == 0 ? '（未要求司机必传）' : '（要求司机必传）' }}</span></p>
          <p style="text-align: left;line-height: 3em;font-weight: 500">装货位置：{{ moveForm.currentPosition }}</p>
          <p style="text-align: left;line-height: 3em;font-weight: 500">装货时间：{{ moveForm.driverLoadingTime }}</p>
          <el-image style="width: 100px; height: 100px;margin-right: 10px"
                    v-for="(item,index) in moveForm.driverOtherUrl"
                    :src="item" :key="index" :preview-src-list="[item]"/>
        </el-col>
        <el-col :span="12" style="text-align: left">
          <p class="title">卸货照片 <span
              style="font-size: 13px;font-weight: 500">{{ orderForm.isXhZp == 0 ? '（未要求司机必传）' : '（要求司机必传）' }}</span></p>
          <p style="text-align: left;line-height: 3em;font-weight: 500">卸货位置：{{ moveForm.unloadingCurrentPosition }}</p>
          <p style="text-align: left;line-height: 3em;font-weight: 500">卸货时间：{{ moveForm.driverUnLoadingTime }}</p>
          <el-image style="width: 100px; height: 100px;margin-right: 10px"
                    v-for="(item,index) in moveForm.driverUnloadingPhotoUrl"
                    :src="item" :key="index" :preview-src-list="[item]"/>
          <el-image style="width: 100px; height: 100px;margin-right: 10px"
                    v-for="(item,index) in moveForm.driverUnloadingOtherPhotoUrl"
                    :src="item" :key="index" :preview-src-list="[item]"/>
        </el-col>
      </el-row>
      <el-row class="title-group" :gutter="10">
        <el-col :span="8">
          <p class="title">车辆造型备注</p>
          <div style="margin-top: 15px">
            <el-input type="textarea" :disabled="formType" :autosize="{ minRows: 6, maxRows: 6}"
                      placeholder="请输入内容"
                      v-model="orderForm.vehicleRequirementsInfo.carHangOther"/>
          </div>
        </el-col>
        <el-col :span="8">
          <p class="title">特殊需求备注</p>
          <div style="margin-top: 15px">
            <el-input type="textarea" :disabled="formType" :autosize="{ minRows: 6, maxRows: 6}"
                      placeholder="请输入内容"
                      v-model="orderForm.goodsInfo.additionalRequirementInfo"/>
          </div>
        </el-col>
        <el-col :span="8">
          <p class="title">订单备注</p>
          <div style="margin-top: 15px">
            <el-input type="textarea" :disabled="formType" :autosize="{ minRows: 6, maxRows: 6}"
                      placeholder="请输入内容"
                      v-model="orderForm.remark"/>
          </div>
        </el-col>
        <el-col :span="24">
          <p class="title">货主备注</p>
          <div style="margin-top: 15px">
            <el-col :span="12" v-for="(item,k) in remarkList" :key="item.id" style="text-align: left">
              <el-card class="box-card" style="margin-bottom: 10px;height: 200px;overflow-y: scroll">
                <p>序号：{{ k + 1 }}</p>
                <p v-if="item.uploadDevice">创建时间：{{ item.createTime }}</p>
                <p v-if="item.realShipperCompany">真实货主企业：{{ item.realShipperCompany }}</p>
                <p v-if="item.deviceName">设备型号：{{ item.deviceName }}</p>
                <p v-if="item.textRemarks">备注：{{ item.textRemarks }}</p>
                <p v-if="item.sources">信息来源：
                  <span v-if="item.sources == 0">未知</span>
                  <span v-if="item.sources == 1">android货主端</span>
                  <span v-if="item.sources == 2">ios货主端</span>
                  <span v-if="item.sources == 3">小程序</span>
                </p>
                <p v-if="item.uploadPosition">提交位置：{{ item.uploadPosition }}</p>
                <p v-if="item.uploadDevice">上传设备：{{ item.uploadDevice }}</p>
                <div style="display: inline-grid" v-for="(img,index) in item.pictureRemarks" :key="index">
                  <el-image :src="img" style="width: 70px;height: 70px;margin-left: 5px" :preview-src-list="[img]"/>
                  <span style="width: 70px;text-align: center" v-if="item.pictureSources.length>0">
                    <span v-if="item.pictureSources[index] == 0">相册</span>
                    <span v-if="item.pictureSources[index] == 1">相机</span>
                  </span>
                </div>
              </el-card>
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--修改卸货地-->
    <el-dialog title="修改卸货地" :visible.sync="reviseAddressVisible" width="40%" center>
      <el-form ref="reviseAddressForm" :model="reviseAddressForm" :rules="reviseAddressRules" label-width="100px"
               size="medium" @close="closeArs">
        <el-form-item label="卸货地址：">
          <el-autocomplete v-model="reviseAddressForm.addressDetail" :fetch-suggestions="addressAsync"
                           placeholder="详细地址" @select="arsSelect" style="width: 100%;"/>
        </el-form-item>
        <el-form-item label="修改凭证：">
          <uploadList :img-list="arsImgList" v-on:photoChange="arsChange($event)"
                      v-on:removeImg="arsrmImg($event)"/>
        </el-form-item>
        <el-form-item label="备注信息：">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="reviseAddressForm.remarks"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeArs">取 消</el-button>
        <el-button type="primary" @click="subArs">确 定</el-button>
      </div>
    </el-dialog>
    <!--换车原因-->
    <el-dialog title="换车原因" :visible.sync="cutCarVisible" width="40%" center>
      <el-form ref="cutCarForm" :model="cutCarForm" label-width="100px"
               size="medium" @close="clsCutCar" :rules="cutCarRules">
        <el-form-item label="备注信息：">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="cutCarForm.remarks"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clsCutCar">取 消</el-button>
        <el-button type="primary" @click="subCutCar">确 定</el-button>
      </div>
    </el-dialog>
    <!--上传凭证-->
    <el-dialog title="上传凭证" :visible.sync="proveVisible" width="40%" center @close="clsProve">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="凭证类型：">
          <el-select v-model="proveType" placeholder="请选择">
            <el-option v-for="item in proveOption" :key="item.value" :label="item.label" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="选择文件：">
          <el-upload action="/" :http-request="selectFile" :before-upload="beforeAvatarUpload"
                     :show-file-list="false" ref="uploadFile">
            <el-button size="small" type="primary">上传本地文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="upload-voucher">
        <div class="block" v-for="(item,index) in uploadList" :key="index">
          <div class="active">
            <p>
              <el-upload action="/" :http-request="selectFile" :before-upload="beforeAvatarUpload"
                         :show-file-list="false" ref="uploadFile">
                <el-link style="color: #ffffff" :underline="false">重新上传</el-link>
              </el-upload>
            </p>
            <p @click="delImg(index)">删除</p>
          </div>
          <el-image style="width: 100px; height: 100px" :src="item.image" fit="cover"/>
          <p class="demonstration">{{ proveForMat(item.type) }}</p>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clsProve">取 消</el-button>
        <el-button type="primary" @click="subProve">确 定</el-button>
      </div>
    </el-dialog>
    <!--录音-->
    <el-dialog title="录音" :visible.sync="recordingVisible" width="820px" center>
      <div class="content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item,index) in recordingList" :label="'录音'+(index+1)" :name="index.toString()"
                       :key="index">
          </el-tab-pane>
          <el-table :data="dialTable" border>
            <el-table-column prop="date" label="状态时间" width="110" align="center"/>
            <el-table-column prop="status" label="当前状态" width="110" align="center"/>
            <el-table-column prop="phone" label="拨出号码" width="110" align="center"/>
            <el-table-column prop="name" label="发货人姓名" width="110" align="center"/>
            <el-table-column prop="record" label="录音" align="center">
              <template slot-scope="scope">
                <audio v-if="scope.row.record" controls='controls' :src='scope.row.record'>收到一条语音</audio>
                <p v-else>当前阶段无录音</p>
              </template>
            </el-table-column>
          </el-table>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="recordingVisible = false">取 消</el-button>
        <el-button type="primary" @click="recordingVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <!--位置信息-->
    <el-dialog :title="'订单状态：'+orderType" :visible.sync="mapDialogVisible" width="1200px" center>
      <el-row>
        <el-col :span="6">
          <p style="text-align: left;width: 18em"><span style="font-weight: 600">起</span>:订单装货地址 </p>
          <p style="text-align: left;width: 18em"><span style="font-weight: 600">终</span>:订单卸货地址 </p>
          <p style="text-align: left;width: 18em"><span style="font-weight: 600">装</span>:实际上传装货照片位置(可点击查看) </p>
          <p style="text-align: left;width: 18em"><span style="font-weight: 600">卸</span>:实际上传卸货照片位置(可点击查看) </p>
        </el-col>
        <el-col :span="18" style="text-align: right">
        </el-col>
      </el-row>
      <div class='map-div' style='height:600px'>
        <mapSport :info="positionInfo" :trackLineArr="trackLine" :phoneLineArr="phoneLine" :stopSpots="stopSpotsArr"
                  :stopTimesArr="stopTimesArr" :id="orderId"/>
      </div>
    </el-dialog>
    <!--查看承运证件-->
    <el-dialog title="承运证件" :visible.sync="certificatesVisible">
      <el-row class="title-group" :gutter="10">
        <el-col :span="19">
          <p class="title">身份证</p>
        </el-col>
      </el-row>
      <el-row :gutter="10" style="text-align: center">
        <el-col :span="12">
          <el-image style="width: 100px; height: 100px" :src="certificatesData.idCardPositiveUrl"
                    v-if="certificatesData.idCardPositiveUrl"
                    :preview-src-list="[certificatesData.idCardPositiveUrl]"/>
        </el-col>
        <el-col :span="12">
          <el-image style="width: 100px; height: 100px" :src="certificatesData.idCardNegativeUrl"
                    v-if="certificatesData.idCardNegativeUrl"
                    :preview-src-list="[certificatesData.idCardNegativeUrl]"/>
        </el-col>
      </el-row>
      <el-row class="title-group" :gutter="10">
        <el-col :span="19">
          <p class="title">驾驶证</p>
        </el-col>
      </el-row>
      <el-row :gutter="10" style="text-align: center">
        <el-col :span="12">
          <el-image style="width: 100px; height: 100px" :src="certificatesData.driverLicenseFrontUrl"
                    v-if="certificatesData.driverLicenseFrontUrl"
                    :preview-src-list="[certificatesData.driverLicenseFrontUrl]"/>
        </el-col>
        <el-col :span="12">
          <el-image style="width: 100px; height: 100px" :src="certificatesData.driverLicenseBackUrl"
                    v-if="certificatesData.driverLicenseBackUrl"
                    :preview-src-list="[certificatesData.driverLicenseBackUrl]"/>
        </el-col>
      </el-row>
      <el-row class="title-group" :gutter="10">
        <el-col :span="19">
          <p class="title">车头行驶证</p>
        </el-col>
      </el-row>
      <el-row :gutter="10" style="text-align: center">
        <el-col :span="12">
          <el-image style="width: 100px; height: 100px" :src="certificatesData.carHeadFrontLicenseUrl"
                    v-if="certificatesData.carHeadFrontLicenseUrl"
                    :preview-src-list="[certificatesData.carHeadFrontLicenseUrl]"/>
        </el-col>
        <el-col :span="12">
          <el-image style="width: 100px; height: 100px" :src="certificatesData.carHeadBackLicenseUrl"
                    v-if="certificatesData.carHeadBackLicenseUrl"
                    :preview-src-list="[certificatesData.carHeadBackLicenseUrl]"/>
        </el-col>
      </el-row>
      <el-row class="title-group" :gutter="10">
        <el-col :span="19">
          <p class="title">车挂行驶证</p>
        </el-col>
      </el-row>
      <el-row :gutter="10" style="text-align: center">
        <el-col :span="12">
          <el-image style="width: 100px; height: 100px" :src="certificatesData.carHangFrontLicenseUrl"
                    v-if="certificatesData.carHangFrontLicenseUrl"
                    :preview-src-list="[certificatesData.carHangFrontLicenseUrl]"/>
        </el-col>
        <el-col :span="12">
          <el-image style="width: 100px; height: 100px" :src="certificatesData.carHangBackLicenseUrl"
                    v-if="certificatesData.carHangBackLicenseUrl"
                    :preview-src-list="[certificatesData.carHangBackLicenseUrl]"/>
        </el-col>
      </el-row>
      <el-row class="title-group" :gutter="10">
        <el-col :span="19">
          <p class="title">从业资格证</p>
        </el-col>
      </el-row>
      <el-row :gutter="10" style="text-align: center">
        <el-col :span="12">
          <div class="placeholderBox" v-if="!certificatesData.qualificationCertificate">用户未上传</div>
          <el-image v-else style="width: 200px; height: 200px" :src="certificatesData.qualificationCertificate"
                    :preview-src-list="[certificatesData.qualificationCertificate]"/>
        </el-col>
      </el-row>
      <el-row class="title-group" :gutter="10">
        <el-col :span="19">
          <p class="title">车头道路运输证</p>
        </el-col>
      </el-row>
      <el-row :gutter="10" style="text-align: center">
        <el-col :span="12">
          <div class="placeholderBox" v-if="!certificatesData.roadTransportPermit">用户未上传</div>
          <el-image v-else style="width: 200px; height: 200px" :src="certificatesData.roadTransportPermit"
                    :preview-src-list="[certificatesData.roadTransportPermit]"/>
        </el-col>
      </el-row>
    </el-dialog>
    <!--修改报价历史-->
    <el-dialog title="报价修改历史" :visible.sync="quoteLogVisible" width="50%">
      <el-table :data="quoteLogData" border style="width: 100%">
        <el-table-column prop="createTimeLocal" label="修改时间" width="180"/>
        <el-table-column prop="deposit" label="定金金额" width="180">
          <template slot-scope="scope">
            {{ scope.row.deposit / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="isOilCardAmount" label="是否含油卡" width="180">
          <template slot-scope="scope">
            {{ scope.row.deposit ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="isReceiptAmount" label="是否需要回单" width="180">
          <template slot-scope="scope">
            {{ scope.row.deposit ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="isRefundDeposit" label="定金是否退还" width="180">
          <template slot-scope="scope">
            {{ scope.row.deposit ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="needInvoice" label="是否需要开发票" width="180">
          <template slot-scope="scope">
            {{ scope.row.needInvoice ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量" width="180">
          <template slot-scope="scope">
            {{ scope.row.num }}
          </template>
        </el-table-column>
        <el-table-column prop="oilCardAmount" label="油卡金额" width="180">
          <template slot-scope="scope">
            {{ scope.row.oilCardAmount / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="payerWay" label="付款方式" width="180">
          <template slot-scope="scope">
            <p v-if="scope.row.payerWay==1">线上结算</p>
            <p v-if="scope.row.payerWay==2">线下结算</p>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价" width="180">
          <template slot-scope="scope">
            {{ scope.row.price }}
          </template>
        </el-table-column>
        <el-table-column prop="quoteCode" label="出单方式" width="180">
          <template slot-scope="scope">
            <p v-if="scope.row.deposit==1">每单</p>
            <p v-if="scope.row.deposit==2">每吨</p>
            <p v-if="scope.row.deposit==3">每件</p>
          </template>
        </el-table-column>
        <el-table-column prop="receiptAmount" label="回单金额" width="180">
          <template slot-scope="scope">
            {{ scope.row.receiptAmount / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice	" label="订单总价" width="180">
          <template slot-scope="scope">
            {{ scope.row.totalPrice / 100 }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--附件-->
    <el-dialog title="附件" :visible.sync="enclosureVisible" width="50%">
      <div v-if="enclosureList">
        <div>位置：{{ enclosureList.currentPosition }}</div>
        <div>定位时间：{{ enclosureList.createTime }}</div>
        <div v-if="enclosureList.receiptUrls.length>0">
          <h3>回单照片</h3>
          <el-image v-for="(item,index) in enclosureList.receiptUrls" style="width: 100px; height: 100px" :src="item"
                    :preview-src-list="[item]" :key="index"/>
        </div>
        <div v-if="enclosureList.otherUrls.length>0">
          <h3>其他照片</h3>
          <el-image v-for="(item,index) in enclosureList.otherUrls" style="width: 100px; height: 100px" :src="item"
                    :preview-src-list="[item]" :key="index"/>
        </div>
      </div>
      <div v-else style="text-align: center">
        <h2>暂无照片</h2>
      </div>
    </el-dialog>
    <!--其他照片-->
    <el-dialog title="查看照片" :visible.sync="otherVisible" width="50%">
      <div v-if="otherUrlList.length>0" style="position: relative">
        <div style="height: 500px;overflow-y: scroll;padding-bottom: 50px">
          <div v-for="(item,index) in otherUrlList" :key="index">
            <div>位置：{{ item.currentPosition }}</div>
            <div>定位时间：{{ item.creatTime }}</div>
            <div>上传时订单状态：{{ statusFormart(item.orderItemStatus) }}</div>
            <div v-if="item.picList.length>0" style="margin-top: 10px">
              <div v-for="(img,i) in item.picList" style="display: inline-block;margin-right: 10px">
                <el-image style="width: 100px; height: 100px" :src="img"
                          :preview-src-list="[img]" :key="i"/>
                <p style="text-align: center">（{{ item.picSourceList[i] == 1 ? '相机' : '照片' }}）</p>
              </div>
            </div>
            <el-divider/>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center">
        <h2>暂无照片</h2>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getGoodsName,
  upAddress,
  getLoadUnloadRequire,
  getPreventionRequire,
  orderInfo,
  selectUpdateOrder,
  orderModify,
  driverInfo,
  orderInfo1,
  vehicleRequirements,
  getDriverCertificateById,
  listByParent,
  order2Location,
  orderHistory,
  getCarType,
  selectOrderIsLock,
  findArea,
  findCity,
  selectReceipt,
  findProvince,
  updateOrder, getUploadLog, shipperRemarksList
} from '@/request/api';
import {carLocationLink, getWaybillLog} from "@/request/order"
import {vehiclesTracks} from '@/request/map'
import area from '@/utils/area.json';
import uploadList from '@/components/upload-list';
import mapSport from "@/components/mapSport/index";
import uploads from '@/components/uploads/index';
import uploadFile from "@/utils/uploadFile";
import {formatDate} from '@/utils/formatDate.js';


export default {
  name: "orderForm",
  components: {uploadList, mapSport, uploads},
  data() {
    return {
      otherVisible: false,
      orderItemStatusOptions: [
        {label: '已开始', value: 1},
        {label: '待装车', value: 2},
        {label: '装车中', value: 3},
        {label: '已装车', value: 4},
        {label: '运输中', value: 5},
        {label: '已转单', value: 6},
        {label: '已到达卸货地', value: 7},
        {label: '已完成', value: 8},
        {label: '已取消', value: 9}
      ],
      otherUrlList: [],
      id: '',
      log: {
        orderItemLogVos: [],
        statusIntervalTime: []
      },
      orderId: '',
      moveForm: {},
      trackLine: [],
      phoneLine: [],
      stopSpotsArr: [],
      stopTimesArr: [],
      foldType: true,
      orderType: '',
      formType: false,
      driverDetail: {},
      placeSearch: '',
      toAddressDetail: '',
      addressDetail: '',
      // 装卸方式op
      loadTimeOption: [
        // {label: '未选择', value: 0},
        {label: '一装一卸', value: 1},
        {label: '一装二卸', value: 2},
        {label: '二装一卸', value: 3},
        {label: '二装二卸', value: 4},
      ],
      areaList: [],
      orderForm: {
        isXhZp: '',
        isZhZp: '',
        channel: 9,
        amount: 0,
        carPrice: 0,
        cargoOwnerType: "",
        clueId: 0,
        serviceRequirements: {
          preventionRequireList: [],
          loadUnloadRequireList: []
        },
        driverOtherUrl: [],
        currentPosition: "",
        createOrderName: "",
        createOrderPhone: "",
        deposit: 0,
        depositStatus: 0,
        senderAddress: {
          adcode: "",
          addressDetail: "",
          area: "",
          city: "",
          latitude: 0,
          longitude: 0,
          phone: "",
          province: "",
          userAddressId: 0,
          username: ""
        },
        receiverAddress: {
          adcode: "",
          addressDetail: "",
          area: "",
          areaId: "",
          city: "",
          cityId: "",
          latitude: 0,
          longitude: 0,
          phone: "",
          province: "",
          provinceId: "",
          userAddressId: 0,
          username: ""
        },
        difference: 0,
        dirverPhoto: {
          carDirectionUrl: "",
          currentPosition: "",
          hangDirectionUrl: "",
          otherUrlList: []
        },
        driverId: 0,
        driverPhone: "",
        importance: 0,
        isActing: true,
        isOilCardAmount: true,
        isRefundDeposit: 0,
        name: "",
        num: 0,
        orderContactName: "",
        orderContactPhone: "",
        orderItemLogVos: [
          {
            createTime: "",
            currentLocationArea: "",
            currentLocationCity: "",
            currentLocationCode: "",
            currentLocationLatitude: 0,
            currentLocationLongitude: 0,
            currentLocationProvince: "",
            id: 0,
            orderItemNo: "",
            remarks: "",
            status: 0
          }
        ],
        orderItemVo: {
          carpoolNo: "",
          createLog: {
            createTime: "",
            currentLocationArea: "",
            currentLocationCity: "",
            currentLocationCode: "",
            currentLocationLatitude: 0,
            currentLocationLongitude: 0,
            currentLocationProvince: "",
            id: 0,
            orderItemNo: "",
            remarks: "",
            status: 0
          },
          createTime: "",
          createType: 0,
          driverMobile: "",
          driverName: "",
          hangPlateNumber: "",
          id: 0,
          isCarpool: true,
          isTransfer: true,
          orderItemNo: "",
          orderNo: "",
          paymentStatus: 0,
          plateNumber: "",
          remarks: "",
          status: 0,
          updateTime: ""
        },
        payStatus: "",
        payTime: "",
        plateNumber: "",
        price: 0,
        quoteCode: 0,
        receiptAmount: 0,
        refundsAmount: 0,
        statusIntervalTime: [],
        taxCost: 0,
        transferTime: "",
        vehicleRequirementsInfo: {
          carHangOther: "",
          roadConditionList: [],
          tcarGooseneckTypeList: [],
          tcarHangTypeList: [],
          tcarLengthList: []
        },
        id: 0,
        createTime: 0,
        modifyTime: "",
        orderNo: "",
        orderType: 0,
        status: 0,
        carrierStatus: 0,
        goodsInfo: {
          additionalRequirementInfo: "",
          additionalRequirementList: "",
          attachment: 0,
          photoShowTime: 0,
          goodsClassifyName: "",
          goodsName: "",
          goodsWeight: 0,
          overrunInfo: {
            high: 0,
            length: 0,
            width: 0
          },
          needCarLength: 0,
          overrun: 0,
          photos: [],
          photosUrl: "",
        },
        vehicleType: [],
        pathEnd: "",
        pathStart: "",
        planLoadDate: "",
        planLoadTime: "",
        distance: 0,
        totalPrice: 0,
        pricePerKm: 0,
        driverCallCount: 0,
        driverPullCount: 0,
        orderInfoDriverCount: 0,
        phoneFlag: true,
        userId: 0,
        clueInfo: {
          id: 0,
          provider: "",
          providerPhone: "",
          source: ""
        },
        planArriveTime: "",
        expedited: 0,
        deliveryType: 0,
        loadTimes: "",
        remark: "",
        loaders: [
          {
            phone: "",
            userName: ""
          }
        ],
        unLoaders: [
          {
            phone: "",
            userName: ""
          }
        ],
        paymentInfo: {
          ifPay: true,
          marginAccount: 0,
          payer: "",
          state: 0
        },
        paymentType: 0,
        oilCardAmount: 0,
        serviceFee: 0,
        serviceFeePayState: 0,
        needInvoice: 0,
        hasInsurance: 0,
        hasNarrowRoad: 0,
        cancelReasonDescription: "",
        paymentState: 0,
        isSpeclifiedDriver: "",
        appointorName: "",  //指定人姓名
        appointorPhone: "", //指定人手机号
        operationResult: "",   //"指定人是否接单(0等待处理 1司机同意接单 2司机拒绝接单 3货主正常下单 4货主取消订单)")
        cancelUser: "",  //"订单取消方（-1-默认，0-货主，1-司机，2-运营管理平台）"
        tloadUnloadRequireEntity: "",
        tpreventionRequireEntity: ""
      },
      actualPrice: 0,
      senderList: [],
      receiverList: [],
      imgList: [],
      provinceOption: [],
      areaOption: [],
      cityOption: [],
      provinceOption1: [],
      areaOption1: [],
      cityOption1: [],
      statusList: ['已接单', '开始取货', '已到达装货地', '已装货', '开始送货', '已到达卸货地', '已卸货', '已完成'],
      orderRules: {
        // "goodsClassifyName": {required: true, message: '不能为空', trigger: 'blur'},
        "orderContactPhone": [
          {min: 11, max: 11, message: '手机号码不低于11位数', trigger: 'blur'},
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码格式',
            trigger: 'blur'
          }
        ],
        "receiverAddress.phone": [
          {min: 11, max: 11, message: '手机号码不低于11位数', trigger: 'blur'},
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码格式',
            trigger: 'blur'
          }
        ],
        "senderAddress.phone": [
          {min: 11, max: 11, message: '手机号码不低于11位数', trigger: 'blur'},
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码格式',
            trigger: 'blur'
          }
        ],
      },
      // 线索渠道op
      sourceChannelOption: [
        {label: 'VIP', value: '1'},
        {label: '小程序', value: '2'},
        {label: '特运通', value: '3'},
        {label: '微信私聊', value: '4'},
        {label: '其他', value: '5'},
        {label: '货主端app', value: '6'},
        {label: '微信群聊', value: '7'},
        {label: '微信朋友圈', value: '8'},
      ],
      // 附件op
      hasOptions: [
        {label: '是', value: 1},
        {label: '否', value: 0}
      ],
      otherType: false,
      boolOption: [
        {label: '是', value: 1},
        {label: '否', value: 0},
      ],
      // 运输方式拍op
      deliveryTypeOption: [
        {label: '未选择', value: 0},
        {label: '整车', value: 1},
        {label: '拼车', value: 2},
      ],
      // 装卸方式op
      // loadTimeOption: [
      //   {label: '未选择', value: 0},
      //   {label: '一装一卸', value: 1},
      //   {label: '一装二卸', value: 2},
      //   {label: '二装一卸', value: 3},
      //   {label: '二装二卸', value: 4},
      // ],
      // 付款方式op
      paymentOption: [
        {label: '未选择', value: 0},
        {label: '微信', value: 1},
        {label: '银行卡', value: 2},
        {label: '现金', value: 3},
        {label: '部分油卡', value: 4},
        {label: '承兑汇票', value: 5},
        {label: '支付宝', value: 6},
      ],
      // 支付状态
      paymentStatusOption: [
        {label: '待支付', value: 0},
        {label: '支付中', value: 1},
        {label: '支付成功', value: 2},
        {label: '退款中', value: 3},
        {label: '退款成功', value: 4},
        {label: '支付失败', value: 129},
        {label: '退款失败', value: 130},
        {label: '支付关闭', value: 131},
      ],
      // 计划装车时间
      hourOption: [
        {label: '全天都可以', value: '00:00-23:59'},
        {label: '凌晨00:00-06:00', value: '00:00-06:59'},
        {label: '凌晨00:00', value: '00:00-00:59'},
        {label: '凌晨01:00', value: '01:00-01:59'},
        {label: '凌晨02:00', value: '02:00-02:59'},
        {label: '凌晨03:00', value: '03:00-03:59'},
        {label: '凌晨04:00', value: '04:00-04:59'},
        {label: '凌晨05:00', value: '05:00-05:59'},
        {label: '上午06:00', value: '06:00-06:59'},
        {label: '上午07:00', value: '07:00-07:59'},
        {label: '上午08:00', value: '08:00-08:59'},
        {label: '上午09:00', value: '09:00-09:59'},
        {label: '上午10:00', value: '10:00-10:59'},
        {label: '上午11:00', value: '11:00-11:59'},
        {label: '上午 06:00-12:00', value: '06:00-12:59'},
        {label: '中午12:00', value: '12:00-12:59'},
        {label: '下午13:00', value: '13:00-13:59'},
        {label: '下午14:00', value: '14:00-14:59'},
        {label: '下午15:00', value: '15:00-15:59'},
        {label: '下午16:00', value: '16:00-16:59'},
        {label: '下午17:00', value: '17:00-17:59'},
        {label: '晚上 18:00-24:00', value: '18:00-23:59'},
        {label: '晚上18:00', value: '18:00-18:59'},
        {label: '晚上19:00', value: '19:00-19:59'},
        {label: '晚上20:00', value: '20:00-20:59'},
        {label: '晚上21:00', value: '21:00-21:59'},
        {label: '晚上22:00', value: '22:00-22:59'},
        {label: '晚上23:00', value: '23:00-23:59'},
      ],
      hourOption1: [
        {label: '全天都可以', value: '00:00-23:59'},
        {label: '凌晨00:00-06:00', value: '00:00-06:59'},
        {label: '凌晨00:00', value: '00:00-00:59'},
        {label: '凌晨01:00', value: '01:00-01:59'},
        {label: '凌晨02:00', value: '02:00-02:59'},
        {label: '凌晨03:00', value: '03:00-03:59'},
        {label: '凌晨04:00', value: '04:00-04:59'},
        {label: '凌晨05:00', value: '05:00-05:59'},
        {label: '上午06:00', value: '06:00-06:59'},
        {label: '上午07:00', value: '07:00-07:59'},
        {label: '上午08:00', value: '08:00-08:59'},
        {label: '上午09:00', value: '09:00-09:59'},
        {label: '上午10:00', value: '10:00-10:59'},
        {label: '上午11:00', value: '11:00-11:59'},
        {label: '上午 06:00-12:00', value: '06:00-12:59'},
        {label: '下午12:00', value: '12:00-12:59'},
        {label: '下午13:00', value: '13:00-13:59'},
        {label: '下午14:00', value: '14:00-14:59'},
        {label: '下午15:00', value: '15:00-15:59'},
        {label: '下午16:00', value: '16:00-16:59'},
        {label: '下午17:00', value: '17:00-17:59'},
        {label: '晚上 18:00-24:00', value: '18:00-23:59'},
        {label: '晚上18:00', value: '18:00-18:59'},
        {label: '晚上19:00', value: '19:00-19:59'},
        {label: '晚上20:00', value: '20:00-20:59'},
        {label: '晚上21:00', value: '21:00-21:59'},
        {label: '晚上22:00', value: '22:00-22:59'},
        {label: '晚上23:00', value: '23:00-23:59'},
      ],
      planLoadHour: '',
      planLoadStatus: true,
      planLoadDate: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
        shortcuts: [{
          text: '今天或明天',
          onClick(picker) {
            console.log(this.orderForm);
            picker.$emit('pick', '0');
          }
        }]
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      // 计划卸货时间
      planArriveHour: '',
      planArriveStatus: true,
      // 操作日志
      logVisible: false,
      logTable: [],
      // 操作确认
      progressChecked: false,
      saveVisible: false,
      // 换车
      cutCarVisible: false,
      cutCarForm: {
        remarks: ''
      },
      cutCarRules: {
        remarks: {required: true, message: '不能为空', trigger: 'blur'},
      },
      // 修改卸货地
      reviseAddressVisible: false,
      arsImgList: [],
      reviseAddressForm: {
        adcode: '',
        addressDetail: '',
        images: [],
        orderId: '',
        remarks: ''
      },
      reviseAddressRules: {
        addressDetail: {required: true, message: '请输入活动名称', trigger: 'blur'},
        images: {required: true, message: '请上传修改凭证', trigger: 'blur'},
      },
      // 上传凭证
      proveVisible: false,
      proveOption: [
        {label: '装车', value: 1},
        {label: '卸货', value: 2},
        {label: '回单', value: 3},
        {label: '收款', value: 4},
        {label: '保险', value: 5},
        {label: '事故', value: 6},
        {label: '罚款', value: 7},
        {label: '其他', value: 8}
      ],
      proveType: '',
      proveList: [],
      uploadList: [],
      recordingVisible: false,
      recordingList: [],
      dialTable: [],
      activeName: 0,
      innerVisible: false,
      innerList: '',
      mapDialogVisible: false,
      positionInfo: {},
      // 新字段
      // 路况名称
      roadConditionOptions: [],
      // 鹅颈造型名称
      tcarGooseneckTypeOptions: [],
      // 车挂造型名称
      tcarHangTypeOptions: [],
      //车挂长度
      tcarLengthOptions: [],
      // 特殊要求
      tcarAccessoryOptions: [],
      // 出价方式
      quoteCodeOptions: [
        {id: 1, name: '每单'},
        {id: 2, name: '每吨'},
        {id: 3, name: '每件'}
      ],
      // 承运证件
      certificatesVisible: false,
      certificatesData: {},
      addressProps: {
        lazy: true,
        lazyLoad(node, resolve) {
          const {level} = node;
          let code = '';
          if (node.value) {
            code = node.value;
          }
          listByParent({parentCode: code}).then(res => {
            const nodes = res.data.map(item => ({
              value: item.code,
              label: item.name,
              leaf: level >= 2
            }));
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes);
          });
        }
      },
      quoteLogVisible: false,
      quoteLogData: [],
      enclosureVisible: false,
      enclosureName: '',
      enclosureList: '',
      loadUnloadRequireOption: [],
      preventionRequireOption: [],
      hourArray: [],
      hourArray0: [{
        name: '全天都可以',
        value: '00:00-23:59',
        checked: 0
      }],
      hourArray1: [
        {
          name: '凌晨 00:00-06:00',
          value: '00:00-05:59',
          checked: 0
        },
        {
          name: '凌晨 00:00',
          value: '00:00-00:59',
          checked: 0
        },
        {
          name: '凌晨 01:00',
          value: '01:00-01:59',
          checked: 0
        },
        {
          name: '凌晨 02:00',
          value: '02:00-02:59',
          checked: 0
        },
        {
          name: '凌晨 03:00',
          value: '03:00-03:59',
          checked: 0
        },
        {
          name: '凌晨 04:00',
          value: '04:00-04:59',
          checked: 0
        },
        {
          name: '凌晨 05:00',
          value: '05:00-05:59',
          checked: 0
        }
      ],
      hourArray2: [
        {
          name: '上午 06:00-12:00',
          value: '06:00-11:59',
          checked: 0
        },
        {
          name: '上午 06:00',
          value: '06:00-06:59',
          checked: 0
        },
        {
          name: '上午 07:00',
          value: '07:00-07:59',
          checked: 0

        },
        {
          name: '上午 08:00',
          value: '08:00-08:59',
          checked: 0
        },
        {
          name: '上午 09:00',
          value: '09:00-09:59',
          checked: 0
        },
        {
          name: '上午 10:00',
          value: '10:00-10:59',
          checked: 0
        },
        {
          name: '上午 11:00',
          value: '11:00-11:59',
          checked: 0
        }
      ],
      hourArray3: [
        {
          name: '下午 12:00-18:00',
          value: '12:00-17:59',
          checked: 0
        },
        {
          name: '下午 12:00',
          value: '12:00-12:59',
          checked: 0
        },
        {
          name: '下午 13:00',
          value: '13:00-13:59',
          checked: 0
        },
        {
          name: '下午 14:00',
          value: '14:00-14:59',
          checked: 0
        },
        {
          name: '下午 15:00',
          value: '15:00-15:59',
          checked: 0
        },
        {
          name: '下午 16:00',
          value: '16:00-16:59',
          checked: 0
        },
        {
          name: '下午 17:00',
          value: '17:00-17:59',
          checked: 0
        }
      ],
      hourArray4: [
        {
          name: '晚上 18:00-24:00',
          value: '18:00-23:59',
          checked: 0
        },
        {
          name: '晚上 18:00',
          value: '18:00-18:59',
          checked: 0
        },
        {
          name: '晚上 19:00',
          value: '19:00-19:59',
          checked: 0
        },
        {
          name: '晚上 20:00',
          value: '20:00-20:59',
          checked: 0
        },
        {
          name: '晚上 21:00',
          value: '21:00-21:59',
          checked: 0
        },
        {
          name: '晚上 22:00',
          value: '22:00-22:59',
          checked: 0
        },
        {
          name: '晚上 23:00',
          value: '23:00-23:59',
          checked: 0
        }
      ],
      remarkList: []
    }
  },
  inject: ['reload'],
  filters: {
    //方法一: yyyy-MM-dd hh:mm
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  computed: {
    pricePerKm() {
      return ((this.orderForm.carPrice * 100) / (this.orderForm.distance / 1000)).toFixed(2)
    }
  },
  watch: {
    '$route'(to, from) {
      this.placeSearch = new AMap.AutoComplete({});
      this.$set(this, 'areaList', area);
      this.id = this.$route.params.id;
      this.allVehicleRequirements();
      if (this.id) {
        this.formType = true;
        // 获取承运信息
        this.getDriverInfo();
        this.orderDetails();
        // 订单详情
      } else {
        this.formType = false;
      }
      ;
      findProvince().then(res => {
        this.provinceOption = res.result
        this.provinceOption1 = res.result
      })
    },
  },
  mounted() {
    this.placeSearch = new AMap.AutoComplete({});
    this.$set(this, 'areaList', area);
    this.id = this.$route.params.id;
    this.allVehicleRequirements();
    if (this.id) {
      this.formType = true;
      // 获取承运信息
      this.getDriverInfo();
      this.orderDetails();
      // 订单详情
    } else {
      this.formType = false;
    }
    getLoadUnloadRequire().then((res) => {
      this.loadUnloadRequireOption = res.result
    });
    getPreventionRequire().then((res) => {
      this.preventionRequireOption = res.result
    });
    findProvince().then(res => {
      this.provinceOption = res.result
      this.provinceOption1 = res.result
    });
  },
  methods: {
    statusFormart(e) {
      let a = ''
      this.orderItemStatusOptions.forEach(item => {
        if (item.value == e) {
          a = item.label;
        }
      })
      return a
    },
    openPhoto() {
      getUploadLog({orderNo: this.orderForm.orderNo}).then(res => {
        this.otherUrlList = res.result;
        this.otherVisible = true;
      });
    },
    hourInit() {
      let timeStampNow = new Date().getTime()
      let timeNow = new Date()
      let hourNow = timeNow.getHours()
      this.hourArray = []
      if (hourNow >= 0 && hourNow <= 5) {
        this.hourArray = this.hourArray.concat(this.hourArray0)
        this.hourArray.push({name: '凌晨 00:00-06:00', value: '00:00-05:59', checked: 0})
        for (let i = hourNow + 1; i <= 6; i++) {
          this.hourArray.push({name: '凌晨 0' + i + ':00', value: '0' + i + ':00-0' + i + ':59', checked: 0})
        }
        this.hourArray = this.hourArray.concat(this.hourArray2, this.hourArray3, this.hourArray4)
      }
      if (hourNow >= 6 && hourNow <= 11) {
        this.hourArray = this.hourArray.concat(this.hourArray0)
        this.hourArray.push({name: '上午 06:00-12:00', value: '06:00-12:00', checked: 0})
        for (let i = hourNow + 1; i <= 12; i++) {
          if (i <= 9) {
            this.hourArray.push({name: '上午 0' + i + ':00', value: '0' + i + ':00-0' + i + ':59', checked: 0})
          } else {
            this.hourArray.push({name: '上午 ' + i + ':00', value: i + ':00-' + i + ':59', checked: 0})
          }
        }
        this.hourArray = this.hourArray.concat(this.hourArray3, this.hourArray4)
      }
      if (hourNow >= 12 && hourNow <= 17) {
        this.hourArray = this.hourArray.concat(this.hourArray0)
        this.hourArray.push({name: '下午 12:00-18:00', value: '12:00-17:59', checked: 0})
        for (let i = hourNow + 1; i <= 17; i++) {
          this.hourArray.push({name: '下午 ' + i + ':00', value: i + ':00-' + i + ':59', checked: 0})
        }
        this.hourArray = this.hourArray.concat(this.hourArray4)
      }
      if (hourNow >= 18 && hourNow <= 23) {
        this.hourArray = this.hourArray.concat(this.hourArray0)
        this.hourArray.push({name: '晚上 18:00-23:59', value: '18:00-23:59'})
        for (let i = hourNow + 1; i <= 24; i++) {
          this.hourArray.push({name: '晚上 ' + i + ':00', value: i + ':00-' + i + ':59', checked: 0})
        }
      }
      for (let i = 0; i <= this.hourArray.length - 1; i++) {
        if (i == 0) {
          this.hourArray[i].checked = 0//默认（不）选中第一个
        } else {
          this.hourArray[i].checked = 0
        }
      }
      return this.hourArray
    },
    photosChange(e, v) {
      switch (v) {
        case 0:
          this.certificatesData.qualificationCertificate = e;
          UploadCertificate({orderNo: this.orderForm.orderNo, qualificationCertificate: e}).then(() => {
            this.$message.success('操作成功');
          })
          break;
        case 1:
          this.certificatesData.roadTransportPermit = e;
          UploadCertificate({orderNo: this.orderForm.orderNo, roadTransportPermit: e}).then(() => {
            this.$message.success('操作成功');
          })
          break;
      }
    },
    payTypeChange(e) {
      // <el-option label="发货方付款" value="SENDER"/>
      // <el-option label="收货方付款" value="RECEIVER"/>
      if (e === 'RECEIVER') {
        this.orderForm.receiptAmount = 0;
        this.orderForm.refundsAmount = 0;
        this.orderForm.needInvoice = 0;
        this.orderForm.taxCost = 0;
        this.orderForm.carPrice = 0;
      } else {
      }
    },
    quoteLogClick() {
      orderHistory(this.orderForm.orderNo).then(res => {
        this.quoteLogData = res.data;
        this.quoteLogVisible = true;
      });
    },
    enclosureClick() {
      selectReceipt({orderNo: this.orderForm.orderNo}).then(res => {
        this.enclosureList = res.result;
        this.enclosureVisible = true;
      });
    },
    quoteChange(e) {
      if (e == 2) {
        this.orderForm.goodsWeight = this.orderForm.num;
      }
    },
    payTypeInput(e) {
      if (this.orderForm.quoteCode == 2) {
        this.orderForm.goodsWeight = e;
      }
      this.orderForm.totalPrice = (e * this.orderForm.price);
      this.actualPrice = this.orderForm.totalPrice - this.orderForm.oilCardAmount;
    },
    weightInput(e) {
      if (this.orderForm.quoteCode == 2) {
        this.orderForm.num = e;
        this.orderForm.totalPrice = (e * this.orderForm.price);
        this.actualPrice = this.orderForm.totalPrice - this.orderForm.oilCardAmount;
      }
    },
    priceInput(e) {
      this.orderForm.totalPrice = (e * this.orderForm.num);
      this.actualPrice = this.orderForm.totalPrice - this.orderForm.oilCardAmount;
    },
    oilCardAmountInput(e) {
      this.actualPrice = this.orderForm.totalPrice - e;
    },
    OilCardChange(e) {
      if (e) {
        this.actualPrice = this.orderForm.totalPrice - e;
      } else {
        this.actualPrice = this.orderForm.totalPrice;
      }
    },
    needInvoiceChange(e) {
      if (e == 1) {
        this.orderForm.taxCost = (this.orderForm.totalPrice * 0.065).toFixed(2);
        this.orderForm.carPrice = this.orderForm.totalPrice - this.orderForm.taxCost;
      } else {
        this.orderForm.taxCost = 0;
        this.orderForm.carPrice = this.orderForm.totalPrice;
      }
    },
    addressChange1(e, i, type) {
      if (type == 'province') {
        this.orderForm.senderAddress.province = e.province;
        findCity({provinceId: e.provinceId}).then(res => {
          this.cityOption1 = res.result
        });
        this.orderForm.senderAddress.city = "";
        this.orderForm.senderAddress.area = "";
      } else if (type == 'city') {
        this.orderForm.senderAddress.city = e.city;
        findArea({cityId: e.cityId}).then(res => {
          this.areaOption1 = res.result
        })
        this.orderForm.senderAddress.area = "";
      } else {
        this.orderForm.senderAddress.adcode = e.areaId;
        this.orderForm.senderAddress.longitude = e.longitude;
        this.orderForm.senderAddress.latitude = e.latitude;
        this.orderForm.senderAddress.area = e.area;
        this.orderForm.senderAddress.userAddressId = e.id;
      }
    },
    addressChange(e, i, type) {
      if (type == 'province') {
        this.orderForm.receiverAddress.province = e.province;
        findCity({provinceId: e.provinceId}).then(res => {
          this.cityOption = res.result
        });
        this.orderForm.receiverAddress.city = "";
        this.orderForm.receiverAddress.area = "";
      } else if (type == 'city') {
        this.orderForm.receiverAddress.city = e.city;
        findArea({cityId: e.cityId}).then(res => {
          this.areaOption = res.result
        });
        this.orderForm.receiverAddress.area = "";
      } else {
        this.orderForm.receiverAddress.adcode = e.areaId;
        this.orderForm.receiverAddress.longitude = e.longitude;
        this.orderForm.receiverAddress.latitude = e.latitude;
        this.orderForm.receiverAddress.area = e.area;
        this.orderForm.receiverAddress.userAddressId = e.id;
      }
    },
    certificatesClick() {
      getDriverCertificateById(this.orderForm.orderNo).then(res => {
        this.certificatesData = res.result;
        this.certificatesVisible = true;
      });
    },
    // 订单详情
    orderDetails() {
      orderInfo(this.id).then(res => {
        this.orderForm = res.result;
        this.driverDetail = res.result.carInfoModel;
        this.orderForm.planArriveTime = Math.round(new Date(this.orderForm.planArriveTime).getTime() * 1000)
        // 货物重量
        this.orderForm.goodsInfo.goodsWeight = this.orderForm.goodsInfo.goodsWeight / 1000;
        // 油卡金额
        this.orderForm.oilCardAmount = this.orderForm.oilCardAmount / 100;
        // 订单总价
        this.orderForm.totalPrice = this.orderForm.totalPrice / 100;
        // 到车价
        this.orderForm.carPrice = this.orderForm.carPrice / 100;
        // 单价
        this.orderForm.price = this.orderForm.price / 100;
        // 税票成本
        this.orderForm.taxCost = this.orderForm.taxCost / 100;
        // 车方定金
        this.orderForm.deposit = this.orderForm.deposit / 100;
        // 回单押金
        this.orderForm.receiptAmount = this.orderForm.receiptAmount / 100;
        // 回单退还金额
        this.orderForm.refundsAmount = this.orderForm.refundsAmount / 100;
        // 代付金额
        this.orderForm.amount = this.orderForm.amount / 100;
        // 代付差价
        this.orderForm.difference = this.orderForm.difference / 100;
        // 长宽高
        this.orderForm.goodsInfo.overrunInfo.width = this.orderForm.goodsInfo.overrunInfo.width / 100;
        this.orderForm.goodsInfo.overrunInfo.high = this.orderForm.goodsInfo.overrunInfo.high / 100;
        this.orderForm.goodsInfo.overrunInfo.length = this.orderForm.goodsInfo.overrunInfo.length / 100;
        // this.orderForm.additionalRequirementList = this.orderForm.additionalRequirementList ? JSON.parse(JSON.stringify(this.orderForm.additionalRequirementList)) : [];
        this.actualPrice = this.orderForm.totalPrice - this.orderForm.oilCardAmount;
        this.orderForm.serviceRequirements.preventionRequireList.length > 0 ? this.orderForm.serviceRequirements.preventionRequireList = this.orderForm.serviceRequirements.preventionRequireList[0] : ''
        if (this.orderForm.goodsInfo.photosUrls.length > 0) {
          this.orderForm.goodsInfo.photosUrls.forEach((item, index) => {
            this.imgList.push({name: index, url: item});
          });
        }
        // 装卸次数
        // this.orderForm.loadTimes = this.orderForm.loadTimes ? parseInt(this.orderForm.loadTimes) : null;
        // 线索渠道
        if (this.orderForm.clueInfo === null) {
          this.orderForm.clueInfo = {
            id: null,
            provider: "",
            providerPhone: "",
            source: ""
          };
        }
        // 凭证列表
        if (this.orderForm.orderItemVo) {
          voucherList(this.orderForm.orderItemVo.orderItemNo).then(res => {
            this.proveList = res.data;
          });
        }
        const d = this.$refs["ID"]
        this.orderForm.goodsInfo.photosUrls.length >= 3 ? d.$children[0].$children[1].$el.style.display = 'none' : '';
        getWaybillLog({orderNo: this.orderForm.orderNo}).then(res => {
          this.log = res.result;
          if (this.log.orderItemLogVos.length == 7) {
            this.log.orderItemLogVos.push(this.log.orderItemLogVos[6])
            this.log.statusIntervalTime.push(this.log.statusIntervalTime[5])
          }
          if (this.orderForm.payerWay == 2 && this.log.length == 7 && this.orderForm.payStatus == '已付款') {
            this.log.push(this.orderForm.orderItemLogVos[6]);
          }
        })
        shipperRemarksList({orderNo: this.orderForm.orderNo}).then(res => {
          this.remarkList = res.result;
        })
      });
    },
    // 获取承运信息
    getDriverInfo() {
      orderInfo1(this.id).then(res => {
        this.moveForm = res.result;
        this.orderId = res.result.id;
        this.driverDetail = res.result.carInfoModel;
        this.orderForm.currentPosition = res.result.currentPosition;
        this.orderForm.driverOtherUrl = res.result.driverOtherUrl;
      })
    },
    // 获取各种车辆要求
    allVehicleRequirements() {
      vehicleRequirements().then(res => {
        // 路况名称
        this.roadConditionOptions = res.result.roadConditionList;
        // 鹅颈造型名称
        this.tcarGooseneckTypeOptions = res.result.tcarGooseneckTypeList;
        // 车挂造型名称
        // this.tcarHangTypeOptions = res.data.result.carAxleTypeList;
        //车挂长度
        this.tcarLengthOptions = res.result.tcarLengthList;
        this.tcarAccessoryOptions = res.result.tcarAccessoryList;
      });
      getCarType().then(res => {
        this.tcarHangTypeOptions = res.result;
      })
    },
    handleClick(e) {
      let a = this.recordingList[this.activeName];
      this.dialTable = [];
      this.dialTable.push({
        status: '连接中' + this.activeName,
        date: a.callTime,
        phone: a.sendPhone,
        name: a.sendName,
        record: ''
      });
      this.dialTable.push({
        status: a.recordUrl ? '通话结束' : '通话失败',
        date: a.hangTime,
        phone: a.sendPhone,
        name: a.sendName,
        record: a.recordUrl
      });

    },
    // 派单
    dispatch() {
      this.$router.push('/dispatchOrder/' + this.id);
    },
    // 上传凭证
    uploadProve() {
      this.uploadList = this.proveList;
      this.proveVisible = true;
    },
    clsProve() {
      this.uploadList = [];
      this.proveVisible = false;
    },
    subProve() {
      updateVouchers(this.orderForm.orderItemVo.orderItemNo, this.uploadList).then(() => {
        this.clsProve();
      });
    },
    proveForMat(e) {
      let a = '';
      this.proveOption.forEach(item => {
        if (item.value === e) {
          a = item.label;
        }
      });
      return a;
    },
    delImg(e) {
      this.uploadList.splice(e, 1);
    },
    // 添加图片事件
    selectFile(e) {
      uploadFile(e.file, (key) => {
        this.uploadList.push({image: key, type: this.proveType});
      })
    },
    // 上传主题方法
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 修改卸货地
    upAddress() {
      this.reviseAddressVisible = true;
      this.reviseAddressForm.orderId = this.id;
    },
    // 关闭修改卸货地弹框
    closeArs() {
      this.$refs['reviseAddressForm'].resetFields();
      this.reviseAddressVisible = false;
      this.reviseAddressForm = {
        adcode: '',
        addressDetail: '',
        images: [],
        orderId: '',
        remarks: ''
      };
    },
    // 提交卸货地
    subArs() {
      this.$refs['reviseAddressForm'].validate((valid) => {
        if (valid) {
          upAddress(this.reviseAddressForm).then(res => {
            this.$message.success('修改成功');
            this.closeArs();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 地址回调
    arsSelect(e) {
      console.log(e);
      this.reviseAddressForm.adcode = e.adcode
      this.reviseAddressForm.addressDetail = e.address + e.name;
    },
    // 图片回调
    arsChange(e) {
      this.reviseAddressForm.images.push(e);
    },
    // 删除图片回调
    arsrmImg(e) {
      let index = this.arsImgList.findIndex(i => {
        return i == e;
      })
      this.reviseAddressForm.images.splice(index, 1);
    },
    // 换车提交
    subCutCar() {
      this.$refs['cutCarForm'].validate((valid) => {
        if (valid) {
          beginTransfer({orderItemId: this.orderForm.orderItemVo.id, remarks: this.cutCarForm.remarks}).then(res => {
            this.$router.push('/dispatchOrder/' + this.id);
          });
        }
      })
    },
    // 重置表单
    clsCutCar() {
      this.cutCarForm.remarks = '';
      this.cutCarVisible = false;
    },
    Refresh() {
      this.reload();
    },
    back() {
      this.$router.go(-1);//返回上一层
    },
    // 远程匹配货物名称
    querySearchAsync(queryString, cb) {
      getGoodsName({name: queryString, currentPage: 1, pageSize: 9999}).then(res => {
        let list = res.data;
        res.data.forEach((item, index) => {
          list[index].value = item.goodName;
        })
        cb(list);
      })
    },
    goodNameSel(e) {
      // 赋值货物名称id
      this.orderForm.goodNameId = e.id;
    },
    // 通过正则过滤小数点后两位
    handleInput(e) {
      e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null;
    },
    // 地址回调
    addressAsync(queryString, cb) {
      this.placeSearch.search(queryString, (status, result) => {
        // 查询成功时，result即对应匹配的POI信息
        if (result && result.tips) {
          let restaurants = [];
          result.tips.forEach(item => {
            item.value = item.name;
            restaurants.push(item);
          });
          cb(restaurants);
        }
      });
    },
    addressSelect(e, t) {
      if (t === 1) {
        this.orderForm.senderAddress.addressDetail = e.value;
      } else {
        this.orderForm.receiverAddress.addressDetail = e.value;
      }
    },
    // 计划装/卸货时间
    planTimeChange(e) {
      if (e == this.dateFormat(0)) {
        this.hourOption = this.hourInit()
      } else {
        this.hourOption = this.hourOption1
      }
      if (e == null) {
        this.planLoadDate = '';
        this.form.planLoadTime = '';
        this.form.planLoadDate = '';
      } else if (e == 0) {
        this.orderForm.planLoadDate = '今天或明天';
        this.planLoadDate = '';
        this.orderForm.planLoadTime = '全天都可以';
        this.planLoadStatus = true;
      } else {
        this.orderForm.planLoadDate = e
        this.orderForm.planLoadTime = '00:00-23:59';
        this.planLoadStatus = false;
      }
    },
    editOrder() {
      selectOrderIsLock(this.id).then(res => {
        if (res.result.isLock == 0) {
          selectUpdateOrder(this.id).then(res => {
            this.formType = false
          })
        } else {
          this.$message.warning('司机正在抢单,暂不支持修改')
        }
      })
    },
    saveForm() {
      let params = JSON.parse(JSON.stringify(this.orderForm));
      params.planLoadDate == '今天或明天' ? params.planLoadDateBef = this.dateFormat(0) : "";
      params.planLoadDate == '今天或明天' ? params.planLoadDate = this.dateFormat(1) : "";
      params.planLoadTime == '全天都可以' ? params.planLoadTimeBef = "00:00-23:59" : "";
      params.planLoadTime == '全天都可以' ? params.planLoadTime = "00:00-23:59" : "";
      const _PlanStartTime = new Date(params.planLoadDate)
      const _PlanLoadDateBef = new Date(params.planLoadDateBef)
      const _PlanEndTime = new Date(params.planArriveTime)
      if (_PlanEndTime.getTime() !== 0 && _PlanEndTime.getTime() < new Date().getTime()) {
        this.$message.warning("希望送达时间不能小于当前时间!")
        return;
      }
      if (_PlanEndTime.getTime() !== 0 && (_PlanEndTime.getTime() < _PlanStartTime.getTime() || _PlanEndTime.getTime() < _PlanLoadDateBef.getTime())) {
        this.$message.warning("装货时间不能大于希望到达时间!")
        return;
      }

      // 货物重量

      params.goodsInfo.goodsWeight = params.goodsInfo.goodsWeight * 1000,
          // 油卡金额
          params.oilCardAmount = params.oilCardAmount * 100;
      // 订单总价
      params.totalPrice = params.totalPrice * 100;
      // 到车价
      params.carPrice = params.carPrice * 100;
      // 单价
      params.price = params.price * 100;
      // 税票成本
      params.taxCost = params.taxCost * 100;
      // 车方定金
      params.deposit = params.deposit * 100;
      // 回单押金
      params.receiptAmount = params.receiptAmount * 100;
      // 回单退还金额
      params.refundsAmount = params.refundsAmount * 100;
      // 代付金额
      params.amount = params.amount * 100;
      // 代付差价
      params.difference = params.difference * 100;
      // 长宽高
      params.goodsInfo.overrunInfo.width = params.goodsInfo.overrunInfo.width * 100;
      params.goodsInfo.overrunInfo.high = params.goodsInfo.overrunInfo.high * 100;
      params.goodsInfo.overrunInfo.length = params.goodsInfo.overrunInfo.length * 100;
      params.photosUrl = JSON.stringify(params.photos);
      params.planArriveTime = Math.round(new Date(params.planArriveTime).getTime() / 1000)
      params.goodsInfo.highestOffer = (+params.price) * (+params.num);
      params.goodsInfo.highestOffer == 0 ? params.isPrice = 0 : params.isPrice = 1;
      params.haveInvoice == 0 ? params.invoiceSource = '' : '';  //车方定金-
      if (Array.isArray(params.serviceRequirements.preventionRequireList)) {
      } else {
        params.serviceRequirements.preventionRequireList = [params.serviceRequirements.preventionRequireList]
      }
      updateOrder(params).then(res => {
        this.$message.success('保存成功');
        this.formType = true;
        this.getDriverInfo();
      })
    },
    dateFormat(num) {
      let date = new Date();
      let y = date.getFullYear();
      let M = date.getMonth() + 1;
      let d = date.getDate() + num;
      return y + '-' + M + '-' + d
    },
    photoChange(e) {
      this.orderForm.goodsInfo.photosUrls.push(e);
      const d = this.$refs["ID"]
      this.orderForm.goodsInfo.photosUrls.length >= 3 ? d.$children[0].$children[1].$el.style.display = 'none' : ''
    },
    removeImg(e) {
      let index = this.imgList.findIndex(i => {
        return i == e;
      })
      this.orderForm.goodsInfo.photosUrls.splice(index, 1);
      const d = this.$refs["ID"]
      this.orderForm.goodsInfo.photosUrls.length < 3 ? d.$children[0].$children[1].$el.style.display = '' : ''
    },
    //计划卸车时间
    planArriveTime(item) {
      let date = new Date(parseInt(item) * 1000);  //如果是10位，这里需要*1000，如果是13位则不需要*1000
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + m + '-' + d
    },
    selectedChange(val) {
      val.forEach(item => {
        this.orderForm.tpreventionRequireEntity.forEach(i => {
          if (item.id == 1 && (i.id == 2 || i.id == 3)) {
            this.orderForm.tpreventionRequireEntity.pop()
          } else if (item.id == 2 && (i.id == 1 || i.id == 3)) {
            this.orderForm.tpreventionRequireEntity.pop()
          } else if (item.id == 3 && (i.id == 1 || i.id == 2)) {
            this.orderForm.tpreventionRequireEntity.pop()
          }
        })
      });
    },
    // 已承运查看轨迹
    showMap() {
      getWaybillLog({orderNo: this.orderForm.orderNo}).then(res => {
        let orderItemLog = res.result;
        let code = orderItemLog.length;
        switch (code) {
          case 1:
            this.orderType = '已派车';
            break;
          case 2:
            this.orderType = '待装车';
            break;
          case 3:
            this.orderType = '装车中';
            break;
          case 4:
            this.orderType = '已装车';
            break;
          case 5:
            this.orderType = '运输中';
            break;
          case 6:
            this.orderType = '卸货中';
            break;
          case 7:
            this.orderType = '已完成';
            break;
          default:
            this.orderType = '暂无';
            break;
        }
        let now = this.$moment().format("YYYY-MM-DD HH:mm:ss");
        let params = {
          ids: orderItemLog[0].driverId,
          // ids: '1692129830709100544',
          // ids: '1692527259207991296',
          vclNs: this.driverDetail.plateNumber,
          start: orderItemLog[0].createTime,
          // start: "2022-05-06 22:41:00",
          end: orderItemLog[code - 1].status == 8 ? orderItemLog[code - 1].createTime : now,
          withStops: true
        };
        vehiclesTracks(params).then(res => {
          if (res.data.trackPoints.length > 0 || res.data.phoneTrackPoints.length > 0) {
            this.$set(this, 'trackLine', res.data.trackPoints);
            this.$set(this, 'phoneLine', res.data.phoneTrackPoints);
            this.$set(this, 'stopSpotsArr', res.data.stopPoints);
            let params = {};
            if (res.data.trackPoints.length > 0) {
              let tracks = res.data.trackPoints[0].tracks;
              if (tracks.length > 0) {
                params = {
                  latitude: tracks[tracks.length - 1][1],
                  longitude: tracks[tracks.length - 1][0],
                  positionTime: res.data.trackPoints[0].track_times[res.data.trackPoints[0].track_times.length - 1],
                };
              }
            }
            if (res.data.phoneTrackPoints.length > 0) {
              let tracks = res.data.phoneTrackPoints[0].tracks;
              if (tracks.length > 0) {
                params = {
                  latitude: tracks[tracks.length - 1][1],
                  longitude: tracks[tracks.length - 1][0],
                  positionTime: res.data.phoneTrackPoints[0].track_times[res.data.phoneTrackPoints[0].track_times.length - 1],
                };
              }
            }
            // this.mapDialogVisible = true;
            order2Location(this.id).then(e => {
              this.positionInfo = e.result;
              console.log(this.trackLine)
              console.log(this.phoneLine)
              this.mapDialogVisible = true;
              /*if (res.result.currentLocation) {
                this.positionInfo = res.result;
                this.mapDialogVisible = true;
                /!*carLocation('/' + this.id + '/location').then(res => {
                  if (res.result.currentLocation) {
                    this.positionInfo = res.result
                  } else {
                    this.$message.warning('暂未找到车辆实时位置');
                  }
                })*!/
              } else {
                this.$message({message: '暂无该车辆信息', type: 'warning'});
              }*/
              //}
            })
          }
        })
      })
    },
    //分享车辆位置
    carLocationLink() {
      let that = this
      let a = JSON.parse(localStorage.getItem('user'))
      carLocationLink({
        orderNo: that.orderForm.orderNo,
        orderId: that.orderId,
        type: 1,
        idendtity: 0
      }).then(res => {
        if (res.code == 0) {
          let input = document.createElement("input");
          input.value = res.result.shareUrl;
          document.body.appendChild(input);
          input.select();
          document.execCommand("Copy");
          document.body.removeChild(input);
          this.$message({
            message: '链接生成成功，已复制',
            type: 'success'
          });
        } else {
          that.$message({
            message: res.message,
            type: 'error'
          })
        }
      })
    },
  }
}
</script>

<style lang="less">
.container {
  .title-group {
    padding: 20px 30px;
    text-align: right;
    background: #ffffff;
    margin: 0 !important;

    .title {
      padding-left: 10px;
      border-left: 4px solid #3459FE;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 8px;
      text-align: left;
    }

    .search-form-inline {
      display: inline;

      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}

.orderForm {
  .uploads .el-upload--picture-card {
    height: 10vw;
    line-height: 200px;
  }

  .el-dialog--center .el-dialog__body {
    height: 96%;
  }

  .map-div {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.06);
    height: 500px;

    #container {
      height: 45vw;
    }
  }

  .container {
    margin: 10px 20px;

    .form {
      margin-top: 1px;
      padding: 20px 30px;
      margin-bottom: 20px;
      background: #ffffff;
      border-bottom: 1px rgba(0, 0, 0, 0.06);

      .el-row {
        background: #F5F5F5;
        border: 1px solid rgba(0, 0, 0, 0.06);

        &:nth-child(2n) {
          border-top: 0;
          border-bottom: 0;
        }

        .el-col-12 {
          .el-form-item {
            margin: 0;
            background: #F5F5F5;

            .el-form-item__label {
              text-align: center;
              background: #F5F5F5;
              font-weight: 600;
              color: #000000;
              padding: 0;
              margin: 0;
            }

            .el-form-item__content {
              border-bottom: 0;
              background: #FFFFFF;
              margin-left: 2em;

              .el-autocomplete {
                width: 100%;
              }

              .el-input__inner {
                border: 0;
                box-shadow: none;
                background: #ffffff;
                //text-indent: 2em;
                width: 100%;
                height: 2em;
              }

              .el-form-item__error {
                top: 32%;
                right: 2%;
                text-align: right;
                left: initial;
              }

              .el-date-editor {
                width: 100%;
                margin: 0;
                display: block;

                .el-range-separator {
                  display: inline;
                }
              }

              .el-radio-group {
                //padding-left: 2em;
              }

              .el-select {
                width: 100%;
              }

              .el-rate {
                margin-top: 2.2%;
                //padding-left: 2em;
              }
            }
          }
        }
      }
    }

    .form2 {
      margin-top: 1px;
      padding: 20px 30px;
      margin-bottom: 20px;
      background: #ffffff;

      .el-row {
        .el-col {
          border: 1px solid rgba(0, 0, 0, 0.06);

          &:nth-child(2n) {
            border-right: 0;
          }

          .el-form-item {
            margin-bottom: 0;

            .el-form-item__label {
              text-align: center;
              display: block;
              margin: 0;
              font-weight: 600;
              padding: 0;
              background: #F5F5F5;
            }
          }

          .el-form-item__content {
            .el-input__inner {
              border: 0;
              box-shadow: none;
              background: #ffffff !important;
            }
          }
        }
      }
    }

    .form3 {
      margin-bottom: 10px;
    }

    .form4 {
      .el-row {
        border: 0;
      }
    }

    .form-tab {
      padding: 0 30px 20px 30px;
      margin-bottom: 20px;
      background: #ffffff;

      .fold-btn {
        border-top: 2px solid #F5F5F5;
        padding-top: 20px;
        text-align: center;
      }

      .el-row {
        .el-col {
          background: #F5F5F5;
          height: 40px;

          p {
            text-align: center;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
            border-bottom: .5px solid #efefef;
            border-right: 1px solid #efefef;
          }

          .el-form-item {
            margin-bottom: 0;
            height: 100%;
            padding: 0;

            .el-form-item__content {
              height: 100%;

              .el-input {
                height: 100%;

                .el-input__inner {
                  height: 100%;
                  border: 0;
                  padding: 0;
                  background: #ffffff;
                  box-shadow: none;
                  border-right: 1px solid #F5F5F5;
                  width: 100%;
                  text-indent: 2em;
                }
              }
            }
          }
        }
      }
    }

    .times-tab {
      background: #ffffff;
      padding: 20px 30px;
      text-align: center;

      .el-col {
        line-height: 40px;
        border: 1px solid #cbcbcb;

        p {
          background: #cbcbcb;
        }
      }
    }

    .process-line {
      background: #ffffff;
      padding: 20px 30px;
      text-align: center;
      font-size: 14px;

      .active {
        color: #1890FF;
      }

      span {
        margin-left: 2em;
        font-weight: bold;
        font-size: 16px;
        line-height: 40px;
      }
    }

  }

  .upload-voucher {
    background: #ffffff;
    padding: 20px 30px;
    text-align: left;

    .block {
      width: 100px;
      position: relative;
      display: inline-table;
      margin-right: 10px;

      &:hover {
        .active {
          display: block;
        }
      }

      .active {
        top: 0;
        left: 0;
        right: 0;
        width: 100px;
        z-index: 999;
        height: 100px;
        display: none;
        position: absolute;
        padding-top: 20px;
        text-align: center;
        background: rgba(0, 0, 0, .6);

        p {
          color: #ffffff;
          cursor: pointer;
          margin-top: 8px;
        }
      }

      .add-d {
        width: 100px;
        height: 100px;
        font-size: 20px;
        cursor: pointer;
        line-height: 100px;
        text-align: center;
        border: 1px solid #cbcbcb;
        display: table-caption;
      }

      p {
        text-align: center;
      }
    }
  }
}

//.upload-list.disabled {
//  ::v-deep .el-upload--picture-card {
//    display: none;
//  }
//}
::v-deep .el-upload .el-upload--picture-card {
  display: none;
}
</style>
