<template>
  <div class="uploads">
    <el-upload action="/" :http-request="selectFile" list-type="picture-card" :on-remove="imgRemove"
               :before-remove="beforeRemove" :show-file-list="false" ref="uploadFile" :disabled="oploadDisabled">
      <div v-if="imgUrl" class="images" v-viewer.rebuild="{inline: true,navbar: false,button: false,title:false}">
        <img :src="imgUrl"/>
      </div>
      <!--
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      -->
      <div v-if="videoUrl" @click.stop='noneClick'>
        <div class="images" v-viewer.rebuild="{inline: true,navbar: false,button: false,title:false}">
          <video :src="videoUrl" controls='controls'></video>
        </div>
      </div>
      <i v-if='!imgUrl && !videoUrl' class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <el-image-viewer :src="imgUrl" class="avatar" v-if="showViewer && imgUrl" :on-close="closeViewer"
                     :url-list="[imgUrl]"/>
    <el-link v-if="imgUrl" type="primary" @click="imgHandelReplace" :underline="false">重新上传</el-link>
    <el-link v-if="videoUrl" type="primary" @click="videoHandelReplace" :underline="false">重新上传</el-link>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import {directive as viewer} from "v-viewer"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import uploadFile from "@/utils/uploadFile";

export default {
  name: "uploads",
  components: {
    ElImageViewer
  },
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    videoUrl: {
      type: String,
      default: '',
    }
  },
  /*props: {
    videoUrl:{
      type: String,
      default: '',
    }
  },*/
  watch: {
    imgUrl: {
      //立即监听
      immediate: true,
      //监听到carouselList状态发生变化了
      handler(old, news) {
        //监听到数据后的下一步操作
        if (old == '' || old == null) {
          this.oploadDisabled = false;
        } else {
          this.oploadDisabled = true
        }
        if (news) {
          this.imglist = [news];
        } else {
          this.imglist = [old];
        }
      }
    },
    videoUrl: {
      //立即监听
      immediate: true,
      //监听到carouselList状态发生变化了
      handler(old, news) {
        //监听到数据后的下一步操作
        // if (old == '' || old == null) {
        //   this.oploadDisabled = false;
        // } else {
        //   this.oploadDisabled = true
        // }
        if (news) {
          this.videolist = [news];
        } else {
          this.videolist = [old];
        }
      }
    },
  },
  data() {
    return {
      photoDialogVisible: false,
      oploadDisabled: false,
      rotateVal: 0,
      showViewer: false,
      imglist: [],
      options: {
        "inline": true,
        "button": true,
        "navbar": false,
        "title": true,
        "toolbar": true,
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "url": "data-source"
      }
    }
  },
  mounted() {
  },
  methods: {
    noneClick() {
      return false
    },
    videoHandelReplace() {
      // 重新上传
      if (this.$refs.uploadFile) {
        this.$refs.uploadFile.clearFiles();
        this.$refs.uploadFile.$children[0].$refs.input.click();
        this.oploadDisabled = true;
      }
    },
    imgHandelReplace() {
      // 重新上传
      if (this.$refs.uploadFile) {
        this.$refs.uploadFile.clearFiles();
        this.$refs.uploadFile.$children[0].$refs.input.click();
        this.oploadDisabled = true;
      }
    },
    onPreview() {
      this.showViewer = true
    },
    closeViewer() {
      this.showViewer = false
    },
    loadimg() {
    },
    // 删除图片
    imgRemove(file) {
      let index = this.imageMessageParam.findIndex(i => {
        return i.name == file.name;
      })
      this.imageMessageParam.splice(index, 1)
    },
    // 删除确认
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 上传主题方法
    beforeAvatarUpload(file, type) {
    },
    // 添加图片事件
    selectFile(e) {
      uploadFile(e.file, (key) => {
        this.$emit('photoChange', key)
      })
    },
  }
}
</script>

<style lang="less">
.viewer-container {
  //width: 500px;
  //height: 400px;
}
video {
  height: 25vw;
}

.uploads {
  margin-bottom: 20px;
  text-align: center;

  .el-upload--picture-card {
    //width: 300px;
    width: 100%;
    height: 25vw;
    line-height: 200px;
    overflow: hidden;

    .avatar {
      width: 100%;
      height: auto;
    }
  }

  .el-link {
    width: 22.5%;
    text-align: center;
  }

  .img-dialog {
    position: absolute;
    padding: 10px 10px;
    background: #f2f6fc;
    top: 10%;
    left: calc(50% - 250px);
    z-index: 99;

    img {
      width: 500px;
    }
  }
}
</style>
