// An highlighted block
import COS from 'cos-js-sdk-v5'
// import { getSecret } from '@/api/upload'

export default function upload(file, callback) {
    // if (typeof config.isPublic === 'undefined') {
    //   config.isPublic = true
    // }
    /*  getSecret({
        type: config.type,
        path: config.path,
        isPublic: config.isPublic ? config.isPublic : false
      }).
      then(response => {
        const data = response.data
        // const cos = new COS({
        //   getAuthorization: function(options, callback) {
        //     callback({
        //       TmpSecretId: data.tmpSecretId,
        //       TmpSecretKey: data.tmpSecretKey,
        //       XCosSecurityToken: data.sessionToken,
        //       ExpiredTime: data.expiredTime
        //     })
        //   }
        // })

        return {
          cos: cos,
          dir: data.url,
          bucket: data.bucket,
          region: data.region
        }
      }).then(data => {
        const cos = data.cos
        const dir = data.dir
        const bucket = data.bucket
        const region = data.region
        */
    const cos = new COS({
        SecretId: 'AKIDXrujX8LEo8B3f6u9qGOG2Yv0tkPnI6tR',
        SecretKey: 'TindD7QSJtTnrgKmDUYLsFn7FFyXt0nt',
    });
    const bucket = 'android-driver-1302681310';
    const region = 'ap-beijing';
    let month = new Date().getMonth();
    // let month = '';
    const dir = 'web/' + (month + 1);
    let name0 = '.' + file.name.split('.')[1]
    let a = new Date().getTime() + name0
    const url = cos.getObjectUrl({
        Bucket: bucket,
        Region: region,
        Key: `${dir}/${a}`,
        Sign: false
    }, function (data) {
        cos.putObject({
            Bucket: bucket,
            Region: region,
            Key: `${dir}/${a}`,
            Body: file
        }, function (data) {
            callback(url)
            // if (config.isPublic) {
            //   callback(url.replace(/^http(s)?:\/\/(.*?)\//, 'https://www.baidu.com/'))
            // } else {
            //   callback(url)
            // }
        })
    })
    // })
}

