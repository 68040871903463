<template>
  <div class="orderList">
    <div class="container">
      <div class="search">
        <el-descriptions class="margin-top" title="筛选列表">
          <template slot="extra">
            <el-button type="primary" @click="searchClick" size="mini">查 询</el-button>
            <el-button type="primary" @click="resetClick" size="mini">重 置</el-button>
          </template>
        </el-descriptions>
        <el-form :inline="true" :model="searchForm" size="mini">
          <el-form-item label="订单编号">
            <el-input v-model="searchForm.orderNo" placeholder="请输入内容" clearable/>
          </el-form-item>
          <el-form-item label="订单联系人">
            <el-input v-model="searchForm.lxrxm" placeholder="请输入内容" clearable/>
          </el-form-item>
          <el-form-item label="订单联系人手机号">
            <el-input v-model="searchForm.lxrsjh" placeholder="请输入内容" clearable/>
          </el-form-item>
          <el-form-item label="发货人">
            <el-input v-model="searchForm.fhr" placeholder="请输入内容" clearable/>
          </el-form-item>
          <el-form-item label="发货人手机号">
            <el-input v-model="searchForm.fhrsjh" placeholder="请输入内容" clearable/>
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select v-model="searchForm.status" placeholder="请选择" size="small">
              <el-option label="不限" value=""></el-option>
              <el-option label="未承运" value="1"></el-option>
              <el-option label="运输中" value="3"></el-option>
              <el-option label="已取消" value="2"></el-option>
              <el-option label="已完成" value="4"></el-option>
              <el-option label="已失效" value="-4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发货城市">
            <el-input v-model="searchForm.originCity" placeholder="请输入内容" clearable/>
          </el-form-item>
          <el-form-item label="收货城市">
            <el-input v-model="searchForm.destinationCity" placeholder="请输入内容" clearable/>
          </el-form-item>
          <el-form-item label="货物名称">
            <el-input v-model="searchForm.hwmc" placeholder="请输入内容" clearable/>
          </el-form-item>
          <el-form-item label="是否需要发票">
            <el-select v-model="searchForm.sfxyfp" placeholder="请选择">
              <el-option label="否" :value="0"/>
              <el-option label="是" :value="1"/>
            </el-select>
          </el-form-item>
          <el-form-item label="订单生成时间">
            <el-date-picker v-model="timer" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd" clearable/>
          </el-form-item>
          <el-form-item label="车牌号">
            <el-input v-model="searchForm.vlcn" placeholder="请输入内容" clearable/>
          </el-form-item>
        </el-form>
      </div>
      <div class="table">
        <el-row>
          <el-col :span="12">
            <el-descriptions class="margin-top" title="订单列表"/>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-popover placement="right" width="500" trigger="click">
              <div class="export-check">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox v-for="(item,index) in exportList" :key="index" :label="item">
                    {{ item.label }}
                  </el-checkbox>
                </el-checkbox-group>
                <div class="btn-dm">
                  <el-button size="small" type="primary" @click="exportClick">确定</el-button>
                </div>
              </div>
              <el-button slot="reference" size="medium" :disabled="checkData.length ==0">导出</el-button>
            </el-popover>
            <div class="">导出功能选择订单后，可使用</div>
          </el-col>
        </el-row>
        <el-table :data="batchData" style="width: 100%" height="500" center :border="true" :row-style="{height:'10px'}"
                  :cell-style="{ textAlign: 'center' }" :header-cell-style="{ background: '#F5F5F5', color: '#000000'}"
                  @selection-change="selectionChange">
          <el-table-column type="selection" width="55"/>
          <el-table-column prop="createTime" label="订单生成时间" width="180" align="center"/>
          <el-table-column prop="orderNo" label="订单编号" width="250" align="center"/>
          <el-table-column prop="vlcn" label="车牌号" width="180" align="center"/>
          <el-table-column prop="lxrxm" label="联系人姓名" width="180" align="center"/>
          <el-table-column prop="lxrsjh" label="联系人手机号" width="180" align="center"/>
          <el-table-column prop="originCity" label="发货城市" width="180" align="center"/>
          <el-table-column prop="destinationCity" label="收货城市" width="180" align="center"/>
          <el-table-column prop="hwmc" label="货物名称" width="180" align="center"/>
          <el-table-column prop="sfxyfp" label="是否需要发票" width="180" align="center">
            <template slot-scope="scope">
              {{ scope.row.sfxyfp == 0 ? '否' : '是' }}
            </template>
          </el-table-column>
          <el-table-column prop="originAddr" label="发货地址" width="180" align="center"/>
          <!--          <el-table-column prop="fhr" label="发货人" width="180" align="center"/>-->
          <!--          <el-table-column prop="fhrsjh" label="发货人手机号" width="180" align="center"/>-->
          <el-table-column prop="destinationAddr" label="收货地址" width="180" align="center"/>
          <!--          <el-table-column prop="shr" label="收货人" width="180" align="center"/>-->
          <!--          <el-table-column prop="shrsjh" label="收货人手机号" width="180" align="center"/>-->
          <el-table-column prop="status" label="承运状态" width="180" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.overdue==1">已失效</p>
              <p v-if="scope.row.overdue!=1&&(scope.row.status==0||scope.row.status==10)">未承运</p>
              <p v-if="scope.row.overdue!=1&&(scope.row.status==1||scope.row.status==2||scope.row.status==3||scope.row.status==4||scope.row.status==5)">
                运输中</p>
              <p v-if="scope.row.overdue!=1&&scope.row.status==127">已取消</p>
              <p v-if="scope.row.overdue!=1&&scope.row.status==6">已完成</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="$router.push('/details/'+scope.row.orderNo)">查看订单</el-button>
              <el-button type="text" @click="comment(scope.row)">查看评论</el-button>
              <el-button type="text" v-if="scope.row.overdue!=1&&scope.row.status!=6&&scope.row.status!=127"
                         @click="stopBtn(scope.row)">终止
              </el-button>
              <el-button style="padding-left: 10px" v-if="scope.row.sfxyfp==1" type="text"
                         @click="billingClick(scope.row)">开票信息补全
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-if="total>0" :total="total" :page.sync="searchForm.currentPage" :limit.sync="searchForm.pageSize"
                    @pagination="paginationChange"/>
      </div>
    </div>
    <el-dialog title="终止订单" :visible.sync="stopDialogVisible" width="30%" center>
      <span>订单取消后不可恢复,是否要取消?</span>
      <span slot='footer'>
        <el-button @click="cancelStopRemarks" size='small'>取消</el-button>
        <el-button @click="cancelOrderList" type='primary' size='small'>确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="开票信息补充" :visible.sync="billingVisible" width="800px" center>
      <el-form ref="form" class="billingForm" :rules="rules" :model="billingForm" label-width="120px" :inline="true">
        <h3>发货信息</h3>
        <el-form-item label="发货姓名" prop="senderAddress.username">
          <el-input v-model="billingForm.senderAddress.username"/>
        </el-form-item>
        <el-form-item label="发货地址" prop="senderAddress.province">
          <el-col :span="18">
            <el-select v-model="billingForm.senderAddress.province" placeholder="省" style="width:30%"
                       value-key="provinceId" @change="addressChange1($event,1,'province')">
              <el-option v-for="item in provinceOption1" :key="item.provinceId" :label="item.province"
                         :value="item">
              </el-option>
            </el-select>
            <el-select v-model="billingForm.senderAddress.city" placeholder="市" value-key="cityId"
                       style="width:35%"
                       @change="addressChange1($event,1,'city')">
              <el-option v-for="item in cityOption1" :key="item.cityId" :label="item.city" :value="item">
              </el-option>
            </el-select>
            <el-select v-model="billingForm.senderAddress.area" placeholder="区/县" style="width:35%"
                       value-key="areaId"
                       @change="addressChange1($event,1,'area')">
              <el-option v-for="item in areaOption1" :key="item.areaId" :label="item.area" :value="item">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="发货详细地址" prop="senderAddress.addressDetail">
          <el-input v-model="billingForm.senderAddress.addressDetail"/>
        </el-form-item>
        <h3>收货信息</h3>
        <el-form-item label="收货姓名" prop="receiverAddress.username">
          <el-input v-model="billingForm.receiverAddress.username"/>
        </el-form-item>
        <el-form-item label="收货地址" prop="receiverAddress.province">
          <el-col :span="18">
            <el-select v-model="billingForm.receiverAddress.province" placeholder="省" style="width:30%"
                       value-key="provinceId" @change="addressChange($event,0,'province')">
              <el-option v-for="item in provinceOption" :key="item.provinceId" :label="item.province"
                         :value="item">
              </el-option>
            </el-select>
            <el-select v-model="billingForm.receiverAddress.city" placeholder="市" value-key="cityId"
                       style="width:35%" @change="addressChange($event,0,'city')">
              <el-option v-for="item in cityOption" :key="item.cityId" :label="item.city" :value="item">
              </el-option>
            </el-select>
            <el-select v-model="billingForm.receiverAddress.area" placeholder="区/县" style="width:35%"
                       value-key="areaId" @change="addressChange($event,0,'area')">
              <el-option v-for="item in areaOption" :key="item.areaId" :label="item.area" :value="item">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="收货详细地址" prop="receiverAddress.addressDetail">
          <el-input v-model="billingForm.receiverAddress.addressDetail"/>
        </el-form-item>
        <h3><span style="color: red">*</span> 法人身份证</h3>
        <div>
          <el-row :gutter="10">
            <el-col :span="12">
              <uploads :imgUrl="billingForm.idCardPositiveUrl" style="height: 100px"
                       v-on:photoChange="photoChange($event,1)"/>
            </el-col>
            <el-col :span="12">
              <uploads :imgUrl="billingForm.idCardNegativeUrl" v-on:photoChange="photoChange($event,2)"/>
            </el-col>
          </el-row>
        </div>
        <h3><span style="color: red">*</span> 企业营业执照上传</h3>
        <div>
          <el-row :gutter="10">
            <el-col :span="12">
              <uploads :imgUrl="billingForm.businessLicense" v-on:photoChange="photoChange($event,3)"/>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="billingVisible = false">取 消</el-button>
        <el-button type="primary" @click="billingSave">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination';
import {exportJsonToExcel} from "@/vender/Export2Excel";
import uploads from '@/components/uploads/index';
import area from '@/utils/area.json';
import {
  batchList,
  getOrderInvoiceInfo,
  insertOrderInvoice,
  cancelOrder,
  findCity,
  findArea,
  findProvince,
} from "@/request/api";

export default {
  name: "orderList",
  components: {pagination, uploads},
  data() {
    return {
      exportList: [
        {label: '订单生成时间', value: 'createTime'},
        {label: '订单编号', value: 'orderNo'},
        {label: '车牌号', value: 'vlcn'},
        {label: '联系人姓名', value: 'lxrxm'},
        {label: '联系人手机号', value: 'lxrsjh'},
        {label: '发货城市', value: 'originCity'},
        {label: '收货城市', value: 'destinationCity'},
        {label: '货物名称', value: 'hwmc'},
        {label: '是否需要发票', value: 'sfxyfp'},
        {label: '发货地址', value: 'originAddr'},
        {label: '收货地址', value: 'destinationAddr'},
        {label: '承运状态', value: 'status'},
      ],
      checkData: [],
      checkList: [],
      timer: [],
      actualReturnUser: '',
      stopDialogVisible: false,
      stopRemarksText: '',
      searchForm: {
        originCity: '',
        destinationCity: '',
        vlcn: '',
        orderNo: '',
        lxrxm: '',
        lxrsjh: '',
        hwmc: '',
        sfxyfp: '',
        fhr: "",
        fhrsjh: '',
        status: '',
        startTime: '',
        endTime: '',
        overdue: '',
        currentPage: 1,
        pageSize: 10,
      },
      billingVisible: false,
      billingForm: {
        businessLicense: "",
        idCardNegativeUrl: "",
        idCardPositiveUrl: "",
        orderNo: "",
        receiverAddress: {
          adcode: "",
          addressDetail: "",
          area: "",
          areaFullName: "",
          city: "",
          latitude: "",
          longitude: "",
          phone: "",
          province: "",
          userAddressId: "",
          username: ""
        },
        senderAddress: {
          adcode: "",
          addressDetail: "",
          area: "",
          areaFullName: "",
          city: "",
          latitude: "",
          longitude: "",
          phone: "",
          province: "",
          userAddressId: "",
          username: ""
        }
      },
      total: 0,
      batchData: [],
      SyncOptions: [
        {label: '未同步', value: '0'},
        {label: '司机同步成功', value: '1'},
        {label: '货主同步成功', value: '2'},
        {label: '车辆同步成功', value: '3'},
        {label: '订单同步成功', value: '4'}
      ],
      areaList: [],
      rules: {
        'senderAddress.username': {required: true, message: '请输入发货姓名', trigger: 'blur'},
        'senderAddress.province': {required: true, message: ' ', trigger: 'change'},
        'senderAddress.addressDetail': {required: true, message: '请输入发货详细地址', trigger: 'blur'},
        'receiverAddress.username': {required: true, message: '请输入收货姓名', trigger: 'blur'},
        'receiverAddress.province': {required: true, message: ' ', trigger: 'change'},
        'receiverAddress.addressDetail': {required: true, message: '请输入收货详细地址', trigger: 'blur'},
      },
      senderList: [],
      receiverList: [],
      provinceOption: [],
      areaOption: [],
      cityOption: [],
      provinceOption1: [],
      areaOption1: [],
      cityOption1: [],
    }
  },
  mounted() {
    this.searchClick();
    this.$set(this, 'areaList', area);
    findProvince().then(res => {
      this.provinceOption = res.result
      this.provinceOption1 = res.result
    });
  },
  methods: {
    resetClick() {
      this.searchForm = {
        originCity: '',
        destinationCity: '',
        orderNo: '',
        lxrxm: '',
        lxrsjh: '',
        hwmc: '',
        sfxyfp: '',
        fhr: "",
        fhrsjh: '',
        status: '',
        startTime: '',
        endTime: '',
        currentPage: 1,
        pageSize: 10,
      }
      this.timer = []
    },
    searchClick() {
      this.searchForm.currentPage = 1;
      this.searchForm.pageSize = 10;
      this.getTabList();
    },
    getTabList() {
      if (this.timer.length > 0) {
        this.searchForm.startTime = this.timer[0];
        this.searchForm.endTime = this.timer[1];
      }
      let params = JSON.parse(JSON.stringify(this.searchForm))
      params.status == -4 ? params.overdue = -4 : ""
      params.status == -4 ? params.status = "" : ""
      batchList(params).then(res => {
        this.batchData = res.result.list;
        this.total = res.result.total;
      });
    },
    addressChange1(e, i, type) {
      if (type == 'province') {
        this.billingForm.senderAddress.province = e.province;
        findCity({provinceId: e.provinceId}).then(res => {
          this.cityOption1 = res.result
        });
        this.billingForm.senderAddress.city = "";
        this.billingForm.senderAddress.area = "";
      } else if (type == 'city') {
        this.billingForm.senderAddress.city = e.city;
        findArea({cityId: e.cityId}).then(res => {
          this.areaOption1 = res.result
        })
        this.billingForm.senderAddress.area = "";
      } else {
        this.billingForm.senderAddress.adcode = e.areaId;
        this.billingForm.senderAddress.longitude = e.longitude;
        this.billingForm.senderAddress.latitude = e.latitude;
        this.billingForm.senderAddress.area = e.area;
        this.billingForm.senderAddress.userAddressId = e.id;
      }
    },
    addressChange(e, i, type) {
      if (type == 'province') {
        this.billingForm.receiverAddress.province = e.province;
        findCity({provinceId: e.provinceId}).then(res => {
          this.cityOption = res.result
        });
        this.billingForm.receiverAddress.city = "";
        this.billingForm.receiverAddress.area = "";
      } else if (type == 'city') {
        this.billingForm.receiverAddress.city = e.city;
        findArea({cityId: e.cityId}).then(res => {
          this.areaOption = res.result
        });
        this.billingForm.receiverAddress.area = "";
      } else {
        this.billingForm.receiverAddress.adcode = e.areaId;
        this.billingForm.receiverAddress.longitude = e.longitude;
        this.billingForm.receiverAddress.latitude = e.latitude;
        this.billingForm.receiverAddress.area = e.area;
        this.billingForm.receiverAddress.userAddressId = e.id;
      }
    },
    //分页的切换
    paginationChange(e) {
      this.searchForm.currentPage = e.page;
      this.searchForm.pageSize = e.limit;
      this.getTabList();
    },
    syncFormatter(e, v, a) {
      this.SyncOptions.forEach(item => {
        if (item.value == a) {
          return item.label;
        }
      });
    },
    comment(e) {
      this.$router.push('/commentDetails/' + e.orderNo);
    },
    cancelStopRemarks() {
      this.stopDialogVisible = false
      this.stopRemarksText = ''
    },
    //取消订单弹框
    stopBtn(e) {
      this.orderData = e
      this.stopDialogVisible = true
    },
    cancelOrderList() {
      let that = this
      const formData = new FormData();
      formData.append("cancelReason", '用户取消')
      formData.append("orderNo", that.orderData.orderNo)
      cancelOrder(formData).then(res => {
        if (res.code == 0) {
          this.searchClick();
          that.stopDialogVisible = false;
          this.stopRemarksText = '';
          this.actualReturnUser = 0;
          that.$message({
            message: '操作成功',
            type: 'success'
          })
        } else {
          that.$message({
            message: '操作失败,请重试!',
            type: 'error'
          })
        }
      })
    },
    // 开票信息补全
    billingClick(e) {
      getOrderInvoiceInfo({orderNo: e.orderNo}).then(res => {
        this.billingForm = res.result;
        this.billingForm.orderNo = e.orderNo;
        if (res.result.senderAddress) {
          this.senderList = [res.result.senderAddress.province, res.result.senderAddress.city, res.result.senderAddress.area];
        }
        if (res.result.receiverAddress) {
          this.receiverList = [res.result.receiverAddress.province, res.result.receiverAddress.city, res.result.receiverAddress.area];
        }
        console.log(this.senderList, this.receiverList)
        this.billingVisible = true;
      })
    },
    billingSave() {
      let check = this.billingForm.businessLicense && this.billingForm.idCardNegativeUrl && this.billingForm.idCardPositiveUrl && this.billingForm.receiverAddress.province != '' && this.billingForm.receiverAddress.city != '' && this.billingForm.receiverAddress.area != '' && this.billingForm.senderAddress.province != '' && this.billingForm.senderAddress.city != '' && this.billingForm.senderAddress.area != '';
      this.$refs['form'].validate((valid) => {
        if (valid && check) {
          insertOrderInvoice(this.billingForm).then(res => {
            this.$message.success('操作成功');
            this.billingVisible = false;
          });
        } else {
          this.$message.warning("请检查必填项!")
        }
      })

    },
    // 图片回调
    photoChange(e, type) {
      switch (type) {
        case 1:
          this.billingForm.idCardPositiveUrl = e;
          break;
        case 2:
          this.billingForm.idCardNegativeUrl = e;
          break;
        case 3:
          this.billingForm.businessLicense = e;
          break;
      }
    },
    // 格式化json数据
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    selectionChange(e) {
      this.checkData = e;
    },
    exportClick() {
      if (this.checkList.length > 0) {
        let addTHeader = [] // 新增列头名称
        let addFilterVal = [] // 新增列头字段
        console.log(this.checkList);
        this.checkList.forEach(item => {
          addTHeader.push(item.label);
          addFilterVal.push(item.value);
        })
        const list = JSON.parse(JSON.stringify(this.checkData)) // 把要导出的数据tableData存到list
        list.forEach(item => {
          item.sfxyfp = item.sfxyfp == 0 ? '否' : '是';
          let status = '';
          if (item.status == 1) {
            status = '已失效';
          } else if (item.overdue != 1 && (item.status == 0 || item.status == 10)) {
            status = '未承运';
          } else if (item.overdue != 1 && (item.status == 1 || item.status == 2 || item.status == 3 || item.status == 4 || item.status == 5)) {
            status = '运输中';
          } else if (item.overdue != 1 && item.status == 127) {
            status = '已取消';
          } else if (item.overdue != 1 && item.status == 6) {
            status = '已完成';
          }
          item.status = status
        });
        const tHeader = addTHeader // 列头名称
        const filterVal = addFilterVal // 列头字段
        const data = this.formatJson(filterVal, list)
        console.log(list);
        console.log(data);
        console.log(tHeader);
        console.log(filterVal);
        exportJsonToExcel(tHeader, data, '导出结果') // 定义导出的excle名字
      } else {
        this.$message.warning('请先选择导出列')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.orderList {
  padding: 20px 30px;

  .container {
    background: #ffffff;
    padding: 20px 30px;
  }

  .export-check {
    padding: 15px 10px;

    .btn-dm {
      text-align: right;
      margin-top: 30px;
    }
  }
}

::v-deep .billingForm {
  .el-form-item {
    display: flex;
  }
}
</style>