<template>
  <el-row class="top">
    <el-col :span="2" style="display:flex;">
      <img style="width:50px;height:60px" src="@/assets/login/logo.png" alt=""/>
      <h1 style="position:absolute;left:50px;top:5px;height:100%;color: #ffffff;">货主发单系统</h1>
    </el-col>
    <el-col :span="22">
      <el-menu mode="horizontal" text-color="#ffffff" active-text-color="#ffffff">
        <el-menu-item>
          <template slot="title">
            <i class="el-icon-user"></i>
            <span>{{ user }}</span>
          </template>
        </el-menu-item>
        <el-menu-item @click="loginOut">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>退出</span>
          </template>
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>

export default {
  name: "top",
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')).userName,
      badgeValue: '',
      upPassDialogVisible: false,
      passForm: {
        oldPassword: '',
        newPassword: '',
        checkPass: ''
      },
      checkPass: '',
      messageVisible: false,
      messageList: [],
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('user')).userName
    }
  },
  watch: {
    userInfo(newVal, oldVal) {
      console.log(newVal);
      this.user = newVal;
    }
  },
  created() {
    // this.promptMessage();
  },
  methods: {
    // 退出登录
    loginOut() {
      this.$confirm('确定要退出登录吗？', '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('userToken');
        this.$router.push('/login');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
  }
}
</script>

<style lang="less">
.top {
  width: 100%;

  .badge {
    .el-badge__content.is-fixed {
      top: 12px;
    }
  }

  .logo-img {
    width: 230px;
  }

  .el-col-22 {
    text-align: right;
    color: #ffffff;

    .el-menu {
      display: inline-block;

      .el-submenu {
        background: #3459FE;
        border-bottom-color: #3459FE !important;

        .el-submenu__title {
          background: #3459FE;
          border-bottom-color: #3459FE !important;
        }

        &:hover, &:before, &:after, &:focus {
          background: #3459FE !important;
        }
      }

      .el-menu-item {
        background: #3459FE;
        border-bottom-color: #3459FE !important;

        &:hover, &:before, &:after, &:focus {
          background: #3459FE !important;
        }
      }

      i {
        color: #ffffff;
      }
    }
  }

  .message-list {
    height: 400px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: #535353;
    }

    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background: #ededed;
    }
  }
}

.el-menu--horizontal {
  .el-menu {
    background: #F0F2F5;

    .el-menu-item {
      color: #000000 !important;
      background: #F0F2F5 !important;

      &:hover {
        background: #E6F7FF !important
      }
    }
  }
}
</style>
