/**
 * 订单
 */
import {get, post} from './http'

// 车辆当前位置
export const carLocation = p => get('/dispatch/op/order', p);
// 订单详情
// export const orderInfo = p => get('/order/admin/operate/v1/order/info' , p);
export const orderInfo = p => get('/order/public/info/' + p);
//运单详情
export const waybillInfo = p => get('/order/public/driverInfo/' + p);
// 订单位置查询
export const orderLocation = (p, a) => get('/dispatch/order/location/' + p, a);
// 订单位置查询
export const order2Location = (p, a) => get('/order/public/' + p + '/location/', a);
// 车辆详情修改
export const orderModify = p => post('/dispatch/order/modify', p);
// 录音
export const ByClueId = p => get('/dispatch/clue-call-record/UdeskRecord/ByClueId', p);
// 分享
export const getShare = p => get('/user/admin/plat/shareV2', p);
// 转为非优订单
export const nonExcellent = p => get('/user/order/api/changeConsultPhone', p);
// 分享车辆位置
export const carLocationLink = p => get('user/app/shipperShareTrack', p);
// 承运信息
export const driverInfo = p => get('/dispatch/order/driverInfo/' + p, {});
// 获取车辆要求
export const vehicleRequirements = p => get('/dispatch/car/vehicle/requirements', p);
// 司机承运证件
export const getDriverCertificateById = p => get('/dispatch/order/getDriverCertificateById/' + p, {});
// 省市区三级联动
export const listByParent = p => get('/dispatch/area/listByParent', p);
// 根据code查询基础地区信息
export const findByCode = p => get('/dispatch/area/findByCode', p);
// 修改报价历史
export const orderHistory = p => get('/dispatch/order/history/' + p, {});
// 根据订单号获取订单的运单日志
export const getWaybillLog = p => get('/order/public/getWaybillLog', p);

