/**
 * api
 */
// let orderUrl = 'http://pre.sfdy.com';
let orderUrl = '';
// let devUrl="http://192.168.0.114:11008";
let devUrl = "";
import {get, post, post1} from './http'
// 登录
export const login = p => post(orderUrl + '/user/user/loginByCode/shipper', p);

// 获取验证码
export const verifyCode = p => get(orderUrl + '/user/user/code', p);
// 订单列表
export const batchList = p => get('/order/public/batch/list', p);
// 修改订单
export const updateOrder = p => post('/order/public/updateOrder', p);

export const createOrder = p => post(orderUrl + '/order/public/create', p);
//批量发单
export const batchCreateOrder = (p, p1) => post1(devUrl + `/order/public/batch-create/order?hasInvoice=${p1}`, p);


/**
 *  order
 */
// 获取货物名称
export const getGoodsName = p => get(orderUrl + '/order/public/goods/list', p);
// 订单发卸货地址
export const upAddress = p => post(orderUrl + '/dispatch/order/address/update', p);
// 获取装卸特殊要求
export const getLoadUnloadRequire = p => get(orderUrl + '/order/public/getLoadUnloadRequire', p);
// 获取防疫特殊要求
export const getPreventionRequire = p => get(orderUrl + '/order/public/getPreventionRequire', p);
// 订单详情
export const selectUpdateOrder = p => get(orderUrl + `/order/public/selectUpdateOrder?orderNo=${p}`);

export const orderInfo = p => get(orderUrl + `/order/public/webOrderDetail?orderNo=${p}`);
// 凭证列表
export const selectReceipt = p => get('/order/selectReceipt' , p);
//订单详情拿司机信息  装货照片
export const orderInfo1 = p => get(orderUrl + `/order/public/info?orderNo=${p}`);
// 订单位置查询
export const order2Location = (p, a) => get('/order/public/'+ p+'/location/', a);

// 车辆详情修改
export const orderModify = p => post(orderUrl + '/dispatch/order/modify', p);
// 承运信息
export const driverInfo = p => get(orderUrl + '/dispatch/order/driverInfo/' + p, {});
// 获取车辆要求   /user/carType/vehicle/requirements
export const vehicleRequirements = p => get(orderUrl + '/user/carType/vehicle/requirements', p);
// 司机承运证件
// export const getDriverCertificateById = p => get(orderUrl + '/dispatch/order/getDriverCertificateById/' + p, {});
export const getDriverCertificateById = p => get(`order/public/getDriverCertificateById/?orderNo=${p}`, {});
// 省市区三级联动
export const listByParent = p => get(orderUrl + '/dispatch/area/listByParent', p);

// 根据code查询基础地区信息
export const findByCode = p => get(orderUrl + '/dispatch/area/findByCode', p);
export const findProvince = p => get(orderUrl + '/user/adcode/findProvince', p);
export const findCity = p => get(orderUrl + '/user/adcode/findCity', p);
export const findArea = p => get(orderUrl + '/user/adcode/findArea', p);


// 修改报价历史
export const orderHistory = p => get(orderUrl + '/dispatch/order/history/' + p, {});
// 运单详情
export const waybillInfo = p => get(orderUrl + '/dispatch/order/driverInfo/' + p);

//车挂造型-货主
export const getCarType = p => get(orderUrl + '/user/carType/carHang/shipperType', p);


// 评论列表
export const selectCommentList = p => get('/order/public/selectCommentList', p);
// 删除评价
export const deleteCommentById = p => get('/dispatch/order/deleteCommentById', p);
// 删除回复
export const deleteReplyById = p => get('/dispatch/order/deleteReplyById', p);
// 订单人员信息
export const selectOrderUserInfo = p => get('/dispatch/order/selectOrderUserInfo', p);
// 货主信息
export const selectUserInfo = p => get('/dispatch/order/selectUserInfo', p);
// 货主全部订单评价列表
export const selectSenderCommentList = p => get('/dispatch/order/selectSenderCommentList', p);
// 添加线索
export const addClue = p => post('/dispatch/goods-clue', p);


// 开票信息查询
export const getOrderInvoiceInfo = p => get('/order/public/getOrderInvoiceInfo', p);
///order/public/insertOrderInvoice
// 开票信息补全
export const insertOrderInvoice = p => post('/order/public/insertOrderInvoice', p);

// 取消订单
export const cancelOrder = p => post('\n' + '/order/public/cancel', p);


//判断是否锁单
export const selectOrderIsLock = p => get(orderUrl + `/order/public/selectOrderIsLock?orderNo=${p}`);


//查询司机
export const selectDriverByPhone = p => get(orderUrl + `/order/public/selectDriverByPhone`, p);
//根据订单号获取订单的运单日志
export const getWaybillLog = p => get(orderUrl + `/order/public/getWaybillLog`, p);

//查看照片
export const getUploadLog = p => get(`/order/driver/getUploadLog`, p);
//查看照片
export const shipperRemarksList = p => get(`/order/public/shipperRemarks/list`, p);
//查看照片
export const judgeIsUploadIdCard = p => get(`/user/audit/shipper/judgeIsUploadIdCard`, p);







