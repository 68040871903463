<template>
  <div id="app">
    <el-container>
      <el-header>
        <top/>
      </el-header>
      <el-container>
        <router-view name="sidebar"/>
        <el-aside :width="openType?'64px':'200px'">
          <sidebar/>
        </el-aside>
        <el-main>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import sidebar from '@/components/sidebar/index'
import top from '@/components/top/index'

export default {
  name: "index",
  data() {
    return {
      openType: this.$store.getters.getIsCollapse,
    }
  },
  components: {
    sidebar, top
  },
  computed: {
    isCollapse() {
      return this.$store.getters.getIsCollapse;
    }
  },
  watch: {
    isCollapse(newVal, oldVal) {
      this.openType = newVal;
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.$store.commit("setUserInfo", {
        userInfo: user
      });
    }
  }
}
</script>

<style lang="less">
html, body {
  margin: 0;
}

#app, html, body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  margin: 0 !important;

  .el-container {
    height: 100%;
    overflow-y: hidden;
  }

  .el-header {
    background: #3459FE;
  }

  .el-aside {
    background: #F0F2F5;
  }

  .el-main {
    background: #F0F2F5;
    padding: 0;
    text-align: left;

    .el-breadcrumb {
      padding-left: 30px;
      height: 48px;
      background: #ffffff;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
      border-left: 1px solid #efefef;

      .el-breadcrumb__item {
        .el-breadcrumb__inner {
          font-weight: 600 !important;
        }
      }
    }

    .pl-container {
      padding: 10px 20px;
      .titles {
        border-left: 4px solid #1890FF;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-indent: 1em;
        font-weight: 600;
        font-weight: bold;
      }

      .search-form {
        margin-top: 20px;
      }

    }
  }
}
</style>
