import Vue from 'vue'
import VueRouter from 'vue-router'
import {Message} from 'element-ui';
import login from '@/views/login'
import index from '@/views/index'
import home from '@/views/home/index'
import orderList from '@/views/order/list'
import add from '@/views/order/add'
import details from '@/views/order/details'
import commentDetails from '@/views/commentDetails/index'


Vue.use(VueRouter)

const routes = [{
    path: '/login', name: 'Login', component: login
}, {
    path: '/',
    name: 'index',
    component: index,
    redirect: '/home',
    children: [
        {path: '/home', name: '首页', component: home, meta: {keepAlive: false}},
        {path: '/orderList', name: '订单列表', component: orderList, meta: {keepAlive: false}},
        {path: '/add', name: '发单', component: add, meta: {keepAlive: false}},
        {path: '/details/:id', name: '订单详情', component: details, meta: {keepAlive: false}},
        {path: '/commentDetails/:id', name: '评论详情', component: commentDetails, meta: {keepAlive: false}},
    ]
}]


const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL, routes
})
router.beforeEach((to, from, next) => {
    // next()
    if (to.path === '/login') {
        next()
    } else {
        let token = localStorage.getItem('userToken');
        if (token === null || token === '') {
            next('/login');
        } else {
            next();
        }
    }
});
export default router
