<template>
  <div class="comment">
    <el-breadcrumb separator-class="el-icon-coordinate">
      <el-breadcrumb-item>评论详情</el-breadcrumb-item>
      <div style="padding-right: 30px; float: right">
        <el-button size="small" type="primary" @click="refresh"
        >刷 新
        </el-button
        >
        <el-button size="small" type="primary" @click="back">返 回</el-button>
      </div>
    </el-breadcrumb>
    <div class="form">
      <div class="card">
        <el-row>
          <el-col :span="20">订单编号:{{ orderNo }}</el-col>
        </el-row>
      </div>
      <div class="card">
        <el-descriptions
            title="订单人员信息"
            direction="vertical"
            :column="8"
            border
        >
          <el-descriptions-item label="订单联系人姓名">{{
              orderData.lxrxm
            }}
          </el-descriptions-item>
          <el-descriptions-item label="订单联系人电话">{{
              orderData.lxrsjh
            }}
          </el-descriptions-item>
          <el-descriptions-item label="发货人姓名">{{
              orderData.fhr
            }}
          </el-descriptions-item>
          <el-descriptions-item label="发货人手机号">{{
              orderData.fhrsjh
            }}
          </el-descriptions-item>
          <el-descriptions-item label="收货人姓名">{{
              orderData.shr
            }}
          </el-descriptions-item>
          <el-descriptions-item label="收货人手机号">{{
              orderData.shrsjh
            }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="card">
        <el-descriptions title="订单评论列表" direction="vertical"/>
        <div class="table">
          <el-table :data="commentList" border style="width: 100%">
            <el-table-column
                type="index"
                label="序号"
                width="80px"
                align="center"
            />
            <el-table-column
                prop="commentUserName"
                label="用户名"
                align="center"
            />
            <el-table-column prop="commentTime" label="时间" align="center"/>
            <el-table-column label="评论内容" align="center">
              <template slot-scope="scope">
                {{ scope.row.comment }}
              </template>
            </el-table-column>
            <el-table-column label="回复内容" align="center">
              <template slot-scope="scope">
                {{
                  scope.row.replys.length > 0 ? scope.row.replys[0].reply : ""
                }}
              </template>
            </el-table-column>
<!--            <el-table-column label="操作" align="center" width="300px">-->
<!--              <template slot-scope="scope">-->
<!--                <el-button type="text" @click="detail(scope.row)"-->
<!--                >查看详情-->
<!--                </el-button-->
<!--                >-->
<!--                <el-button type="text" @click="del(scope.row, 0)"-->
<!--                >删除评论-->
<!--                </el-button-->
<!--                >-->
<!--                <el-button-->
<!--                    type="text"-->
<!--                    v-if="scope.row.replys.length > 0"-->
<!--                    @click="del(scope.row, 1)"-->
<!--                >删除回复-->
<!--                </el-button-->
<!--                >-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
          <pagination
              v-if="total > 0"
              :total="total"
              :page.sync="searchForm.pageNum"
              :limit.sync="searchForm.pageSize"
              @pagination="paginationChange"
          />
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="detailVisible" width="400px">
      <div class="">
        <el-descriptions column="1">
          <el-descriptions-item label="评论内容">{{
              commentDetail.comment
            }}
          </el-descriptions-item>
          <br/>
          <el-descriptions-item label="回复内容">
            {{
              commentDetail.replys
                  ? commentDetail.replys.length > 0
                      ? commentDetail.replys[0].reply
                      : ""
                  : ""
            }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailVisible = false"
        >确 定
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectCommentList,
  batchList,
} from "@/request/api";
import pagination from "@/components/pagination";

export default {
  name: "comment",
  components: {
    pagination,
  },
  inject: ["reload"],
  data() {
    return {
      orderNo: "",
      searchForm: {
        orderNo: "",
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      commentList: [],
      detailVisible: false,
      commentDetail: {},
      orderData: {},
    };
  },
  mounted() {
    this.orderNo = this.$route.params.id;
    this.searchForm.orderNo = this.$route.params.id;
    this.getTabList();
    this.userData();
  },
  methods: {
    refresh() {
      this.reload();
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    userData() {
      let params = {
        orderNo: this.orderNo,
        currentPage: 1,
        pageSize: 10,
      }
      batchList(params).then((res) => {
        this.orderData = res.result.list[0];
      });
    },
    getTabList() {
      selectCommentList(this.searchForm).then((res) => {
        this.commentList = res.result.list;
        this.total = res.result.total;
      });
    },
    //分页的切换
    paginationChange(e) {
      this.searchForm.pageNum = e.page;
      this.searchForm.pageSize = e.limit;
      this.getTabList();
    },
  },
};
</script>

<style lang="less">
.comment {
  .form {
    padding: 20px 30px;

    .card {
      padding: 20px 30px;
      background: #ffffff;
      font-size: 14px;

      .table {
        width: 1000px;
      }
    }
  }
}
</style>
