/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';

import {Message} from 'element-ui';
import router from '@/router/index'

if (process.env.VUE_APP_FLAG == "development") {
    // axios.defaults.baseURL = 'http://81.70.100.164:11008';
    axios.defaults.baseURL = 'https://wxnotify.sfdy.cn';
    // axios.defaults.baseURL = '/api';
    // axios.defaults.baseURL = 'http://pre.sfdy.com';
} else if (process.env.VUE_APP_FLAG == "dev") {
    axios.defaults.baseURL = 'https://wxnotify.sfdy.cn';
} else if (process.env.VUE_APP_FLAG == "test") {
    axios.defaults.baseURL = 'http://pre.sfdy.com';
} else if (process.env.VUE_APP_FLAG == "production") {
    axios.defaults.baseURL = 'https://www.zhiyuntongkj.com/prod';// 正式服务器环境
}

// 请求超时时间
axios.defaults.timeout = 600000;
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.interceptors.request.use((config) => {
    let token = localStorage.getItem("userToken")
    const randstr = localStorage.getItem('randstr')
    const ticket = localStorage.getItem('ticket')
    const sign = localStorage.getItem('sign')
    if (randstr) {
        config.headers.randstr = randstr;
        config.headers.type = 4;
        localStorage.removeItem('randstr')
    }
    if (ticket) {
        config.headers.ticket = ticket;
        localStorage.removeItem('ticket')
    }
    if (sign) {
        config.headers.sign = sign;
        localStorage.removeItem('sign')
    }
    if (token) {
        config.headers.Authorization = token;    //将token放到请求头发送给服务器
        config.headers.token = token;    //将token放到请求头发送给服务器
        config.headers.labelSourceId = 1;    //将token放到请求头发送给服务器
        config.headers.resourceType = 10000001;    //将token放到请求头发送给服务器
        config.headers.labelType = 10000001;    //将token放到请求头发送给服务器
        return config;
    } else {
        return config;
    }
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
// 响应拦截器
axios.interceptors.response.use(response => {
    if (response.headers.token != undefined) {
        localStorage.setItem('token', response.headers.token);
    }
    if (response.status === 200) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(response);
    }
}, error => {
    if (error.response.status) {
        switch (error.response.status) {
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
            case 401:
                router.replace({
                    path: '/login'
                });
                break;
            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面
            case 403:
                Message({
                    message: '登录过期，请重新登录', type: 'warning'
                });
                // 清除token
                localStorage.removeItem('userToken');
                // store.commit('loginSuccess', null);
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                setTimeout(() => {
                    router.replace({
                        path: '/login'
                    });
                }, 500);
                break;
            case 400009:
                Message({
                    message: '登录过期，请重新登录', type: 'warning'
                });
                // 清除token
                localStorage.removeItem('userToken');
                // store.commit('loginSuccess', null);
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                setTimeout(() => {
                    router.replace({
                        path: '/login', query: {
                            redirect: router.currentRoute.fullPath
                        }
                    });
                }, 500);
                break;
            // 404请求不存在
            case 404:
                Message({
                    message: '网络请求不存在', type: 'warning'
                });
                break;
            // 其他错误，直接抛出错误提示
            default:
                Message({
                    message: error.response.data.message, type: 'warning'
                });
        }
        return Promise.reject(error.response);
    }
});

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, responseType) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params, responseType: responseType,
            headers: {
                appVersion: 'web',
                os: 'web',
                phoneType: 'web'
            }
        })
            .then(res => {
                if (res.data.code === 4003 || res.data.code === 400009) {
                    Message({
                        message: '登录过期，请重新登录', type: 'warning'
                    });
                    // 清除token
                    localStorage.removeItem('userToken');
                    // store.commit('loginSuccess', null);
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    setTimeout(() => {
                        router.replace({
                            path: '/login', query: {
                                redirect: router.currentRoute.fullPath
                            }
                        });
                    }, 500);
                } else {
                    if (res.data.code === 0 && res.status === 200) {
                        resolve(res.data);
                    } else {
                        Message({message: res.data.message, type: 'warning'});
                    }
                }
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, responseType) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, {responseType: responseType})
            .then(res => {
                if (res.data.code === 4003) {
                    Message({
                        message: '登录过期，请重新登录', type: 'warning'
                    });
                    // 清除token
                    localStorage.removeItem('userToken');
                    // store.commit('loginSuccess', null);
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    setTimeout(() => {
                        router.replace({
                            path: '/login', query: {
                                redirect: router.currentRoute.fullPath
                            }
                        });
                    }, 500);
                } else {
                    if (res.data.code === 0 && res.status === 200) {
                        resolve(res.data);
                    } else {
                        Message({message: res.data.message, type: 'warning'});
                    }
                }
            })
            .catch(err => {
                reject(err.data)
            })
    });
};

export function post1(url, params, responseType) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, {responseType: responseType})
            .then(res => {
                if (res.data.code === 4003) {
                    Message({
                        message: '登录过期，请重新登录', type: 'warning'
                    });
                    // 清除token
                    localStorage.removeItem('userToken');
                    // store.commit('loginSuccess', null);
                    // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    setTimeout(() => {
                        router.replace({
                            path: '/login', query: {
                                redirect: router.currentRoute.fullPath
                            }
                        });
                    }, 500);
                } else {
                    resolve(res.data);
                }
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
