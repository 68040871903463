/**
 * 地图
 */
import {get, post} from './http'


let url = 'https://mapservice.zhiyuntongkj.com/heatmap/';
// let url = 'http://81.70.94.138:8080/heatmap';
// let url = 'http://81.70.31.48:8080/heatmap';
// 车辆轨迹点
export const vehiclesTracks = p => get(url + '/vehicles/tracks', p);
