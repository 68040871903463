<template>
  <div class="login">
    <el-form :model="loginForm" :rules="rules" ref="loginForm" class="loginForm" size="medium" style="padding-top:50px">
      <el-form-item>
        <p class="title">用户登录 / USER LOGIN</p>
      </el-form-item>
      <el-form-item prop="phone">
        <el-input prefix-icon="el-icon-user-solid" v-input-limit="0" placeholder="请输入用户账号"
                  v-model="loginForm.phone" maxlength="11"/>
      </el-form-item>
      <el-form-item prop="code">
        <el-input placeholder="请输入" v-model="loginForm.code" maxlength="6">
          <el-button slot="append" style="color: #1890FF" v-if="show" @click="getVerifyCode">获取验证码</el-button>
          <template slot="append" v-else>{{ count }}</template>
        </el-input>
      </el-form-item>
      <el-button class="sub-btn" style="width: 100%" @keyup.enter.native="submitForm" @click="submitForm">登录</el-button>
    </el-form>
  </div>
</template>

<script>
// import {MD5} from '../utils/utils'
import MD5 from 'js-md5'
// import {sha256} from 'js-sha256'
import {login, verifyCode} from '@/request/api';

export default {
  name: "login",
  data() {
    return {
      rules: {
        phone: [
          {required: true, message: '请输入用户账号', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'change'},
        ],
      },
      loginForm: {
        phone: '',
        code: '',
        srcType: 4,
      },
      show: true,
      count: '',
      timer: null,
    }
  },
  mounted() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  },
  methods: {
// 定义回调函数
    callback(res) {
      // 第一个参数传入回调结果，结果如下：
      // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
      // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
      // CaptchaAppId       String    验证码应用ID。
      // bizState    Any       自定义透传参数。
      // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
      console.log('callback:', res);
      // res（用户主动关闭验证码）= {ret: 2, ticket: null}
      // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
      // res（请求验证码发生错误，验证码自动返回terror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
      // 此处代码仅为验证结果的展示示例，真实业务接入，建议基于ticket和errorCode情况做不同的业务处理
      if (res.ret === 0) {
        // var str = '【randstr】->【' + res.randstr + '】      【ticket】->【' + res.ticket + '】';
        let str = this.loginForm.phone + res.ticket + res.randstr + '2023_zyt_user_tencent';
        console.log(str);
        const mySign = MD5(str);
        localStorage.setItem('randstr', res.randstr)
        localStorage.setItem('ticket', res.ticket)
        localStorage.setItem('sign', mySign)
        verifyCode({phone: this.loginForm.phone}).then(res => {
          this.$message.success('验证码已发送，请注意查收');
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000)
          }
        });
      }
    },
    // 定义验证码js加载错误处理函数
    loadErrorCallback() {
      var appid = '您的CaptchaAppId';
      // 生成容灾票据或自行做其它处理
      var ticket = 'terror_1001_' + appid + '_' + Math.floor(new Date().getTime() / 1000);
      callback({
        ret: 0,
        randstr: '@' + Math.random().toString(36).substr(2),
        ticket: ticket,
        errorCode: 1001,
        errorMessage: 'jsload_error'
      });
    },
    getVerifyCode() {
      if (!this.loginForm.phone) {
        this.$message.error('请先输入手机号');
        return;
      }
      try {
        // 生成一个验证码对象
        // CaptchaAppId：登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
        //callback：定义的回调函数
        let captcha = new TencentCaptcha('193663156', this.callback, {});
        // 调用方法，显示验证码
        captcha.show();
      } catch (error) {
        // 加载异常，调用验证码js加载错误处理函数
        this.loadErrorCallback();
      }
    },
    submitForm() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          const formData = new FormData()
          formData.append('srcType', '4')
          formData.append('phone', this.loginForm.phone)
          formData.append('code', this.loginForm.code)
          login(formData).then(res => {
            this.$store.commit("setUserInfo", {userInfo: res.result});
            localStorage.setItem('user', JSON.stringify(res.result));
            localStorage.setItem('userToken', res.result.token);
            this.$router.push({path: '/'})
          });
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  background: url("~@/assets/login/login.jpg") no-repeat center;
  background-size: cover;
  position: relative;

  .loginForm {
    position: absolute;
    top: 32%;
    right: 19%;
    border-radius: 5px;
    padding: 50px 30px;
    background: #ffffff;
    width: 300px;

    .el-form-item {
      margin-bottom: 29px;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
      text-align: left;
      margin: 0;
    }

    .sub-btn {
      background: #3459FE;
      border-radius: 4px;
      color: #ffffff;
    }
  }
}
</style>
