<template>
  <div class="examine">
    <div class="pl-container">
      <el-card class="box-card">
        <div class="container">
          <div class="tHeader">
            <div class="step">
              <el-steps :active="active" align-center finish-status="finish">
                <el-step class="stepTitle" title="上传文件"></el-step>
                <el-step title="结果"></el-step>
              </el-steps>
            </div>
          </div>
          <div class="content">
            <!-- 填写导入数据信息 -->
            <el-container v-show="active==0" style="height: 150px; border: 1px solid #eee;margin-top: 20px;">
              <el-aside width="100px"
                        style="background-color: rgb(238, 241, 246);text-align: center;line-height: 140px;">
                <i class="el-icon-folder-checked" style="font-size:30px"></i>
              </el-aside>
              <el-main style="background-color:#ffffff;padding: 20px;">
                <h3>下载批量发货模板</h3>
                <p>请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除</p>
                <p>
                  <el-button size="mini" type="text" @click="downClick()">下载模板</el-button>
                </p>
              </el-main>
            </el-container>
            <!-- 上传填好的数据表 -->
            <el-container v-show="active==0" style="height: 150px; border: 1px solid #eee;margin-top: 20px;">
              <el-aside width="100px"
                        style="background-color: rgb(238, 241, 246);text-align: center;line-height: 140px;">
                <i class="el-icon-upload2" style="font-size:30px;"></i>
              </el-aside>
              <el-main style="background-color:#ffffff;padding: 20px;">
                <h3>上传填写好的批量发货信息表</h3>
                <p>文件名后缀必须为xls或xlsx (即Excel格式)，上传大量数据耗时较长,请您耐心等待！</p>
                <!-- <p><el-button size="mini" type="text" @click="downClick('净化导入模板')">上传文件</el-button></p> -->
                <el-upload ref="upload" :show-file-list="true" class="upload-demo" action="/" :http-request="upload"
                           :file-list="filelist"
                           :before-upload="beforeUpload" :on-remove="handleRemove">
                  <el-button v-if="files1.length==0" type="text" size="mini">上传文件</el-button>
                </el-upload>
              </el-main>
            </el-container>

            <el-row v-if="active==2">
              <el-row v-if="unReplaceCount>0||failList.length>0">
                <!-- 本次可解除监控数量 -->
                <el-container style="height: 150px; border: 1px solid #eee;margin-top: 20px;">
                  <el-aside width="100px"
                            style="background-color: rgb(238, 241, 246);text-align: center;line-height: 140px;">
                    <i class="el-icon-success" style="font-size:30px;"></i>
                  </el-aside>
                  <el-main style="background-color:#ffffff;padding: 20px;">
                    <h3>本次可导入条数数量</h3>
                    <p>{{ replaceCount }}条</p>
                  </el-main>
                </el-container>
                <!-- 本次解除监控失败数量 -->
                <el-container style="height: 150px; border: 1px solid #eee;margin-top: 20px;">
                  <el-aside width="100px"
                            style="background-color: rgb(238, 241, 246);text-align: center;line-height: 140px;">
                    <i class="el-icon-warning" style="font-size:30px;"></i>
                  </el-aside>
                  <el-main style="background-color:#ffffff;padding: 20px;">
                    <h3>本次不可导入条数数量</h3>
                    <p>{{ unReplaceCount }}条</p>
                  </el-main>
                </el-container>
                <!-- 解除失败监控列表 -->
                <el-row style="padding:30px 0 20px 0;">
                  <el-col :span="20"><span style="font-size:16px;font-weight: 600;">不可导入列表</span></el-col>
                  <el-col :span="4">
                    <div>
                      <el-button size="medium" type="text" @click="failListExcel()">导出不可导入的表格</el-button>
                    </div>
                  </el-col>
                </el-row>
                <el-table ref="table" :data="failList" style="width: 100%" :height="500" center :border="true"
                          :row-style="{height:'10px',textAlign: 'center'}"
                          :cell-style="{ textAlign: 'center' }"
                          :header-cell-style="{ background: '#F5F5F5', color: '#000000',textAlign: 'center'}">
                  <el-table-column type="index" label="行数" width="100"></el-table-column>
                  <el-table-column prop="orderContactName" label="订单联系人姓名" width="120"></el-table-column>
                  <el-table-column prop="orderContactPhone" label="订单联系人手机号" width="150"></el-table-column>
                  <el-table-column prop="sendProvince" label="装货地址省市县" width="150"></el-table-column>
                  <el-table-column v-if="haveInvoice == 1" prop="sendAddressDetail" label="装货地址详情地址"
                                   width="200"></el-table-column>
                  <el-table-column v-if="haveInvoice == 1" prop="sendName" label="发货人姓名" width="120"></el-table-column>
                  <el-table-column v-if="haveInvoice == 1" prop="sendPhone" label="发货人手机号"
                                   width="150"></el-table-column>
                  <el-table-column prop="receiveProvince" label="卸货地址省市县" width="150"></el-table-column>
                  <el-table-column v-if="haveInvoice == 1" prop="receiveAddressDetail" label="卸货地址详情地址"
                                   width="200"></el-table-column>
                  <el-table-column v-if="haveInvoice == 1" prop="receiveName" label="收货人姓名"
                                   width="120"></el-table-column>
                  <el-table-column v-if="haveInvoice == 1" prop="receivePhone" label="收货人手机号"
                                   width="150"></el-table-column>
                  <el-table-column prop="goodsName" label="货物名称"></el-table-column>
                  <el-table-column prop="goodsWeight" label="货物总重量（t）" width="130"></el-table-column>
                  <el-table-column prop="quoteWay" label="报价方式"></el-table-column>
                  <el-table-column prop="num" label="数量"></el-table-column>
                  <el-table-column v-if="haveInvoice == 0" prop="payerType" label="付款方" width="100"></el-table-column>
                  <el-table-column prop="payerWay" label="付款方式"></el-table-column>
                  <el-table-column prop="planLoadTimeStart" label="计划装车时间（开始）" width="180"></el-table-column>
                  <el-table-column prop="planLoadTimeEnd" label="计划装车时间（结束）" width="180"></el-table-column>
                  <el-table-column v-if="haveInvoice == 1" prop="planUnloadTime" label="希望送达时间" width="180"/>
                  <!--<el-table-column prop="goodsLength" label="货物长度（m）" width="120"></el-table-column>-->
                  <!--<el-table-column prop="goodsWidth" label="货物宽度（m）" width="120"></el-table-column>-->
                  <!--<el-table-column prop="unitPrice" label="单价"></el-table-column>-->
                  <!--<el-table-column prop="isOil" label="是否含油卡"  width="120"></el-table-column>-->
                  <!--<el-table-column prop="oilCardAmount" label="油卡金额"></el-table-column>-->
                  <!--<el-table-column prop="isReceipt" label="是否要回单"  width="120"></el-table-column>-->
                  <!--<el-table-column prop="receiptAmount" label="回单押金"></el-table-column>-->
                  <!--<el-table-column prop="haveInvoice" label="是否开发票"  width="120"></el-table-column>-->
                  <!--<el-table-column prop="invoiceSource" label="发票开具方"  width="120"></el-table-column>-->
                  <!--<el-table-column prop="deposit" label="车方定金"></el-table-column>-->
                  <!--<el-table-column prop="isRefundDeposit" label="定金是否退还"  width="120"></el-table-column>-->
                  <!--<el-table-column prop="deliveryType" label="配货方式"></el-table-column>-->
                  <!--<el-table-column prop="expedited" label="是否加急"></el-table-column>-->
                  <!--<el-table-column prop="tcarHangType" label="车挂造型"></el-table-column>-->
                  <!--<el-table-column prop="tcarLength" label="车挂长度"></el-table-column>-->
                  <!--<el-table-column prop="tcarGooseneckType" label="鹅颈选择"></el-table-column>-->
                  <!--<el-table-column prop="carAccessories" label="特殊需求"></el-table-column>-->
                  <!--<el-table-column prop="roadCondition" label="路况"  width="100"></el-table-column>-->
                  <!--<el-table-column prop="loadTimes" label="装卸次数"></el-table-column>-->
                  <!--<el-table-column prop="loadUnloadRequire" label="装卸特殊要求"  width="120"></el-table-column>-->
                  <!--<el-table-column prop="preventionRequire" label="防疫特殊要求"  width="120"></el-table-column>-->
                  <!--<el-table-column prop="remark" label="订单备注"></el-table-column>-->
                  <el-table-column prop="error" label="错误提示" width="200" fixed="right"></el-table-column>
                </el-table>
              </el-row>
              <el-col v-else>
                <el-result icon="success" title="批量导入完成">
                  <template slot="extra">
                    <el-button type="primary" size="medium" @click="goBack" style="margin-top:20px">返回</el-button>
                  </template>
                </el-result>
              </el-col>
            </el-row>
          </div>
          <div class="footer">
            <span v-show="active==2" slot="footer" class="dialog-footer">
              <el-button @click="againUpload">重新上传</el-button>
              <el-button type="primary" @click="goBack">关 闭</el-button>
            </span>
            <span v-show="active==0" slot="footer" class="dialog-footer">
              <el-button @click="canel">取 消</el-button>
              <el-button type="primary" @click="next" :loading="uploadLoading">下一步</el-button>
            </span>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {exportJsonToExcel} from "@/vender/Export2Excel"
import {batchCreateOrder} from "@/request/api";
import axios from 'axios';

let failList
export default {
  name: "replaceImport",
  props: ['haveInvoice'],
  data() {
    return {
      active: 0,
      text: "下一步",
      failList: [],
      fileName: "",
      filelist: [],
      files1: [],
      replaceCount: 0,
      unReplaceCount: 0,
      uploadLoading: false,
      haveInvoice1: 1,
      isSuccess: true,
      loading: false,
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      totalPage: 0,
      countTotal: 10,
    }
  },
  watch: {
    haveInvoice: {
      immediate: true,
      handler(newVal, oldVal) {
        this.haveInvoice1 = newVal
      }
    }
  },
  mounted() {
    localStorage.removeItem("isSuccess")
    localStorage.setItem("isSuccess", this.isSuccess)
  },
  methods: {
    upload() {
      //覆盖组件默认上传行为
    },
    next() {
      //掉请求  请求ok active=2  否则=1
      //判断是否上传文件
      if (this.files1.length == 0) {
        this.$message.warning("请先上传文件！")
      } else {
        this.uploadLoading = true;
        this.replaceCount = 0;
        this.unReplaceCount = 0;
        this.failList = [];
        if (this.beforeUploadRight == true) {
          let fileFormData = new FormData();
          fileFormData.append('file', this.files, this.fileName);
          this.isSuccess = false
          localStorage.setItem("isSuccess", this.isSuccess)
          batchCreateOrder(fileFormData, this.haveInvoice1).then(async res => {
            if (res.code == 0) {
              this.beforeUploadRight = false;
              if (res.result.hasInvoice.unReplaceCount == 0 && res.result.noInvoice.unReplaceCount == 0) {
                this.uploadLoading = false;
                this.active = 2
                this.isSuccess = true;
                localStorage.setItem("isSuccess", this.isSuccess)
              } else {
                this.uploadLoading = false;
                this.haveInvoice1 == 1 ? this.replaceCount = res.result.hasInvoice.replaceCount : this.replaceCount = res.result.noInvoice.replaceCount;
                this.haveInvoice1 == 1 ? this.unReplaceCount = res.result.hasInvoice.unReplaceCount : this.replaceCount = res.result.noInvoice.unReplaceCount;
                this.haveInvoice1 == 1 ? failList = res.result.hasInvoice.errorData : failList = res.result.noInvoice.errorData;
                if (failList.length > 10) {
                  this.failList = failList.slice(0, 10)
                  this.pagination.total = failList.length;
                  this.totalPage = failList.length / this.pagination.pageSize;
                  setTimeout(() => {
                    this.lazyLoading(), 2000
                  })
                } else {
                  this.failList = failList
                }
                this.active = 2
                this.isSuccess = true;
                localStorage.setItem("isSuccess", this.isSuccess)
              }
            } else {
              this.$message.warning(res.message);
              this.uploadLoading = false;
              this.isSuccess = true;
              localStorage.setItem("isSuccess", this.isSuccess)
            }
          }).catch((error) => {
            this.isSuccess = true;
            localStorage.setItem("isSuccess", this.isSuccess)
          })
        }
      }
      // this.active = 1
    },
    //重新上传
    againUpload() {
      this.active = 0
      this.files1 = []
      this.$refs.upload.clearFiles()
      this.uploadLoading = false;
    },
    //取消
    canel() {
      if (this.isSuccess) {
        this.active = 0;
        this.actives = 1;
        this.files1 = [];
        this.uploadLoading = false;
        this.$refs.upload.clearFiles();
        this.$emit("closeDialog", false);
      } else {
        this.$message.warning('操作失败,任务执行中,请稍后再试！')
      }

    },
    downClick() {
      let downUrl
      this.haveInvoice1 == 1 ? downUrl = "批量发货发票模板.xlsx" : downUrl = "批量发货非发票模板.xlsx"
      const a = document.createElement('a');
      a.href = downUrl;
      a.download = '';
      a.click();
      window.URL.revokeObjectURL(downUrl);
    },
    beforeUpload(file) {
      this.files = file
      this.files1 = file
      const extension = file.name.split('.')[1] === 'xls'
      const extension2 = file.name.split('.')[1] === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!extension && !extension2) {
        this.$message.warning('上传文件只能是 xls、xlsx格式!')
        this.beforeUploadRight = false
        return false
      }
      if (!isLt2M) {
        this.$message.warning('上传文件大小不能超过 50MB!')
        this.beforeUploadRight = false
        return false
      }
      this.beforeUploadRight = true
      this.fileName = file.name;
    },
    setHttpExcelRequest() {

    },
    handleRemove(file, fileList) {
      this.files1 = [];
      this.uploadLoading = false;
    },
    //
    goBack() {
      this.canel()
    },
    // 解绑结果导出
    failListExcel() {
      const removeTHeader = [
        "序号",
        "订单联系人姓名",
        "订单联系人手机号",
        "装货地址省市县",
        "装货地址详情地址",
        "发货人姓名",
        "发货人手机号",
        "卸货地址省市县",
        "卸货地址详情地址",
        "收货人姓名",
        "收货人手机号",
        "货物名称",
        "货物总重量（t）",
        "付款方",
        "付款方式",
        "计划装车时间（开始）",
        "计划装车时间（结束）",
        "希望送达时间",
        "指定司机手机号/账号",
        "货物长度（m）",
        "货物宽度（m）",
        "报价方式",
        "单价",
        "数量",
        "是否含油卡",
        "油卡金额",
        "是否要回单",
        "回单押金",
        "车方定金",
        "定金是否退还",
        "配货方式",
        "是否加急",
        "车挂造型",
        "车挂长度",
        "鹅颈选择",
        "特殊需求",
        "路况",
        "装卸次数",
        "装卸特殊要求",
        "订单备注",
        "错误提示"
      ] // 新增列头名称
      const removeFilterVal =
          ["index",
            "orderContactName",
            "orderContactPhone",
            "sendProvince",
            "sendAddressDetail",
            "sendName",
            "sendPhone",
            "receiveProvince",
            "receiveAddressDetail",
            "receiveName",
            "receivePhone",
            "goodsName",
            "goodsWeight",
            "payerType",
            "payerWay",
            "planLoadTimeStart",
            "planLoadTimeEnd",
            "planUnloadTime",
            "specifiedDriverPhone",
            "goodsLength",
            "goodsWidth",
            "quoteWay",
            "unitPrice",
            "num",
            "isOil",
            "oilCardAmount",
            "isReceipt",
            "receiptAmount",
            "deposit",
            "isRefundDeposit",
            "deliveryType",
            "expedited",
            "tcarHangType",
            "tcarLength",
            "tcarGooseneckType",
            "carAccessories",
            "roadCondition",
            "loadTimes",
            "loadUnloadRequire",
            "remark",
            "error"]    // 新增列头字段
      let list = [];// 把要导出的数据tableData存到list
      let index = 0;
      failList.forEach(el => {
        index += 1;
        list.push({el, ...el, ...el.excelDataVO, index})
      })
      if (list.length > 0) {
        const tHeader1 = removeTHeader // 列头名称
        const filterVal1 = removeFilterVal // 列头字段
        const data1 = this.formatJson(filterVal1, list)
        exportJsonToExcel(tHeader1, data1, '不可导入名单') // 定义导出的excle名字
      } else {
        this.$Message('请先选择数据')
      }
    },
    // 格式化json数据
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    lazyLoading() {
      let dom = document.querySelector(".el-table__body-wrapper");
      dom.addEventListener("scroll", (v) => {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 1) {
          if (this.pagination.page >= this.totalPage) {
          }
          if (this.pagination.page < this.totalPage) {
            //当前页数小于总页数就请求
            let pageSize = this.pagination.pageSize
            let page = this.pagination.page
            let currList = failList.slice(pageSize * page - 1, pageSize * (page + 1) - 1)
            this.failList.push(...currList)
            this.pagination.page++; //当前页数自增
          }
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.examine {
  .pl-container {
    .search {
      background: #ffffff;
      padding: 10px 20px;
      margin-bottom: 20px;
    }

    .container {
      background: #ffffff;
      padding: 10px 20px;
      margin-bottom: 20px;

      .tHeader {
        display: flex;

        .step {
          width: 100%;
        }
      }

      .footer {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        margin-right: 50px;
      }
    }
  }
}

::v-deep .stepTitle {
  color: #0a0;
}

::v-deep .upload-demo {
  // display: block;
  width: 250px;
  height: 0;
}

::v-deep .el-upload--text {
  float: left;
}
</style>