<template>
  <div class="addForm">
    <div class="form">
      <el-radio-group v-model="form.haveInvoice" style="margin-bottom: 30px;" @change="haveChange">
        <el-radio-button :label="1">开票发货</el-radio-button>
        <el-radio-button :label="0">不开票发货</el-radio-button>
      </el-radio-group>
      <div class="btn-group">
        <el-button type="primary" size="small" @click="toImport">批量发订单</el-button>
      </div>
      <el-descriptions title="订单人员信息" direction="vertical" :column="6" border/>
      <el-form ref="form" label-position="top" :model="form" :rules="form.haveInvoice==1?formRules:formRules1"
               :validate-on-rule-change="false" label-width="40%"
               style="text-align: center">
        <el-row>
          <el-col :span="18" style="border: 0">
            <el-row>
              <el-col :span="4">
                <el-form-item label="订单联系人姓名" prop="orderContactName">
                  <el-input placeholder="请输入" v-model="form.orderContactName"/>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="订单联系人电话" prop="orderContactPhone">
                  <el-input placeholder="请输入" v-input-limit='0' maxlength="11" v-model="form.orderContactPhone"/>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="发货人姓名" prop="senderAddress.username">
                  <el-input placeholder="请输入" v-model="form.senderAddress.username"/>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="发货人手机号" prop="senderAddress.phone">
                  <el-input placeholder="请输入" v-input-limit='0' maxlength="11" v-model="form.senderAddress.phone"/>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="收货人姓名" prop="receiverAddress.username">
                  <el-input placeholder="请输入" v-model="form.receiverAddress.username"/>
                </el-form-item>
              </el-col>
              <el-col :span="3" style="border-right: 1px solid #eee;">
                <el-form-item label="收货人手机号" prop="receiverAddress.phone">
                  <el-input placeholder="请输入" v-input-limit='0' maxlength="11" v-model="form.receiverAddress.phone"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <!--          <el-col :span="6">
                      <el-row>
                        <el-col :span="12">
                          <el-form-item label="指定司机手机号">
                            <el-input disabled v-model="form.specifiedDriverPhone"/>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="指定司机姓名">
                            <el-input disabled v-model="form.specifiedDriverName"/>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-col>-->
        </el-row>
      </el-form>
      <el-descriptions title="订单信息" direction="vertical" :column="6" border style="margin-top:20px"/>
      <el-form ref="form" :model="form" :rules="form.haveInvoice==1?formRules:formRules1"
               :validate-on-rule-change="false" label-width="40%">
        <el-row style="border-right: 1px solid #eee;">
          <el-col :span="12">
            <el-form-item label="发货（装车）地址" prop="senderAddress.areaId" label-width="150px">
              <el-row style="background: #FFFFFF">
                <el-col :span="14">
                  <el-select v-model="form.senderAddress.provinceId" placeholder="省" style="width:30%"
                             value-key="provinceId" @change="addressChange1($event,1,'province')">
                    <el-option v-for="item in provinceOption1" :key="item.provinceId" :label="item.province"
                               :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="form.senderAddress.cityId" placeholder="市" value-key="cityId" style="width:35%"
                             @change="addressChange1($event,1,'city')">
                    <el-option v-for="item in cityOption1" :key="item.cityId" :label="item.city" :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="form.senderAddress.areaId" placeholder="区/县" style="width:35%" value-key="areaId"
                             @change="addressChange1($event,1,'area')">
                    <el-option v-for="item in areaOption1" :key="item.areaId" :label="item.area" :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="10" class="detail">
                  <el-input v-model="form.senderAddress.addressDetail" placeholder="请输入内容" style="width: 100%;"/>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12" prop="">
            <el-form-item label="发货（装车）时间" prop="planLoadTime">
              <el-col :span="12">
                <el-date-picker v-model="planLoadDate" type="date" valueFormat="yyyy-MM-dd"
                                :picker-options="pickerOptions" :placeholder="form.planLoadDate"
                                @change="planTimeChange" style="width: 100%"/>
              </el-col>
              <el-col :span="12">
                <el-select v-model='form.planLoadTime' :disabled="planLoadStatus">
                  <el-option v-for="(item,index) in hourOption" :key="index" :value="item.value"
                             :label="item.label"/>
                </el-select>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="border-right: 1px solid #eee;">
          <el-col :span="12">
            <el-form-item label="收货（卸车）地址" prop="receiverAddress.areaId" label-width="150px">
              <el-row style="background: #FFFFFF">
                <el-col :span="14">
                  <el-select v-model="form.receiverAddress.provinceId" placeholder="省" style="width:30%"
                             value-key="provinceId" @change="addressChange($event,0,'province')">
                    <el-option v-for="item in provinceOption" :key="item.provinceId" :label="item.province"
                               :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="form.receiverAddress.cityId" placeholder="市" value-key="cityId"
                             style="width:35%" @change="addressChange($event,0,'city')">
                    <el-option v-for="item in cityOption" :key="item.cityId" :label="item.city" :value="item">
                    </el-option>
                  </el-select>
                  <el-select v-model="form.receiverAddress.areaId" placeholder="区/县" style="width:35%"
                             value-key="areaId" @change="addressChange($event,0,'area')">
                    <el-option v-for="item in areaOption" :key="item.areaId" :label="item.area" :value="item">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="10">
                  <el-input v-model="form.receiverAddress.addressDetail" placeholder="请输入内容" style="width:200px;"/>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="希望送达时间" prop="planArriveTime">
              <el-date-picker v-model="form.planArriveTime" valueFormat="timestamp" type="datetime"
                              :picker-options="pickerOptions1" placeholder="选择日期时间"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form ref="form" :model="form" :rules="form.haveInvoice==1?formRules:formRules1"
               :validate-on-rule-change="false"
               label-width="115px">
        <el-row class="row-form">
          <el-col :span="6" class="item">
            <el-form-item label="货物名称" prop="goodsInfo.goodsName">
              <el-autocomplete v-model="form.goodsInfo.goodsName" :fetch-suggestions="querySearchAsync"
                               @select="goodNameSel" placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="货物重量(吨)" prop="goodsInfo.goodsWeight">
              <el-input v-model="form.goodsInfo.goodsWeight" placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="货物-长(米)">
              <el-input v-model="form.goodsInfo.overrunInfo.length" placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="货物-宽(米)">
              <el-input v-model="form.goodsInfo.overrunInfo.width" placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="货物-高(米)">
              <el-input v-model="form.goodsInfo.overrunInfo.high" placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="配送方式">
              <el-select v-model='form.deliveryType' placeholder="未选择" style="width:100%">
                <el-option v-for="(item,index) in deliveryTypeOption" :key="index" :value="item.value"
                           :label="item.label"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="是否加急">
              <el-select v-model="form.expedited" placeholder="未选择" style="width:100%">
                <el-option label="否" :value="1"/>
                <el-option label="是" :value="2"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="装卸次数">
              <el-select v-model="form.loadTimes" placeholder="未选择">
                <el-option v-for="item in loadTimeOption" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="车挂造型">
              <el-select v-model="form.vehicleType" value-key="id" placeholder="请选择" style="width:100%" multiple
                         collapse-tags @change="carHangOtherChange">
                <el-option v-for="item in tcarHangTypeOptions" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="车挂长度">
              <el-select v-model="form.vehicleRequirementsInfo.tcarLengthList" value-key="id" placeholder="请选择"
                         style="width:100%"
                         multiple collapse-tags>
                <el-option v-for="item in tcarLengthOptions" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="鹅颈选择">
              <el-select v-model="form.vehicleRequirementsInfo.tcarGooseneckTypeList" value-key="id" placeholder="请选择"
                         style="width:100%"
                         multiple collapse-tags>
                <el-option v-for="item in tcarGooseneckTypeOptions" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="特殊需求">
              <el-select v-model="form.carAccessories" value-key="id"
                         placeholder="请选择" multiple style="width:100%"
                         collapse-tags @change="carAccessoriesChange">
                <el-option v-for="item in tcarAccessoryOptions" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="路况">
              <el-select v-model="form.vehicleRequirementsInfo.roadConditionList" value-key="id" placeholder="请选择"
                         style="width:100%"
                         multiple collapse-tags>
                <el-option v-for="item in roadConditionOptions" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="装卸特殊要求">
              <el-select v-model="form.serviceRequirements.loadUnloadRequireList" multiple value-key="id" collapse-tags
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in loadUnloadRequireOption" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="要装货照片">
              <el-select v-model="form.isZhZp" placeholder="未选择" style="width:100%">
                <el-option label="否" :value="0"/>
                <el-option label="是" :value="1"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="item">
            <el-form-item label="要卸货照片">
              <el-select v-model="form.isXhZp" placeholder="未选择" style="width:100%">
                <el-option label="否" :value="0"/>
                <el-option label="是" :value="1"/>
              </el-select>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="6" class="item">
                      <el-form-item label="防疫特殊要求" style="border-right: 1px solid #F0F2F5">
                        <el-select v-model="form.serviceRequirements.preventionRequireList" value-key="id" style="width:100%"
                                   placeholder="请选择">
                          <el-option v-for="item in preventionRequireOption" :key="item.id" :label="item.name" :value="item"/>
                        </el-select>
                      </el-form-item>
                    </el-col>-->
          <!--          <el-col :span="16" class="item">-->
          <!--            <el-form-item>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
        </el-row>
      </el-form>
      <el-form ref="form" :model="form" :rules="form.haveInvoice==1?formRules:formRules1"
               :validate-on-rule-change="false"
               label-width="115px" label-position="top" style="margin-top: 30px">
        <el-descriptions title="订单费用相关" direction="vertical" :column="6" border/>
        <el-row class="top-form">
          <el-col :span="3" class="item">
            <el-form-item label="出价方式" class="first-child">
              <el-select v-model="form.quoteCode" placeholder="请选择" @change="quoteChange" style="width:100%">
                <el-option v-for="item in quoteCodeOptions" :key="item.id" :label="item.name" :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="item">
            <el-form-item label="数量">
              <el-input v-model="form.num" v-input-limit='2' placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="item">
            <el-form-item label="单价">
              <el-input v-model="form.price" v-input-limit='2' placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="item">
            <el-form-item label="付款方">
              <el-select v-model="form.payerType" placeholder="请选择" @change="payTypeChange" style="width:100%"
                         :disabled="form.haveInvoice == 1">
                <el-option label="发货方付款" :value="1"/>
                <el-option label="收货方付款" :value="2"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="item">
            <el-form-item label="付款方式" prop="payerWay">
              <el-select v-model="form.payerWay" placeholder="请选择" style="width:100%" :disabled="form.haveInvoice == 1">
                <el-option label="线上结算" :value="1"/>
                <el-option :label="form.haveInvoice == 1?'对公汇款':'线下结算'" :value="2"/>
              </el-select>
            </el-form-item>
          </el-col>
          <!--          <el-col :span="3" class="item">
                      <el-form-item label="是否要发票" prop="haveInvoice">
                        <el-select v-model="form.haveInvoice" placeholder="请选择" @change="needInvoiceChange" style="width:100%"
                                   disabled>
                          <el-option v-for="item in hasOptions" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="3" class="item">
                      <el-form-item label="发票开具方" prop="invoiceSource">
                        <el-select v-model="form.invoiceSource" placeholder="请选择" :disabled="form.haveInvoice==0"
                                   style="width:100%">
                          <el-option label="平台开具" :value="1"/>
                          <el-option label="车方开具" :value="2"/>
                        </el-select>
                      </el-form-item>
                    </el-col>-->
          <el-col :span="3" class="item">
            <el-form-item label="是否含油卡">
              <el-select v-model="form.isOil" placeholder="请选择" style="width:100%">
                <el-option label="否" :value="0"/>
                <el-option label="是" :value="1"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="item">
            <el-form-item label="油卡金额" class="first-child">
              <el-input placeholder="请输入" v-input-limit='2' v-model="form.oilCardAmount" :disabled="form.isOil==0"/>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="item">
            <el-form-item label="是否要回单">
              <el-select v-model="form.isReceipt" placeholder="请选择" style="width:100%">
                <el-option label="否" :value="0"/>
                <el-option label="是" :value="1"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="item">
            <el-form-item label="回单押金">
              <el-input v-model="form.receiptAmount" v-input-limit='2' :disabled="form.isReceipt==0" placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="item">
            <el-form-item label="车方定金">
              <el-input v-model="form.deposit" v-input-limit='2' placeholder="请输入"/>
            </el-form-item>
          </el-col>
          <el-col :span="3" class="item">
            <el-form-item label="定金是否退还" class="last-child">
              <el-select v-model="form.isRefundDeposit" placeholder="请选择" style="width:100%">
                <el-option label="退还" :value="0"/>
                <el-option label="不退还" :value="1"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="goodsPhotos">
        <el-descriptions title="发货照片" direction="vertical" :column="6" border/>
        <el-radio-group v-model="form.goodsInfo.photoShowTime">
          <el-radio :label="0">司机抢单前显示</el-radio>
          <el-radio :label="1">司机抢单后显示</el-radio>
        </el-radio-group>
        <uploadList ref="ID" :imgList="form.goodsInfo.photosUrls" v-on:photoChange="photoChange($event)"
                    v-on:removeImg="removeImg($event)"/>
      </div>
      <div class="remarks">
        <el-descriptions title="订单备注" direction="vertical" :column="6" border/>
        <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 8 }" placeholder="请输入内容" v-model="form.remark"
                  maxlength="300" show-word-limit/>
      </div>
      <div style="text-align:center;margin-top:20px">
        <el-button type="primary" :loading="addLoading"
                   style="width:180px;height:40px;font-size: 16px;text-align: center;font-weight: 600;"
                   @click="addOrder">发货
        </el-button>
        <el-button type="primary" size="small"
                   style="width:180px;height:40px;font-size: 16px;text-align: center;font-weight: 600;"
                   @click="driverSpecifyDialogVisible = true">指定司机
        </el-button>
      </div>
    </div>
    <el-dialog title="车挂造型" :visible.sync="carHangOtherDialogVisible" width="30%" :before-close="carHangOtherClose">
      <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 8 }" placeholder="请输入内容"
                v-model="form.vehicleRequirementsInfo.carHangOther"
                maxlength="300" show-word-limit/>
      <span slot="footer" class="dialog-footer">
    <el-button @click="carHangOtherClose">取 消</el-button>
    <el-button type="primary" @click="carHangOtherDialogVisible = false">确 定</el-button>
     </span>
    </el-dialog>
    <el-dialog title="特殊需求" :visible.sync="carAccessoriesDialogVisible" width="30%" :before-close="carAccessoriesClose">
      <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 8 }" placeholder="请输入内容"
                v-model="carAccessoriesOther"
                maxlength="300" show-word-limit/>
      <span slot="footer" class="dialog-footer">
    <el-button @click="carAccessoriesClose">取 消</el-button>
    <el-button type="primary" @click="carAccessoriesBeOK">确 定</el-button>
     </span>
    </el-dialog>
    <el-dialog title="" :visible.sync="importDialogVisible" width="80%" :append-to-body="true"
               :before-close="handleClose" center>
      <replaceImport ref="import" @closeDialog="closeDialog" :haveInvoice.sync="form.haveInvoice"></replaceImport>
    </el-dialog>
    <el-dialog title="指定司机" :visible.sync="driverSpecifyDialogVisible" width="440px" :before-close="driverSpecifyClose"
               class="zhidingsiji">
      <div class="drivers-content">
        <el-row :gutter="5">
          <el-col :span="19">
            <el-input v-model="driverPhone" max-length="11" v-input-limit="0" placeholder="请输入需要指定司机的注册手机号"
                      size="mini"/>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" icon="el-icon-search" size="mini" @click="searchDriver">查 询</el-button>
          </el-col>
        </el-row>
        <!--审核通过-->
        <el-row class="driverDetails" v-if="driverInfo&&driverInfo.auditResult == 3">
          <el-col :span="5">
            <el-image :src="require('../../assets/home/img_driver_hp.png')" style="width: 56px; height: 56px"/>
          </el-col>
          <el-col :span="19">
            <div class="driver_name">{{ driverInfo.userName }}</div>
            <div class="driver_tips">
              <div class="tips-items">{{ driverInfo.plateNumber }}</div>
              <div class="tips-items">{{ driverInfo.carHangType }}</div>
            </div>
          </el-col>
        </el-row>
        <div class="driverDetails" v-else-if="driverInfo&&driverInfo.auditResult == 1">
          <div class="con-txt null">
            <i class="el-icon-warning"/>您指定的司机注册证件审核未通过，是否邀请当前司机重新上传注册证件
          </div>
        </div>
        <div class="driverDetails" v-else-if="driverInfo&&driverInfo.auditResult == 0">
          <div class="con-txt examine">
            <i class="el-icon-warning"/>当前司机正在审核，是否确定给他指派订单
          </div>
        </div>
        <div class="driverDetails" v-else-if="driverInfo == null">
          <div class="con-txt reject">
            <i class="el-icon-warning"/>您指定的司机非平台司机，是否邀请当前司机成为平台司机并接单
          </div>
        </div>
      </div>
      <div class="drivers-footer">
        <el-button type="primary" :loading="addLoading" @click="appointDriver" size="small"
                   v-if="driverInfo&&driverInfo.auditResult == 3">确认下单
        </el-button>
        <el-button type="primary" :loading="addLoading" @click="appointDriver" size="small"
                   v-else-if="driverInfo&&driverInfo.auditResult == 1">邀 请
        </el-button>
        <el-button type="primary" :loading="addLoading" @click="appointDriver" size="small"
                   v-else-if="driverInfo&&driverInfo.auditResult == 0">指派订单
        </el-button>
        <el-button type="primary" :loading="addLoading" @click="appointDriver" size="small"
                   v-else-if="driverInfo == null">
          邀请司机并接单
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import area from '@/utils/area.json';
import uploadList from '@/components/upload-list';
import replaceImport from "@/components/import";
import {
  findByCode,
  getGoodsName,
  vehicleRequirements,
  getPreventionRequire,
  getLoadUnloadRequire,
  getCarType,
  createOrder,
  findArea,
  findCity,
  findProvince, selectDriverByPhone, judgeIsUploadIdCard
} from '@/request/api'
import form from "element-ui/packages/form";

export default {
  name: "addForm",
  inject: ['reload'],
  components: {uploadList, replaceImport},
  data() {
    return {
      addLoading: false,
      provinceOption: [],
      provinceOption1: [],
      areaOption: [],
      areaOption1: [],
      cityOption: [],
      cityOption1: [],
      // 装卸方式op
      loadTimeOption: [
        // {label: '未选择', value: 0},
        {label: '一装一卸', value: 1},
        {label: '一装二卸', value: 2},
        {label: '二装一卸', value: 3},
        {label: '二装二卸', value: 4},
      ],
      importDialogVisible: false,
      carAccessoriesOther: "",
      carHangOtherDialogVisible: false,
      carAccessoriesDialogVisible: false,
      activeName: 'first',
      form: {
        isXhZp: null,
        isZhZp: null,
        loadTimes: 1,
        isSpeclifiedDriver: 0,
        channel: 9,
        carAccessories: [],
        senderList: [],
        receiverList: [],
        isOil: 0,
        isReceipt: 0,
        deposit: "",
        isQuickly: 0,//是否是快速发货
        isRefundDeposit: 0,
        name: "",
        num: "",
        orderContactName: "",
        orderContactPhone: "",
        price: "",
        quoteCode: 1,
        receiptAmount: "",

        refundsAmount: 0,
        vehicleRequirementsInfo: {
          carHangOther: "",
          roadConditionList: [],
          tcarGooseneckTypeList: [],
          tcarHangTypeList: [],
          vehicleType: [],
          tcarLengthList: []
        },
        serviceRequirements: {
          preventionRequireList: [],
          loadUnloadRequireList: []
        },
        status: 0,
        goodsInfo: {
          additionalRequirement: "",
          additionalRequirementInfo: "",
          additionalRequirementList: [],
          attachment: "",
          goodsClassifyId: "",
          goodsClassifyName: "",
          highestOffer: "",
          highestOfferInfos: [],
          needCarLength: "",
          overrun: "",
          goodsName: "",
          goodsNameId: '',
          goodsWeight: "",
          photoShowTime: 0,
          photosUrls: [],
          overrunInfo: {
            high: '',
            length: '',
            width: '',
          }
        },
        senderAddress: {
          adcode: "",
          addressDetail: "",
          area: "",
          city: "",
          latitude: 0,
          longitude: 0,
          phone: "",
          province: "",
          userAddressId: 0,
          username: ""
        },
        receiverAddress: {
          adcode: "",
          addressDetail: "",
          area: "",
          areaId: "",
          city: "",
          cityId: "",
          latitude: 0,
          longitude: 0,
          phone: "",
          province: "",
          provinceId: "",
          userAddressId: 0,
          username: ""
        },
        planLoadDate: "",
        planLoadTime: "",
        planArriveTime: "",
        userId: 0,
        expedited: 1,
        deliveryType: 1,
        remark: "",
        oilCardAmount: "",
        haveInvoice: 1,
        payerWay: 2,  //付款方式
        payerType: 1,   //付款方,
        invoiceSource: 1,
        isMargin: 0,//是否需要保证金，0否1是
        orderType: 0,
        distance: 0,
        lineId: 0,
        planLoadDateBef: "",
        vehicleType: [],
        specifiedDriverPhone: "",
        isPrice: 0,
      },
      areaList: [],
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
        // },
        shortcuts: [{
          text: '今天或明天',
          onClick(picker) {
            picker.$emit('pick', '0');
            console.log('12312312');
          }
        }]
      },
      pickerOptions1: {
        /*disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
        }*/
      },
      planLoadHour: '',
      planLoadStatus: true,
      planLoadDate: '',
      hourOption: [
        {label: '全天都可以', value: '00:00-23:59'},
        {label: '凌晨00:00-06:00', value: '00:00-06:59'},
        {label: '凌晨00:00', value: '00:00-00:59'},
        {label: '凌晨01:00', value: '01:00-01:59'},
        {label: '凌晨02:00', value: '02:00-02:59'},
        {label: '凌晨03:00', value: '03:00-03:59'},
        {label: '凌晨04:00', value: '04:00-04:59'},
        {label: '凌晨05:00', value: '05:00-05:59'},
        {label: '上午06:00', value: '06:00-06:59'},
        {label: '上午07:00', value: '07:00-07:59'},
        {label: '上午08:00', value: '08:00-08:59'},
        {label: '上午09:00', value: '09:00-09:59'},
        {label: '上午10:00', value: '10:00-10:59'},
        {label: '上午11:00', value: '11:00-11:59'},
        {label: '上午 06:00-12:00', value: '06:00-12:59'},
        {label: '中午12:00', value: '12:00-12:59'},
        {label: '下午13:00', value: '13:00-13:59'},
        {label: '下午14:00', value: '14:00-14:59'},
        {label: '下午15:00', value: '15:00-15:59'},
        {label: '下午16:00', value: '16:00-16:59'},
        {label: '下午17:00', value: '17:00-17:59'},
        {label: '晚上 18:00-24:00', value: '18:00-23:59'},
        {label: '晚上18:00', value: '18:00-18:59'},
        {label: '晚上19:00', value: '19:00-19:59'},
        {label: '晚上20:00', value: '20:00-20:59'},
        {label: '晚上21:00', value: '21:00-21:59'},
        {label: '晚上22:00', value: '22:00-22:59'},
        {label: '晚上23:00', value: '23:00-23:59'},
      ],
      hourOption1: [
        {label: '全天都可以', value: '00:00-23:59'},
        {label: '凌晨00:00-06:00', value: '00:00-06:59'},
        {label: '凌晨00:00', value: '00:00-00:59'},
        {label: '凌晨01:00', value: '01:00-01:59'},
        {label: '凌晨02:00', value: '02:00-02:59'},
        {label: '凌晨03:00', value: '03:00-03:59'},
        {label: '凌晨04:00', value: '04:00-04:59'},
        {label: '凌晨05:00', value: '05:00-05:59'},
        {label: '上午06:00', value: '06:00-06:59'},
        {label: '上午07:00', value: '07:00-07:59'},
        {label: '上午08:00', value: '08:00-08:59'},
        {label: '上午09:00', value: '09:00-09:59'},
        {label: '上午10:00', value: '10:00-10:59'},
        {label: '上午11:00', value: '11:00-11:59'},
        {label: '上午 06:00-12:00', value: '06:00-12:59'},
        {label: '下午12:00', value: '12:00-12:59'},
        {label: '下午13:00', value: '13:00-13:59'},
        {label: '下午14:00', value: '14:00-14:59'},
        {label: '下午15:00', value: '15:00-15:59'},
        {label: '下午16:00', value: '16:00-16:59'},
        {label: '下午17:00', value: '17:00-17:59'},
        {label: '晚上 18:00-24:00', value: '18:00-23:59'},
        {label: '晚上18:00', value: '18:00-18:59'},
        {label: '晚上19:00', value: '19:00-19:59'},
        {label: '晚上20:00', value: '20:00-20:59'},
        {label: '晚上21:00', value: '21:00-21:59'},
        {label: '晚上22:00', value: '22:00-22:59'},
        {label: '晚上23:00', value: '23:00-23:59'},
      ],
      formRules: {
        // "goodsInfoVo.goodsClassifyName": {required: true, message: '不能为空', trigger: 'blur'},
        "orderContactName": [{required: true, message: ' ', trigger: 'blur'},
          {
            validator: function (rule, value, callback) {
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("请输入中文"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          }],
        "orderContactPhone": [
          {required: true, message: ' ', trigger: 'blur'},
          {min: 11, max: 11, message: '手机号码不低于11位数', trigger: 'blur'},
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码格式',
            trigger: 'blur'
          }
        ],
        "receiverAddress.phone": [
          {required: true, message: ' ', trigger: 'blur'},
          {min: 11, max: 11, message: '手机号码不低于11位数', trigger: 'blur'},
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码格式',
            trigger: 'blur'
          }
        ],
        "receiverAddress.username": [{required: true, message: ' ', trigger: 'blur'},
          {
            validator: function (rule, value, callback) {
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("请输入中文"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          }],
        "senderAddress.phone": [
          {required: true, message: ' ', trigger: 'blur'},
          {min: 11, max: 11, message: '手机号码不低于11位数', trigger: 'blur'},
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码格式',
            trigger: 'blur'
          }
        ],
        "senderAddress.username": [{required: true, message: ' ', trigger: 'blur'},
          {
            validator: function (rule, value, callback) {
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("请输入中文"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          }],
        "planLoadTime": {required: true, message: ' ', trigger: 'change'},
        "senderAddress.areaId": {required: true, message: '不能为空', trigger: 'change'},
        "planArriveTime": {required: true, message: ' ', trigger: 'change'},
        "receiverAddress.areaId": {required: true, message: ' ', trigger: 'change'},
        "goodsInfo.goodsName": {required: true, message: '不能为空', trigger: 'change'},
        "goodsInfo.goodsWeight": {required: true, message: ' ', trigger: 'blur'},
        "invoiceSource": {required: true, message: '不能为空', trigger: 'change'},
        "haveInvoice": {required: true, message: '不能为空', trigger: 'change'},
        "payerWay": {required: true, message: ' ', trigger: 'change'},
      },
      formRules1: {
        "orderContactName": [{required: true, message: ' ', trigger: 'blur'},
          {
            validator: function (rule, value, callback) {
              if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
                callback(new Error("请输入中文"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          }],
        "orderContactPhone": [
          {required: true, message: ' ', trigger: 'blur'},
          {min: 11, max: 11, message: '手机号码不低于11位数', trigger: 'blur'},
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码格式',
            trigger: 'blur'
          }
        ],
        "planLoadTime": {required: true, message: ' ', trigger: 'change'},
        "senderAddress.areaId": {required: true, message: ' ', trigger: 'change'},
        "receiverAddress.areaId": {required: true, message: ' ', trigger: 'change'},
        "goodsInfo.goodsName": {required: true, message: '不能为空', trigger: 'change'},
        "goodsInfo.goodsWeight": {required: true, message: ' ', trigger: 'blur'},
        "payerWay": {required: true, message: ' ', trigger: 'change'},
      },
      // 运输方式拍op
      deliveryTypeOption: [
        {label: '整车', value: 1},
        {label: '拼车', value: 2},
      ],
      // 附件op
      hasOptions: [
        {label: '是', value: 1},
        {label: '否', value: 0}
      ],
      //  车挂造型op
      tcarHangTypeOptions: [],
      //车挂长度
      tcarLengthOptions: [],
      //鹅颈
      tcarGooseneckTypeOptions: [],
      // 路况名称
      roadConditionOptions: [],
      loadUnloadRequireOption: [],
      preventionRequireOption: [],
      tcarAccessoryOptions: [],
      // 出价方式
      quoteCodeOptions: [
        {id: 1, name: '每单'},
        {id: 2, name: '每吨'},
        {id: 3, name: '每件'}
      ],
      hourArray: [],
      hourArray0: [{
        name: '全天都可以',
        value: '00:00-23:59',
        checked: 0
      }],
      hourArray1: [
        {
          name: '凌晨 00:00-06:00',
          value: '00:00-05:59',
          checked: 0
        },
        {
          name: '凌晨 00:00',
          value: '00:00-00:59',
          checked: 0
        },
        {
          name: '凌晨 01:00',
          value: '01:00-01:59',
          checked: 0
        },
        {
          name: '凌晨 02:00',
          value: '02:00-02:59',
          checked: 0
        },
        {
          name: '凌晨 03:00',
          value: '03:00-03:59',
          checked: 0
        },
        {
          name: '凌晨 04:00',
          value: '04:00-04:59',
          checked: 0
        },
        {
          name: '凌晨 05:00',
          value: '05:00-05:59',
          checked: 0
        }
      ],
      hourArray2: [
        {
          name: '上午 06:00-12:00',
          value: '06:00-11:59',
          checked: 0
        },
        {
          name: '上午 06:00',
          value: '06:00-06:59',
          checked: 0
        },
        {
          name: '上午 07:00',
          value: '07:00-07:59',
          checked: 0

        },
        {
          name: '上午 08:00',
          value: '08:00-08:59',
          checked: 0
        },
        {
          name: '上午 09:00',
          value: '09:00-09:59',
          checked: 0
        },
        {
          name: '上午 10:00',
          value: '10:00-10:59',
          checked: 0
        },
        {
          name: '上午 11:00',
          value: '11:00-11:59',
          checked: 0
        }
      ],
      hourArray3: [
        {
          name: '下午 12:00-18:00',
          value: '12:00-17:59',
          checked: 0
        },
        {
          name: '下午 12:00',
          value: '12:00-12:59',
          checked: 0
        },
        {
          name: '下午 13:00',
          value: '13:00-13:59',
          checked: 0
        },
        {
          name: '下午 14:00',
          value: '14:00-14:59',
          checked: 0
        },
        {
          name: '下午 15:00',
          value: '15:00-15:59',
          checked: 0
        },
        {
          name: '下午 16:00',
          value: '16:00-16:59',
          checked: 0
        },
        {
          name: '下午 17:00',
          value: '17:00-17:59',
          checked: 0
        }
      ],
      hourArray4: [
        {
          name: '晚上 18:00-24:00',
          value: '18:00-23:59',
          checked: 0
        },
        {
          name: '晚上 18:00',
          value: '18:00-18:59',
          checked: 0
        },
        {
          name: '晚上 19:00',
          value: '19:00-19:59',
          checked: 0
        },
        {
          name: '晚上 20:00',
          value: '20:00-20:59',
          checked: 0
        },
        {
          name: '晚上 21:00',
          value: '21:00-21:59',
          checked: 0
        },
        {
          name: '晚上 22:00',
          value: '22:00-22:59',
          checked: 0
        },
        {
          name: '晚上 23:00',
          value: '23:00-23:59',
          checked: 0
        }
      ],
      driverSpecifyDialogVisible: false,
      driverPhone: '',
      driverInfo: '',
    }
  },
  watch: {
    //监听carouselList状态发生变化
    'form.haveInvoice': {
      //深度监听
      // deep:true,
      //立即监听
      immediate: true,
      //监听到carouselList状态发生变化了
      handler() {
        //监听到数据后的下一步操作
        let haveInvoice = this.form.haveInvoice
        this.form = {
          haveInvoice: haveInvoice,
          isSpeclifiedDriver: 0,
          carAccessories: [],
          senderList: [],
          receiverList: [],
          isOil: 0,
          isReceipt: 0,
          deposit: "",
          isQuickly: 0,//是否是快速发货
          isRefundDeposit: 0,
          name: "",
          num: "",
          orderContactName: "",
          orderContactPhone: "",
          price: "",
          quoteCode: 1,
          receiptAmount: "",
          refundsAmount: 0,
          loadTimes: "一装一卸",
          vehicleRequirementsInfo: {
            carHangOther: "",
            roadConditionList: [],
            tcarGooseneckTypeList: [],
            tcarHangTypeList: [],
            tcarLengthList: []
          },
          serviceRequirements: {
            preventionRequireList: [],
            loadUnloadRequireList: []
          },
          status: 0,
          goodsInfo: {
            additionalRequirement: "",
            additionalRequirementInfo: "",
            additionalRequirementList: [],
            attachment: "",
            goodsClassifyId: "",
            goodsClassifyName: "",
            highestOffer: "",
            highestOfferInfos: [],
            needCarLength: "",
            overrun: "",
            goodsName: "",
            goodsNameId: '',
            goodsWeight: "",
            photoShowTime: 0,
            photosUrls: [],
            overrunInfo: {
              high: '',
              length: '',
              width: '',
            }
          },
          senderAddress: {
            adcode: "",
            addressDetail: "",
            area: "",
            city: "",
            latitude: 0,
            longitude: 0,
            phone: "",
            province: "",
            userAddressId: 0,
            username: ""
          },
          receiverAddress: {
            adcode: "",
            addressDetail: "",
            area: "",
            areaId: "",
            city: "",
            cityId: "",
            latitude: 0,
            longitude: 0,
            phone: "",
            province: "",
            provinceId: "",
            userAddressId: 0,
            username: ""
          },
          planLoadDate: "",
          planLoadTime: "",
          userId: 0,
          expedited: 1,
          deliveryType: 1,
          remark: "",
          oilCardAmount: "",
          payerWay: haveInvoice == 1 ? 2 : 1,  //付款方式
          payerType: 1,   //付款方,
          invoiceSource: 1,
          isMargin: 0,//是否需要保证金，0否1是
          orderType: 0,
          distance: 0,
          lineId: 0,
          planLoadDateBef: "",
          planArriveTime: "",
          vehicleType: [],
          specifiedDriverPhone: "",
          isPrice: 0,
        };
        this.planLoadDate = "";
        this.planLoadTime = "";
        this.planArriveTime = "";
      }
    }
  },
  mounted() {
    this.placeSearch = new AMap.AutoComplete({});
    // this.$set(this, 'areaList', area);
    this.allVehicleRequirements();
    this.getServiceRequirements();
    findProvince().then(res => {
      this.provinceOption = res.result
      this.provinceOption1 = res.result
    });
    judgeIsUploadIdCard().then(res => {
      this.form.haveInvoice = res.result.haveInvoice
      if (this.form.haveInvoice == 1) {
        this.form.payerWay = 2;
        this.form.payerType = 1;
        this.$set(this.form, 'isXhZp', 1)
        this.$set(this.form, 'isZhZp', 1)
        this.$set(this.form, 'isReceipt', 1)
      } else {
        this.form.payerWay = 1;
        this.form.payerType = 2;
        this.$set(this.form, 'isXhZp', null)
        this.$set(this.form, 'isZhZp', null)
        this.$set(this.form, 'isReceipt', null)
      }
    })
  },
  methods: {
    haveChange(e) {
      if (e == 1) {
        this.form.payerWay = 2;
        this.form.payerType = 1;
        this.$set(this.form, 'isXhZp', 1)
        this.$set(this.form, 'isZhZp', 1)
        this.$set(this.form, 'isReceipt', 1)
      } else {
        this.form.payerWay = 1;
        this.form.payerType = 2;
        this.$set(this.form, 'isXhZp', null)
        this.$set(this.form, 'isZhZp', null)
        this.$set(this.form, 'isReceipt', null)
      }
    },
    hourInit() {
      let timeStampNow = new Date().getTime()
      let timeNow = new Date()
      let hourNow = timeNow.getHours()
      this.hourArray = []
      if (hourNow >= 0 && hourNow <= 5) {
        this.hourArray = this.hourArray.concat(this.hourArray0)
        this.hourArray.push({name: '凌晨 00:00-06:00', value: '00:00-05:59', checked: 0})
        for (let i = hourNow + 1; i <= 6; i++) {
          this.hourArray.push({name: '凌晨 0' + i + ':00', value: '0' + i + ':00-0' + i + ':59', checked: 0})
        }
        this.hourArray = this.hourArray.concat(this.hourArray2, this.hourArray3, this.hourArray4)
      }
      if (hourNow >= 6 && hourNow <= 11) {
        this.hourArray = this.hourArray.concat(this.hourArray0)
        this.hourArray.push({name: '上午 06:00-12:00', value: '06:00-12:00', checked: 0})
        for (let i = hourNow + 1; i <= 12; i++) {
          if (i <= 9) {
            this.hourArray.push({name: '上午 0' + i + ':00', value: '0' + i + ':00-0' + i + ':59', checked: 0})
          } else {
            this.hourArray.push({name: '上午 ' + i + ':00', value: i + ':00-' + i + ':59', checked: 0})
          }
        }
        this.hourArray = this.hourArray.concat(this.hourArray3, this.hourArray4)
      }
      if (hourNow >= 12 && hourNow <= 17) {
        this.hourArray = this.hourArray.concat(this.hourArray0)
        this.hourArray.push({name: '下午 12:00-18:00', value: '12:00-17:59', checked: 0})
        for (let i = hourNow + 1; i <= 17; i++) {
          this.hourArray.push({name: '下午 ' + i + ':00', value: i + ':00-' + i + ':59', checked: 0})
        }
        this.hourArray = this.hourArray.concat(this.hourArray4)
      }
      if (hourNow >= 18 && hourNow <= 23) {
        this.hourArray = this.hourArray.concat(this.hourArray0)
        this.hourArray.push({name: '晚上 18:00-23:59', value: '18:00-23:59'})
        for (let i = hourNow + 1; i <= 24; i++) {
          this.hourArray.push({name: '晚上 ' + i + ':00', value: i + ':00-' + i + ':59', checked: 0})
        }
      }
      for (let i = 0; i <= this.hourArray.length - 1; i++) {
        if (i == 0) {
          this.hourArray[i].checked = 0//默认（不）选中第一个
        } else {
          this.hourArray[i].checked = 0
        }
      }
      return this.hourArray
    },
    show() {
      this.carAccessoriesDialogVisible = true
    },
    // 地址回调
    addressAsync(queryString, cb) {
      this.placeSearch.search(queryString, (status, result) => {
        // 查询成功时，result即对应匹配的POI信息
        if (result && result.tips) {
          let restaurants = [];
          result.tips.forEach(item => {
            item.value = item.name;
            restaurants.push(item);
          });
          cb(restaurants);
        }
      });
    },
    // 计划装/卸货时间
    planTimeChange(e) {
      console.log(e)
      if (e == this.dateFormat(0)) {
        this.hourOption = this.hourInit()
      } else {
        this.hourOption = this.hourOption1
      }
      if (e == null) {
        this.planLoadDate = '';
        this.form.planLoadTime = '';
        this.form.planLoadDate = '';
      } else if (e == 0) {
        this.form.planLoadDate = '今天或明天';
        this.planLoadDate = '';
        this.form.planLoadTime = '全天都可以';
        this.planLoadStatus = true;
      } else {
        this.form.planLoadDate = e
        this.form.planLoadTime = '00:00-23:59';
        this.planLoadStatus = false;
      }
    },
    dateFormat(num) {
      let date = new Date();
      let y = date.getFullYear();
      let M = date.getMonth() + 1;
      let d = date.getDate() + num;
      return y + '-' + M + '-' + d
    },
    addressChange(e, i, type) {
      if (type == 'province') {
        this.form.receiverAddress.province = e.province;
        findCity({provinceId: e.provinceId}).then(res => {
          this.cityOption = res.result
        });
        typeof (this.form.receiverAddress.cityId) == 'object' ? this.form.receiverAddress.cityId = {} : "";
        typeof (this.form.receiverAddress.areaId) == 'object' ? this.form.receiverAddress.areaId = {} : "";
      } else if (type == 'city') {
        this.form.receiverAddress.city = e.city;
        findArea({cityId: e.cityId}).then(res => {
          this.areaOption = res.result
        });
        typeof (this.form.receiverAddress.areaId) == 'object' ? this.form.receiverAddress.areaId = {} : "";
      } else {
        this.form.receiverAddress.adcode = e.areaId;
        this.form.receiverAddress.longitude = e.longitude;
        this.form.receiverAddress.latitude = e.latitude;
        this.form.receiverAddress.area = e.area;
        this.form.receiverAddress.userAddressId = e.id;
      }
    },
    addressChange1(e, i, type) {
      console.log()
      if (type == 'province') {
        typeof (this.form.senderAddress.cityId) == 'object' ? this.form.senderAddress.cityId = {} : "";
        typeof (this.form.senderAddress.areaId) == 'object' ? this.form.senderAddress.areaId = {} : "";
        this.form.senderAddress.province = e.province;
        findCity({provinceId: e.provinceId}).then(res => {
          this.cityOption1 = res.result
        });
      } else if (type == 'city') {
        typeof (this.form.senderAddress.areaId) == 'object' ? this.form.senderAddress.areaId = {} : "";
        this.form.senderAddress.city = e.city;
        findArea({cityId: e.cityId}).then(res => {
          this.areaOption1 = res.result
        })
      } else {
        this.form.senderAddress.adcode = e.areaId;
        this.form.senderAddress.longitude = e.longitude;
        this.form.senderAddress.latitude = e.latitude;
        this.form.senderAddress.area = e.area;
        this.form.senderAddress.userAddressId = e.id;
      }
    },

    // 远程匹配货物名称
    querySearchAsync(queryString, cb) {
      getGoodsName({name: queryString, currentPage: 1, pageSize: 999}).then(res => {
        let list = res.result.list;
        res.result.list.forEach((item, index) => {
          list[index].value = item.name;
        })
        cb(list);
      })
    },
    goodNameSel(e) {
      // 赋值货物名称id
      this.form.goodsInfo.goodsNameId = e.id;
    },
    // 获取各种车辆要求
    allVehicleRequirements() {
      vehicleRequirements().then(res => {
        // 路况名称
        this.roadConditionOptions = res.result.roadConditionList;
        // 鹅颈造型名称
        this.tcarGooseneckTypeOptions = res.result.tcarGooseneckTypeList;
        // 车挂造型名称
        // this.tcarHangTypeOptions = res.data.result.carAxleTypeList;
        //车挂长度
        this.tcarLengthOptions = res.result.tcarLengthList;
        this.tcarAccessoryOptions = res.result.tcarAccessoryList;
      });
      getCarType().then(res => {
        this.tcarHangTypeOptions = res.result;
      })
    },
    //获取特殊服务要求
    getServiceRequirements() {
      getLoadUnloadRequire().then((res) => {
        this.loadUnloadRequireOption = res.result
      });
      getPreventionRequire().then((res) => {
        this.preventionRequireOption = res.result
      })
    },
    quoteChange(e) {
      if (e == 2) {
        this.form.goodsInfo.goodsWeight = this.form.num;
      }
    },
    payTypeChange(e) {
      if (e === '2') {
        // this.orderForm.receiptAmount = 0;
        // this.orderForm.refundsAmount = 0;
        // this.orderForm.needInvoice = 0;
        // this.orderForm.taxCost = 0;
        // this.orderForm.carPrice = 0;
      } else {
      }
    },
    needInvoiceChange(e) {
      // if (e==1) {
      //   this.orderForm.taxCost = (this.orderForm.totalPrice * 0.065).toFixed(2);
      //   this.orderForm.carPrice = this.orderForm.totalPrice - this.orderForm.taxCost;
      // } else {
      //   this.orderForm.taxCost = 0;
      //   this.orderForm.carPrice = this.orderForm.totalPrice;
      // }
    },
    photoChange(e) {
      this.form.goodsInfo.photosUrls.push(e);
      const d = this.$refs["ID"]
      this.form.goodsInfo.photosUrls.length >= 3 ? d.$children[0].$children[1].$el.style.display = 'none' : ''
    },
    removeImg(e) {
      let index = this.form.goodsInfo.photosUrls.findIndex(i => {
        return i == e;
      })
      this.form.goodsInfo.photosUrls.splice(index, 1);
      const d = this.$refs["ID"]
      this.form.goodsInfo.photosUrls.length < 3 ? d.$children[0].$children[1].$el.style.display = '' : ''
    },
    appointDriver() {
      this.form.isSpeclifiedDriver = 1;
      this.form.specifiedDriverPhone = this.driverInfo.phone;
      this.addOrder();
    },
    addOrder() {
      let check1 = this.form.orderContactPhone != "" && this.form.orderContactName != "" && this.form.receiverAddress.phone != '' && this.form.receiverAddress.username != '' && this.form.senderAddress.phone != '' && this.form.senderAddress.username != '' && this.form.senderAddress.phone !== '' && this.form.payerWay !== '' && this.form.haveInvoice !== '' && this.form.invoiceSource !== '' && this.form.goodsInfo.goodsWeight !== '' && this.form.goodsInfo.goodsName !== '' && this.form.receiverAddress.areaId !== '' && this.form.planArriveTime !== '' && this.form.senderAddress.areaId !== '' && this.form.planLoadTime !== '';
      let check = this.form.haveInvoice == 0 ? this.form.orderContactPhone != "" && this.form.orderContactName != "" && this.form.senderAddress.areaId !== '' && this.form.receiverAddress.areaId !== '' && this.form.planLoadTime !== '' && this.form.payerWay !== '' : check1;
      this.$refs['form'].validate((valid) => {
        if (valid && check) {
          this.addLoading = true;
          let params = JSON.parse(JSON.stringify(this.form));
          console.log(params);
          params.haveInvoice == 1 ? params.goodsInfo.highestOfferInfos.push('开发票') : "";
          params.isOil == 1 ? params.goodsInfo.highestOfferInfos.push('含油卡') : "";
          params.isReceipt == 1 ? params.goodsInfo.highestOfferInfos.push('要回单') : "";
          params.planLoadDate == '今天或明天' ? params.planLoadDateBef = this.$moment().format('yyyy-MM-DD') : "";
          params.planLoadDate == '今天或明天' ? params.planLoadDate = this.$moment().add(1, 'days').format('yyyy-MM-DD') : "";
          params.planLoadTime == '全天都可以' ? params.planLoadTimeBef = "00:00-23:59" : "";
          params.planLoadTime == '全天都可以' ? params.planLoadTime = "00:00-23:59" : "";
          const _PlanStartTime = new Date(params.planLoadDate)
          const _PlanLoadDateBef = new Date(params.planLoadDateBef)
          const _PlanEndTime = new Date(params.planArriveTime)
          if (params.isSpeclifiedDriver == 0) {
            if (_PlanEndTime.getTime() !== 0 && _PlanEndTime.getTime() < new Date().getTime()) {
              this.$message.warning("希望送达时间不能小于当前时间!")
              this.addLoading = false;
              return;
            }
            if (_PlanStartTime.getTime() !== 0 && _PlanStartTime.getTime() < new Date().getTime()) {
              this.$message.warning("装货时间不能小于当前时间!")
              this.addLoading = false;
              return;
            }
          }
          if (_PlanEndTime.getTime() !== 0 && _PlanEndTime.getTime() < _PlanStartTime.getTime()) {
            this.$message.warning("装货时间不能大于希望到达时间!")
            this.addLoading = false;
            return;
          }
          params.planArriveTime = Math.round(new Date(params.planArriveTime).getTime() / 1000)
          params.goodsInfo.goodsWeight = params.goodsInfo.goodsWeight * 1000;  //货主重量
          params.goodsInfo.overrunInfo.high = params.goodsInfo.overrunInfo.high * 100; //货物-高
          params.goodsInfo.overrunInfo.width = params.goodsInfo.overrunInfo.width * 100; //货物-高
          params.goodsInfo.overrunInfo.length = params.goodsInfo.overrunInfo.length * 100;  //货物-高
          params.price = params.price * 100;  //单价-
          // params.quoteCode == 2 ? params.num = params.num * 1000 : "";
          params.oilCardAmount = params.oilCardAmount * 100;  //油卡金额-
          params.receiptAmount = params.receiptAmount * 100;  //回单金额-
          params.deposit = params.deposit * 100;  //车方定金-
          params.goodsInfo.highestOffer = (+params.price) * (+params.num);
          params.goodsInfo.highestOffer == 0 ? params.isPrice = 0 : params.isPrice = 1;
          params.haveInvoice == 0 ? params.invoiceSource = '' : '';  //车方定金-
          if (Array.isArray(params.serviceRequirements.preventionRequireList)) {
          } else {
            params.serviceRequirements.preventionRequireList = params.serviceRequirements.preventionRequireList ? [params.serviceRequirements.preventionRequireList] : [];
          }
          params.goodsInfo.additionalRequirementInfo = this.carAccessoriesOther
          createOrder(params).then(res => {
            this.$message.success('发货成功!')
            this.reload()
          });
        } else {
          this.$message.warning("请检查必填项!")
        }
        setTimeout(() => {
          this.addLoading = false;
        }, 2000)
      })
    },
    carAccessoriesClose() {
      this.carAccessoriesDialogVisible = false;
      this.form.vehicleRequirementsInfo.carHangOther = '';
    },
    carHangOtherClose() {
      this.carHangOtherDialogVisible = false;
      this.form.vehicleRequirementsInfo.carHangOther = '';
    },
    carHangOtherChange(e) {
      if (e.length == 1) {
        e[0].name == "其他" ? this.carHangOtherDialogVisible = true : ""
      } else if (e.length > 1) {
        e[e.length - 1].name == "其他" ? this.carHangOtherDialogVisible = true : ""
      }
    },
    carAccessoriesChange(e) {
      if (e.length == 1) {
        e[0].name == "其他" ? this.carAccessoriesDialogVisible = true : ""
      } else if (e.length > 1) {
        e[e.length - 1].name == "其他" || e[e.length - 1].name == "其他:" ? this.carAccessoriesDialogVisible = true : ""
      }
    },
    carAccessoriesBeOK() {
      // this.form.carAccessories.forEach(item => {
      //   if (item.name == "其他") {
      //     this.carAccessoriesOther!=""? item.name += ":" + this.carAccessoriesOther:""
      //   }
      // });
      this.carAccessoriesDialogVisible = false
    },
    handleClose() {
      if (JSON.parse(localStorage.getItem('isSuccess')) == true) {
        this.importDialogVisible = false;
        this.$nextTick(() => {
          this.$refs.import.againUpload();
        });
      } else {
        this.$message.warning('操作失败,任务执行中,请稍后再试！')
      }

    },
    closeDialog(val) {
      this.importDialogVisible = val
    },
    toImport() {
      this.importDialogVisible = true
    },
    driverSpecifyClose() {
      this.driverSpecifyDialogVisible = false;
      this.driverInfo = '';
      this.driverPhone = '';
    },
    searchDriver() {
      if (!this.driverPhone) {
        this.$message.warning('请输入完整手机号');
        return;
      }
      selectDriverByPhone({phone: this.driverPhone}).then(res => {
        this.driverInfo = res.result;
      });
    }
  }
}
</script>

<style lang="less">
.addForm {
  padding: 20px 0;

  .form {
    background: #ffffff;
    padding: 20px 0;

    .btn-group {
      padding: 30px 50px;
      text-align: right;
    }

    .form-tab {
      border-top: 1px solid #cccccc;
      border-left: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      margin-bottom: 30px;

      .item {
        .title {
          padding: 10px 0;
          border-right: 1px solid #cccccc;
          text-align: center;
          background: #F5F5F5;
          font-weight: 600;

          span {
            color: red;
          }
        }

        .content {
          padding: 10px 0;
          border-right: 1px solid #cccccc;

          .el-input__inner {
            border: 0;
          }
        }
      }
    }

    .row-form {
      border-right: 1px solid #F5F5F5;

      .item {
        .el-form-item {
          margin-bottom: 0;
          border-top: 1px solid #F0F2F5;

          .el-form-item__label {
            background: #F5F5F5;
            margin: 0;
          }

          .el-form-item__content {
            .el-input__inner {
              border: 0;
            }
          }
        }
      }
    }

    .top-form {

      .item {
        .el-form-item {
          border-right: 1px solid #F5F5F5;
          border-bottom: 1px solid #F5F5F5;

          &.first-child {
            border-left: 1px solid #F5F5F5;
          }

          &.last-child {
            border-right: 1px solid #F5F5F5;
          }

          .el-form-item__label {
            padding: 0;
            margin: 0;
            display: block;
            background: #F5F5F5;
            text-align: center;
          }

          .el-form-item__content {
            .el-input__inner {
              border: 0;
            }
          }
        }
      }
    }

    .goodsPhotos {
      margin-top: 30px;
    }

    .remarks {
      margin-top: 30px;
    }
  }

  .form {
    margin-top: 1px;
    padding: 20px 30px;
    margin-bottom: 20px;
    background: #ffffff;

    .el-row {
      .el-col {
        border: 1px solid rgba(0, 0, 0, 0.06);

        &:nth-child(2n) {
          border-right: 0;
        }

        .el-form-item {
          margin-bottom: 0;

          .el-form-item__label {
            text-align: center;
            display: block;
            margin: 0;
            font-weight: 600;
            padding: 0;
            background: #F5F5F5;
          }
        }

        .el-form-item__content {
          .el-input__inner {
            border: 0;
            box-shadow: none;
            background: #ffffff !important;
          }
        }
      }
    }
  }

  .drivers-content {
    .driverDetails {
      margin-top: 10px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      padding: 14px 10px;

      .driver_name {
        margin-top: 6px;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }

      .driver_tips {
        .tips-items {
          background: rgba(52, 89, 254, .1);
          border-radius: 2px;
          font-size: 12px;
          font-weight: 400;
          color: #3459FE;
          line-height: 22px;
          padding: 1px 6px;
          margin-right: 12px;
          display: inline-block;
        }
      }

      .con-txt {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;

        i {
          color: #FAAD14;
          margin-right: 5px;
        }
      }
    }

  }

  .drivers-footer {
    margin-top: 10px;
    text-align: right;
  }

  .zhidingsiji {
    .el-dialog__title {
      font-weight: 600;
    }
  }
}
</style>