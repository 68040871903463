<template>
  <div class="home">
    <div class="pl-container">
      <img src="../../assets/home/banner.png"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "index",
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.home {
  .pl-container {
    text-align: center;

    img {
      width: 80%;
      margin-top: 5%;
    }
  }
}
</style>
