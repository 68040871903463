import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    isCollapse: false, userInfo: {}, toKen: '',
}

const getters = {
    getIsCollapse: (state) => {
        return state.isCollapse;
    }, getUserInfo: (state) => {
        return state.userInfo;
    }, getToken: (state) => {
        return state.toKen;
    }
}
const mutations = {
    setIsCollapse(state, data) {
        state.isCollapse = data.isCollapse;
    }, setUserInfo(state, data) {
        state.userInfo = data.userInfo;
    }, setToken(state, data) {
        state.toKen = data.toKen;
    }
}

const store = new Vuex.Store({
    state: state, getters: getters, mutations: mutations
})

export default store;
