<template>
  <div class="mapSport">
    <div class="line-tip">
      车辆轨迹 <i class="car-line"></i>
      APP定位轨迹 <i class="app-line"></i>
    </div>
    <div id="container"></div>
    <div class='dialog-btn' @click='openDialog'>详<br/>细<br/>信<br/>息<br/>>></div>
    <div v-if='dialogLeft==0' class='container-dialog' :style='{left:+dialogLeft+"px"}'>
      <div class='dialog-title'>订单编号：{{ orderData.orderNo }}</div>
      <div class='dialog-btn0' @click='closeDialog'>收起</div>
      <div class='dialog-title0'>订单信息</div>
      <div class='dialog-text'>货物名称：{{ orderData.goodsInfoVo.goodsName }}</div>
      <div class='dialog-text'>货物类型：{{ orderData.goodsInfoVo.goodsClassifyName }}</div>
      <div class='dialog-text'>订单联系人姓名：{{ orderData.orderContactName }}</div>
      <div class='dialog-text'>订单联系人电话：{{ orderData.orderContactPhone }}</div>
      <div class='dialog-text'>发货人电话：{{ orderData.senderInfo.phone }}</div>
      <div class='dialog-text'>货物重量：{{ orderData.goodsInfoVo.goodsWeight / 1000 }}吨</div>
      <div class='dialog-text'>订单金额：{{ orderData.totalPrice / 100 }}元</div>
      <div v-if='orderData.paymentInfo.payer=="SENDER"' class='dialog-text'>付款方：发货方付款</div>
      <div v-if='orderData.paymentInfo.payer=="RECEIVER"' class='dialog-text'>付款方：收货方付款</div>
      <div class='dialog-text' style='width:80%'>付款状态：{{ orderData.payStatus }}</div>
      <div class='dialog-text' style='width:80%'>
        装货地：{{
          orderData.senderInfo.province + orderData.senderInfo.city + orderData.senderInfo.area + orderData.senderInfo.addressDetail
        }}
      </div>
      <div class='dialog-text' style='width:80%'>
        卸货地：{{
          orderData.receiverInfo.province + orderData.receiverInfo.city + orderData.receiverInfo.area + orderData.receiverInfo.addressDetail
        }}
      </div>
      <div class='dialog-title0'>运单信息</div>
      <div class='dialog-text'>抢单人姓名：{{ this.waybillData.grabName }}</div>
      <div class='dialog-text'>抢单人手机号：{{ this.waybillData.grabPhone }}</div>
      <div class='dialog-text'>承运司机姓名：{{ this.waybillData.name }}</div>
      <div class='dialog-text'>承运司机手机号：{{ this.waybillData.driverPhone }}</div>
      <div class='dialog-text'>车头牌号：{{ this.waybillData.plateNumber }}</div>
      <div class='dialog-text'>
        车挂牌号：{{ this.waybillData.hangPlateNumber != null ? this.waybillData.hangPlateNumber : '' }}
      </div>
      <div class='dialog-text'>运单状态：{{ this.statusList[this.orderData.orderItemLogVos.length - 1] }}</div>
      <div class='dialog-text'>当前承运位置：{{ info.currentLocation ? info.currentLocation.adr : '' }}</div>
      <div class='dialog-text'>全程均速：{{ waybillData.avgSpeedByFullRange }}km/h</div>
      <div class='dialog-text'>当前均速：{{ waybillData.avgSpeedByCurrent }}km/h</div>
    </div>
    <el-dialog title="装货照片" :visible.sync="pzVisible" width="600px" center append-to-body>
      <div>装货位置：{{ info.driverPhoto ? info.driverPhoto.currentPosition : '' }}</div>
      <div>装货时间：{{ info.driverPhoto ? info.driverPhoto.creatTime : '' }}</div>
      <div class="demo-image__preview">
        <el-image style="width: 100px; height: 100px;margin-right: 10px" v-for="(item,index) in urlList" :key="index"
                  :src="item" :preview-src-list="urlList"/>
      </div>
    </el-dialog>
    <el-dialog title="卸货照片" :visible.sync="xhVisible" width="600px" center append-to-body>
      <div v-if="info.unloadingPhoto">
        <div>卸货位置：{{ info.unloadingPhoto ? info.unloadingPhoto.currentPosition : '' }}</div>
        <div>卸货时间：{{ info.unloadingPhoto ? info.unloadingPhoto.creatTime : '' }}</div>
        <div class="demo-image__preview">
          <el-image style="width: 100px; height: 100px;margin-right: 10px"
                    :preview-src-list="info.unloadingPhoto.unloadingUrl"
                    v-for="(item,index) in info.unloadingPhoto.unloadingUrl" :key="index" :src="item"/>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="停车点详情" :visible.sync="spotVisible" width="600px" center append-to-body>
      <el-form :model="spotForm" label-width="80px">
        <el-form-item label="车牌号码:">
          {{ spotForm.vclN }}
        </el-form-item>
        <el-form-item label="开始时间:">
          {{ spotForm.begin }}
        </el-form-item>
        <el-form-item label="结束时间:">
          {{ spotForm.end }}
        </el-form-item>
        <el-form-item label="停车位置:">
          {{ spotForm.formatted_address }}
        </el-form-item>
        <el-form-item label="经纬度:">
          {{ spotForm.lon }}，{{ spotForm.lat }}
        </el-form-item>
        <el-form-item label="停车时长:">
          {{ spotForm.result }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {orderInfo, waybillInfo} from '../../request/order';

export default {
  name: "mapSport",
  data() {
    return {
      map: '',
      dialogLeft: 0,
      waybillData: {},
      orderData: {
        goodsInfoVo: {},
        senderInfo: {},
        receiverInfo: {},
        paymentInfo: {},
        orderItemLogVos: {}
      },
      statusList: ['已接单', '开始取货', '已到达装货地', '已装货', '开始送货', '已到达卸货地', '已卸货', '已完成'],
      pzVisible: false,
      urlList: [],
      spotForm: {},
      spotVisible: false,
      xhVisible: false
    }
  },
  props: ['info', 'trackLineArr', 'phoneLineArr', 'stopSpots', 'id'],
  watch: {
    info: {
      handler() {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    openDialog() {
      this.dialogLeft = 0
    },
    closeDialog() {
      this.dialogLeft = -600
    },
    init() {
      this.dialogLeft = 0
      waybillInfo(this.id).then(res => {
        this.waybillData = res.data;
      })
      orderInfo(this.id).then(res => {
        this.orderData = res.data;
      })

      this.map && this.map.destroy();
      let position = '';
      if (this.info.currentLocation) {
        position = new AMap.LngLat(this.info.currentLocation.longitude, this.info.currentLocation.latitude);
      }
      // 创建地图实例
      this.map = new AMap.Map("container", {
        zoom: 7,
        center: position,
        resizeEnable: true
      });
      if (this.trackLineArr && this.trackLineArr.length > 0) {
        this.trackLineArr.forEach(item => {
          // 绘制轨迹
          let polyline = new AMap.Polyline({
            map: this.map,
            path: item.tracks,
            showDir: true,
            strokeColor: "#28F",  //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 6,      //线宽
            // strokeStyle: "solid"  //线样式
          });

          // let passedPolyline = new AMap.Polyline({
          //   map: this.map,
          //   path: item.tracks,
          //   strokeColor: "#AF5",  //线颜色
          //   // strokeOpacity: 1,     //线透明度
          //   strokeWeight: 6,      //线宽
          //   // strokeStyle: "solid"  //线样式
          // });


          // this.marker.on('moving', function (e) {
          //   passedPolyline.setPath(e.passedPath);
          // });
          this.map.setFitView();
        })
      }
      if (this.phoneLineArr && this.phoneLineArr.length > 0 && this.phoneLineArr[0].tracks.length > 0) {
        let temp = [];
        let tempTime = [];
        this.phoneLineArr.forEach(item => {
          item.tracks.forEach((tra, index) => {
            if (tra[0] !== 0) {
              temp.push(tra);
              tempTime.push(item.track_times[index]);
            }
          })
          this.phoneLineArr[0] = {tracks: temp, track_times: tempTime};
          // 绘制轨迹
          let polyline = new AMap.Polyline({
            map: this.map,
            path: temp,
            showDir: true,
            strokeColor: "red",  //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 6,      //线宽
            // strokeStyle: "solid"  //线样式
          });
          this.map.setFitView();
        })
        temp.forEach((stop, index) => {
          let markerContent = '<div class="stop-spot-1"><span>' + this.phoneLineArr[0].track_times[index] + '</span></div>';
          let viaMarker = new AMap.Marker({
            position: stop,
            content: markerContent,
            offset: [-10, 0]
          })
          this.map.add(viaMarker);
        })
      }
      if (this.stopSpots && this.stopSpots.length > 0) {
        this.stopSpots.forEach((item, index) => {
          let markerContent = '<div class="stop-spot"></div>';

          let viaMarker = new AMap.Marker({
            position: [item.lon, item.lat],
            content: markerContent,
            offset: [-10, 0]
          });
          viaMarker.on('click', (e) => {
            this.stopClick(item);
          });
          this.map.add(viaMarker);
          let secondTime = parseInt(item.stop_seconds)//将传入的秒的值转化为Number
          let min = 0// 初始化分
          let h = 0// 初始化小时
          let result = ''
          if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
            min = parseInt(secondTime / 60)//获取分钟，除以60取整数，得到整数分钟
            secondTime = parseInt(secondTime % 60)//获取秒数，秒数取佘，得到整数秒数
            if (min > 60) {//如果分钟大于60，将分钟转换成小时
              h = parseInt(min / 60)//获取小时，获取分钟除以60，得到整数小时
              min = parseInt(min % 60) //获取小时后取佘的分，获取分钟除以60取佘的分
            }
          }
          result = `${h.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`;
          viaMarker.setTitle(item.vclN + "停留" + result);
        })
      }
      // 点标记显示内容，HTML要素字符串
      let markerContent = '';
      if (this.info.currentLocation) {
        markerContent = '' +
            '<div class="custom-content-marker">' +
            '<div class="content">' +
            /*'<p style="text-align: right">X</p>' +*/
            //'<p>司机姓名：' + this.info.driverName + '</p>' +
            //'<p>车头牌号：' + this.info.carHeadPlateNum + '</p>' +
            //'<p>经纬度：[' + this.info.currentLocation.longitude + ',' + this.info.currentLocation.latitude + ']</p>' +
            '<p>定位时间：' + this.info.currentLocation.positionTime + '</p>' +
            //'<p>订单总里程：' + this.info.distance / 1000 + 'km</p>' +
            '<p>当前位置：' + this.info.currentLocation.adr + '</p>' +
            '<div class="bubbleTail"></div></div>' +
            '</div>';
        let marker = new AMap.Marker({
          position: position,
          content: markerContent,
          offset: new AMap.Pixel(-13, -30)
        });
        // 将 markers 添加到地图
        this.map.add([marker]);
      }
      /*let startIcon = new AMap.Icon({
        size: new AMap.Size(25, 34),
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
        imageSize: new AMap.Size(135, 40),
        imageOffset: new AMap.Pixel(-9, -3)
      });
      let startMarker = new AMap.Marker({
        position: new AMap.LngLat(this.info.senderInfo.longitude, this.info.senderInfo.latitude),
        icon: startIcon,
        offset: new AMap.Pixel(-13, -30)
      });
      let jIcon = new AMap.Icon({
        size: new AMap.Size(25, 34),
        image: '//a.amap.com/jsapi/static/image/plugin/marker/mid.png',
        imageSize: new AMap.Size(24, 30),
        imageOffset: new AMap.Pixel(0, 0)
      });

      let jMarker = new AMap.Marker({
        position: new AMap.LngLat(this.info.driverPhoto.longitude, this.info.driverPhoto.latitude),
        icon: jIcon,
        offset: new AMap.Pixel(-13, -30)
      });
      let endIcon = new AMap.Icon({
        size: new AMap.Size(25, 34),
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
        imageSize: new AMap.Size(135, 40),
        imageOffset: new AMap.Pixel(-95, -3)
      });
      // 将 icon 传入 marker
      let endMarker = new AMap.Marker({
        position: new AMap.LngLat(this.info.receiverInfo.longitude, this.info.receiverInfo.latitude),
        icon: endIcon,
        offset: new AMap.Pixel(-13, -30)
      });
      let marker = new AMap.Marker({
        position: position,
        content: markerContent,
        offset: new AMap.Pixel(-13, -30)
      });
      jMarker.on('click', this.markerClick)
      // 将 markers 添加到地图
      this.map.add([marker, startMarker, endMarker]);*/

      AMapUI.loadUI(['overlay/SimpleMarker'], (SimpleMarker) => {
        let start = new SimpleMarker({
          iconLabel: {
            innerHTML: '<div>起</div>',
            style: {
              color: '#fff',
              fontSize: '12px',
              lineHright: '30px'
            }
          },
          //图标主题
          iconTheme: 'numv1',
          //背景图标样式
          iconStyle: 'blue',
          //...其他Marker选项...，不包括content
          map: this.map,
          position: [this.info.senderInfo.longitude, this.info.senderInfo.latitude]
        });
        let end = new SimpleMarker({
          iconLabel: {
            innerHTML: '<div>终</div>',
            style: {
              color: '#fff',
              fontSize: '12px',
              lineHright: '30px'
            }
          },
          //图标主题
          iconTheme: 'numv1',
          //背景图标样式
          iconStyle: 'red',
          //...其他Marker选项...，不包括content
          map: this.map,
          position: [this.info.receiverInfo.longitude, this.info.receiverInfo.latitude]
        });
        if (this.info.driverPhoto.longitude) {
          let zhuang = new SimpleMarker({
            iconLabel: {
              innerHTML: '<div>装</div>',
              style: {
                color: '#fff',
                fontSize: '12px',
                lineHright: '30px'
              }
            },
            //图标主题
            iconTheme: 'numv1',
            //背景图标样式
            iconStyle: 'red',
            //...其他Marker选项...，不包括content
            map: this.map,
            position: [this.info.driverPhoto.longitude, this.info.driverPhoto.latitude]
          });
          zhuang.on('click', this.markerClick)
        }
        if (this.info.unloadingPhoto.longitude || this.info.unloadingPhoto.latitude) {
          let xie = new SimpleMarker({
            iconLabel: {
              innerHTML: '<div>卸</div>',
              style: {
                color: '#fff',
                fontSize: '12px',
                lineHright: '30px'
              }
            },
            //图标主题
            iconTheme: 'numv1',
            //背景图标样式
            iconStyle: 'red',
            //...其他Marker选项...，不包括content
            map: this.map,
            position: [this.info.unloadingPhoto.longitude, this.info.unloadingPhoto.latitude]
          });
          xie.on('click', () => {
            this.xhVisible = true
          });
        }
      });
    },
    markerClick() {
      console.log(this.info);
      this.urlList = JSON.parse(this.info.driverPhoto.otherUrl);
      this.urlList.push(this.info.driverPhoto.carDirectionUrl)
      this.urlList.push(this.info.driverPhoto.hangDirectionUrl)
      this.pzVisible = true;
    },
    stopClick(e) {
      this.spotVisible = false;
      let secondTime = parseInt(e.stop_seconds)//将传入的秒的值转化为Number
      let min = 0// 初始化分
      let h = 0// 初始化小时
      let result = ''
      if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60)//获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60)//获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {//如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60)//获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60) //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`;
      e.result = result;
      console.log(e);
      this.spotForm = e;
      this.spotVisible = true;
      // let lnglat = [e.lon, e.lat];
      // this.geocoder.getAddress(lnglat, (status, result) => {
      //   if (status === 'complete' && result.info === 'OK') {
      //     console.log(result);
      //     this.spotForm.formattedAddress = result.regeocode.formattedAddress;
      //     this.spotFormType = true;
      //   }
      // });
    }
  }
}
</script>

<style lang="less">
.mapSport {
  position: relative;
  float: left;
  overflow: hidden;
  height: 600px;
  width: 100%;

  .custom-content-marker {
    position: relative;
    border-radius: 50px;
    background: red;
    height: 10px!important;
    width: 10px!important;
  }

  .line-tip {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    padding: 3px;
    background: #fff;
    display: flex;

    .car-line {
      width: 50px;
      height: 3px;
      display: inline-block;
      background: #28F;
      margin: 10px 5px 0 5px;
    }

    .app-line {
      width: 50px;
      height: 3px;
      display: inline-block;
      background: red;
      margin: 10px 5px 0 5px;
    }
  }

  #container {
    height: 600px;
    width: 100%;
  }

  .dialog-btn {
    position: absolute;
    width: 40px;
    height: auto;
    padding: 20px 0px;
    text-align: center;
    top: 10px;
    left: 0px;
    background-color: #fff;
    border-top: 1px solid #666;
    border-right: 1px solid #666;
    border-bottom: 1px solid #666;
    cursor: pointer;
  }

  .container-dialog {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 600px;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #666;
    z-index: 1000;
    overflow-y: auto;
    overflow-x: hidden;

    .dialog-btn0 {
      position: absolute;
      width: auto;
      height: 40px;
      top: 0px;
      right: 0px;
      font-size: 14px;
      line-height: 40px;
      padding: 0px 20px;
      font-weight: bold;
      cursor: pointer;
    }

    .dialog-title {
      position: relative;
      float: left;
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #f2f3f4;
      color: #222;
      padding-left: 10px;
      box-sizing: border-box;
      font-weight: bold;
    }

    .dialog-title0 {
      position: relative;
      float: left;
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #666;
      font-size: 14px;
      padding-left: 10px;
      box-sizing: border-box;
      font-weight: bold;
      color: #222;
    }

    .dialog-text {
      position: relative;
      float: left;
      width: auto;
      min-width: 40%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #222;
      margin-left: 10px;
    }
  }

  .custom-content-marker {
    position: relative;
    width: 25px;
    height: 34px;

    img {
      width: 100%;
      height: 100%;
    }

    .content {
      position: absolute;
      width: 260px;
      padding: 10px 20px;
      border-radius: 5px;
      background: #ffffff;
      top: -113px;
      left: -55px;

      .bubbleTail {
        position: absolute;
        top: 100%;
        left: 50px;
        width: 0;
        height: 0;
        border-width: 10px;
        border-style: solid;
        border-color: transparent;
        border-top-width: 16px;
        border-top-color: currentColor;
        color: #ffffff;

      }
    }

  }

  .stop-spot {
    background: #00ffff;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    color: red;

    span {
      display: none;
    }

    &:hover {
      span {
        width: 150px;
        padding: 8px;
        display: block;
        color: #FFFFFF;
        background: #28F;
        border-radius: 10px;
        z-index: 999;
      }
    }
  }

  .stop-spot-1 {
    background: #FB9822;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    color: red;

    span {
      display: none;
    }

    &:hover {
      span {
        width: 150px;
        padding: 8px;
        display: block;
        color: #FFFFFF;
        background: #28F;
        border-radius: 10px;
        z-index: 999;
      }
    }
  }
}
</style>
