<template>
  <div v-if='imageNum<=2' class="uploads-list">
    <el-upload
        ref="ID"
        action="/" :http-request="selectFile"
        list-type="picture-card"
        :file-list="imgList"
        :on-preview="handlePictureCardPreview"
        :on-remove="onRemove"
        :limit='3'
        :exceed='exceedNum'
        :before-upload="imageFormat">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="photoDialogVisible" width="30%" append-to-body>
      <img width="100%" :src="imgUrl"/>
    </el-dialog>
  </div>
</template>

<script>
import uploadFile from "@/utils/uploadFile";

export default {
  name: "uploads",
  props: {
    imgList: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      imageNum:0,
      imgUrl: '',
      photoDialogVisible: false
    }
  },
  mounted() {
  },
  methods: {
    exceedNum(){
      this.$message({
        message:'最多只能上传5个文件',
        type:'warning'
      })
    },
    //上传图片前
    imageFormat(file){
      const isRight = (file.type === 'image/jpeg' || file.type==='image/jpg' || file.type==='image/png');
      if(!isRight){
        this.$message({
          message:'上传的文件格式不对',
          type:'error'
        })
      }
      return isRight
    },
    // 删除确认
    onRemove(file, fileList) {
      this.$emit('removeImg', file.url)
    },
    // 添加图片事件
    selectFile(e) {
      uploadFile(e.file, (key) => {
        this.$emit('photoChange', key)
      })
    },
    handlePictureCardPreview(file) {
      this.imgUrl = file.url;
      this.photoDialogVisible = true;
    }
  }
}
</script>

<style lang="less">
.uploads-list {
  margin-bottom: 20px;
  text-align: left;
}
</style>
