<template>
  <div class="Sidebar">
    <el-menu :default-active="activeIndex" class="el-menu-vertical" background-color="#F4F7FE" text-color="#000000"
      active-text-color="#0071FE" :collapse="openType" :router="true" :unique-opened="true">
      <div class="switchClick" @click="upIsCollapse">
        <i class="el-icon-s-operation" style="color: #555555"></i>
      </div>
      <el-menu-item index="/home">
        <i class="el-icon-house"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu v-for="(m, i) in menusList" :key="i" :index="m.path">
        <template slot="title">
          <i :class="m.icon"></i>
          <span slot="title">{{ m.title }}</span>
        </template>
        <el-menu-item v-for="(t, x) in m.list" :key="x" :index="t.path">{{
          t.title
        }}</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: "sidebar",
  data() {
    return {
      openType: this.$store.getters.getIsCollapse,
      activeIndex: "/home",
      menusList: [
        {
          title: "批量发单",
          icon: "el-icon-share",
          path: "order",
          list: [
            { title: "发单", path: "/add" },
            { title: "我的订单", path: "/orderList" },
          ],
        },
      ],
      userInfo: JSON.parse(localStorage.getItem("user")),
    };
  },
  computed: {
    isCollapse() {
      return this.$store.getters.getIsCollapse;
    },
  },
  watch: {
    isCollapse(newVal, oldVal) {
      this.openType = newVal;
    },
  },
  mounted() {
    // demo /home/add   home
    this.activeIndex = "/" + this.$route.path.split("/")[1];
  },
  methods: {
    upIsCollapse() {
      this.$store.commit("setIsCollapse", {
        isCollapse: !this.openType,
      });
    },
  },
};
</script>

<style lang="less">
.Sidebar {
  height: 100%;
  text-align: left;

  .el-menu-vertical:not(.el-menu--collapse) {
    width: 200px;
    height: 100%;
    color: #ffffff;
    min-height: 400px;
    background: #f4f7fe;
  }

  .el-menu--collapse {
    background: #f4f7fe;
    height: 100%;

    .switchClick {
      text-align: center !important;
    }
  }

  .el-menu {
    border-right: 0;

    .el-submenu__title,
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      margin: 4px 0;
    }

    .el-menu-item {
      &.is-active {
        background-color: #e6f7ff !important;
      }
    }

    .switchClick {
      height: 48px;
      line-height: 48px;
      cursor: pointer;
      text-align: right;
      background: #ffffff;
      box-shadow: 0px 1px 0px 0px #f0f0f0;
      padding: 0 16px;

      &:hover {
        //background: #E6F7FF;
      }
    }
  }
}
</style>
