<template>
  <div class="pagination">
    <el-pagination :class="{'hidden':hidden}" class="pagination-container" @size-change="handleSizeChange"
                   @current-change="handleCurrentChange" :page-size="limit" :current-page="page" :page-sizes="pageSizes"
                   background :layout="layout" :total="total"/>
  </div>
</template>
<script>
export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50, 100]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', {page: this.currentPage, limit: val})
    },
    handleCurrentChange(val) {
      this.$emit('pagination', {page: val, limit: this.pageSize})
    }
  }
}
</script>

<style lang="less">
.pagination {
  text-align: right;
  margin-top: 20px;
  margin-right:30px;
}
</style>
